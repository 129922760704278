import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ClStation } from '../../interface/ClType';
import LoadingScreen from '../../component/LoadingScreen';
import StationDetailUi from './StationDetailUi';
import { handleErrorPage } from 'helper/handle-response';
import CRUD from '../../service/CRUD.service';
import APIRoute from '../../constant/API.constant';
import AccessFilter from '../../helper/AccessFilter';
import ROLE from '../../constant/role.constant';

/**
 * Fiche station (page show)
 * @param id identifiant de la station
 * @param setHttpCodePage fonction pour changer le code http de la page
 */
export const StationDetail: React.FC = ({ id, setHttpCodePage }: any) => {
  const [station, setStation] = useState<ClStation | undefined>();
  const navigate = useNavigate();

  const returnToList = useCallback(() => {
    navigate('/castres/client/station/list');
  }, [navigate]);

  useEffect(() => {
    if (id && !station) {
      setStation({ isLoading: true });
      getStation(id)
        .then((res: ClStation | null) => (res ? setStation(res) : returnToList()))
        .catch((response) => handleErrorPage(response, setHttpCodePage));
    } else if (!id) returnToList();
    else if (id && station?.id && id !== station.id.toString()) setStation(undefined);
  }, [id, station, navigate, returnToList, setHttpCodePage]);

  if (!station || station.isLoading) return <LoadingScreen />;
  return <StationDetailUi station={station} />;
};

/**
 * Récupère les données d'une station (devis, contrats, équipements, interventions, pannes)
 * @param id identifiant de la station
 */
async function getStation(id: string): Promise<ClStation | null> {
  let result: any = {};
  const stationGetList = [
    { attr: 'blQuotation', url: 'BlQuotation/ByStation', role: ROLE.ADMIN_QUOTATION_LIST },
    { attr: 'blQuotationRequest', url: 'BlQuotationRequest/ByStation', role: ROLE.ADMIN_QUOTATION_REQUEST_LIST },
    { attr: 'eqEquipment', url: 'EqEquipment/ByStation', role: ROLE.ADMIN_PARK_EQUIPMENT_LIST },
    { attr: 'blContract', url: 'BlContract/ByStation', role: ROLE.ADMIN_CONTRACT_LIST },
    { attr: 'wkIntervention', url: 'WkIntervention/ByStation', role: ROLE.ADMIN_WORK_INTERVENTION_LIST },
    { attr: 'breakdowns', url: 'EqEquipment/pannebyStation', role: ROLE.ADMIN_PARK_EQUIPMENT_LIST },
  ];
  await CRUD.getById<ClStation>(APIRoute.ClStation, id).then((station: ClStation) => (result = station));
  if (!result) return null;
  for (const item of stationGetList) {
    if (!item.role || AccessFilter([item.role])) {
      await CRUD.getById<ClStation>(item.url, id, true)
        // eslint-disable-next-line no-loop-func
        .then((res: any) => {
          const target: string = item.attr.toString();
          result[target] = res;
        })
        .catch(() => {}); // do not throw whole page if one array is 500
    }
  }
  return result;
}
