import React, { CSSProperties, FC, useCallback, useContext, useEffect, useState } from 'react';
import { CmsButton, CMSChip, HeaderPanel, CmsPaper, WarningBubble, TextEllipse } from 'component/shared/Ui';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { IconButton, TableContainer } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import './timeReporting.scss';
import { InputUI, UI } from '../../component/shared';
import CRUD from '../../service/CRUD.service';
import APIRoute from '../../constant/API.constant';
import LoadingScreen from '../../component/LoadingScreen';
import { PlanningContext, PlanningProvider, WkTimeReportingWeek } from '../../context/TimeReport.context';
import {
  parseDateOfDayList,
  TimeReportingFormStep,
  TRActionOption,
  TRActionType,
  WkTimeReportingAction,
  WkTimeReportingDay,
} from './TimeReporting';
import NotificationService from '../../service/NotificationService';
import Utils from '../../helper/Utils';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from 'react-router-dom';
import { DialogUI, DialogVanillaUI } from '../../component/shared/DialogUI';
import AccessFilter from '../../helper/AccessFilter';
import { UserPackageStatus } from '../user/User.pack';
import CmsIcon from '../../component/shared/CmsIcon';
import MuiLink from '@mui/material/Link';
import { BASE_URL } from '../../constant/API_URL';
import { GlobalContext } from '../../context/Global.context';
import ROLE from '../../constant/role.constant';

/**
 * Administration d'une feuille de temps d'un technicien
 */
const TimeReportingAdministration: FC<any> = () => {
  const [selection, setSelection] = useState<{ userId: number | undefined; date: Date | undefined }>({
    userId: undefined,
    date: undefined,
  });
  const navigate = useNavigate();
  const { id, date } = useParams() as any;

  useEffect(() => {
    if (isNaN(id) || !Utils.isDate(Utils.getKebabDateToDate(date))) {
      NotificationService.error('Url mal formatée');
      navigate('/castres/timereporting/administration/');
    }
    setSelection({ userId: id, date: Utils.getKebabDateToDate(date) });
  }, [navigate, id, date]);

  if (!selection.userId || !selection.date) return <></>;

  return (
    <PlanningProvider>
      <TimeReportingWeekDashBord selection={selection} setSelection={() => {}} />
    </PlanningProvider>
  );
};

/**
 * Espace de travail pour la validation d'une semaine de temps de travail
 * @param selection Selection de la date et du technicien pour l'appel API (on ne recherche pas par Id intervention
 * mais par Id technicien et date de début de semaine)
 * @param setSelection Fonction de mise à jour de la selection
 */
const TimeReportingWeekDashBord: FC<{ selection: any; setSelection: any }> = ({ selection }) => {
  const { planning, setPlanning } = useContext(PlanningContext);
  const navigate = useNavigate();
  const returnToList = useCallback(() => navigate('/castres/timereporting/administration/'), [navigate]);

  useEffect(() => {
    const request = '/Administration/GetWeek/' + selection.userId + '?date=' + selection.date.toISOString();
    CRUD.getCustomObject<WkTimeReportingWeek>(APIRoute.WkTimeReportingWeek + request).then((result) => {
      if (!result?.dayList) {
        NotificationService.error('Aucun jour validé par le technicien cette semaine');
        returnToList();
        return;
      }
      result.mondayDate = new Date(result.mondayDate);
      result.dayList = parseDateOfDayList(result.dayList);
      setPlanning(result);
    });
  }, [selection, setPlanning, returnToList]);

  if (!planning?.id) return <LoadingScreen />;
  return (
    <div className="administration">
      <TRHeader planning={planning} returnToList={returnToList} mondayDate={planning.mondayDate} />
      <TRGlobalInfo planning={planning} />
      {planning.warningList && planning.warningList.length > 0 && <WeeklyWarningList planning={planning} />}
      <WeeklyComments planning={planning} />
      <TRDayList planning={planning} />
      <TRFooter planning={planning} />
    </div>
  );
};

/**
 * Affiche le Temps en 13h30 | 13.5
 * @param minutes Temps en minutes
 */
const pipeTime = (minutes: number): string => {
  return Utils.minuteToStringHour(minutes) + ' | ' + Utils.minuteToDecimalHour(minutes);
};

interface TRHeaderProps {
  returnToList: any;
  planning: WkTimeReportingWeek;
  mondayDate: Date;
}

/**
 * En-tête de la page
 * @param user Technicien
 * @param returnToList Fonction de retour à la liste
 * @param mondayDate Lundi de la semaine
 */
const TRHeader: FC<TRHeaderProps> = ({ planning, returnToList, mondayDate }) => {
  return (
    <HeaderPanel
      title="RAPPORT D'ACTIVITÉ HEBDOMADAIRE"
      subtitle={`${planning.serviceLabel} / ${planning.technicianName}`}
      actions={[
        <CmsIcon
          icon="download"
          onClick={(event: any) => downloadTimeReportingPDF(event, planning.userId, mondayDate)}
          tooltip={"Télécharger la feuille d'heures"}
        />,
        <CmsIcon
          href="/help/Feuille_heures/validation"
          icon="help"
          pdfTitle="Aide Validation des feuilles d'heures"
          target="_blank"
        />,
        <CmsButton startIcon={<ArrowBackIcon />} onClick={returnToList}>
          Retourner au calendrier
        </CmsButton>,
      ]}
    />
  );
};

/**
 * Affiche les informations globales de la semaine
 * @param user Technicien
 * @param planning Semaine de temps de travail
 */
const TRGlobalInfo: FC<{ planning: WkTimeReportingWeek }> = ({ planning }) => {
  const [isSticky, setSticky] = useState<boolean>(true);
  const monday = planning.mondayDate;
  const sunday = new Date(monday);
  sunday.setDate(monday.getDate() + 6);
  const paperStyle: CSSProperties = isSticky ? { position: 'sticky', top: '20px', zIndex: 10 } : {};
  const paperHeaderStyle: CSSProperties = {};
  const { theming } = useContext(GlobalContext);
  const headerStyleOptions = [
    { title: 'Semaine non validée', color: theming.get().cms.color.red },
    { title: 'Validation manager', color: theming.get().cms.color.orange },
    { title: 'Validation RH', color: theming.get().cms.color.blue },
    { title: 'Validé', color: theming.get().cms.color.green },
  ];
  const title = headerStyleOptions[planning.status]?.title;
  paperHeaderStyle.backgroundColor = headerStyleOptions[planning.status]?.color;
  const totalTicketRestaurant = planning.dayList.reduce((acc, day) => acc + (day.ticketRestaurant ?? 0), 0);

  return (
    <CmsPaper
      title={title}
      headerStyle={paperHeaderStyle}
      style={paperStyle}
      actions={[
        <EditWeekUserData />,
        <CmsButton onClick={() => setSticky(!isSticky)}>{isSticky ? 'Non figé' : 'Figé'}</CmsButton>,
      ]}
    >
      <div className="chip-container">
        <CMSChip label="Technicien:" value={planning.technicianName} />
        {planning.status > 0 && (
          <>
            <CMSChip
              label="Validé par:"
              value={planning.validatedBy}
              style={{ backgroundColor: planning.validatedBy !== planning.technicianName ? '#907700' : '' }}
            />
            <CMSChip label="Validé le:" value={Utils.displayDate(new Date(planning.validatedAt ?? 0))} />
          </>
        )}
        {planning.status > 1 && <CMSChip label="Manager ayant validé :" value={planning.supervisorValidation} />}
        {planning.status > 2 && <CMSChip label="RH ayant validé :" value={planning.rhValidation} />}
        <CMSChip label="Service:" value={planning.serviceLabel} />
        <CMSChip label="N°Semaine:" value={Utils.getWeekNumber(planning.mondayDate)} />
        <CMSChip
          label="Dates:"
          value={`du ${Utils.displayDate(planning.mondayDate)} au ${Utils.displayDate(sunday)}`}
        />
        <CMSChip label="Rattachement:" value={planning.detached ? 'Détaché secteur' : 'Rattaché agence'} />
        <CMSChip label="Calcul par zone:" value={planning.haveArea ? 'oui' : 'non'} />
        <CMSChip label="Temps Travaillé:" value={pipeTime(planning.totalWorkedTime ?? 0)} />
        {!!planning.totalAbsenceTime && <CMSChip label="Absence:" value={pipeTime(planning.totalAbsenceTime ?? 0)} />}
        {!!planning.totalPaidAbsenceTime && (
          <CMSChip label="Absence Idemnisé:" value={pipeTime(planning.totalPaidAbsenceTime ?? 0)} />
        )}
        <CMSChip label="Zones payés:" value={getAreaList(planning)} />
        <CMSChip label="Indemnité trajet:" value={pipeTime(planning.totalRouteTime ?? 0)} />
        <CMSChip label="Distance parcourue:" value={(planning.totalTravelDistance ?? 0) + ' km'} />
        <CMSChip label="Fortait:" value={UserPackageStatus[planning.packageStatus ?? 0].label} />
        <CMSChip label="Total Transport" value={aggregateTransPortTitle(planning.dayList)} />
        {!!totalTicketRestaurant && <CMSChip label="Total titre restaurant" value={totalTicketRestaurant} />}
        {!!planning.fullTravelingPaid && <CMSChip label="Trajet complet payé:" value="oui" />}
      </div>
    </CmsPaper>
  );
};

function aggregateTransPortTitle(dayList: WkTimeReportingDay[]): string {
  const data: any = [];
  let result = '';
  for (const day of dayList) data[day.travelingType ?? 0] = 1 + (data[day.travelingType ?? 0] ?? 0);
  for (const type of travelingTypeList) if (data[type.id] > 0) result += `${data[type.id]} ${type.label}, `;
  return result.length > 2 ? result.slice(0, -2) : result;
}

function planningToAdimnForm(planning: WkTimeReportingWeek): any {
  return {
    id: planning.id,
    contractualHour: planning.contractualHour,
    haveArea: planning.haveArea,
    fullTravelingPaid: planning.fullTravelingPaid,
    detached: planning.detached,
    packageStatus: planning.packageStatus,
  };
}

const EditWeekUserData: FC = () => {
  const { planning, setPlanning, canEdit } = useContext(PlanningContext);
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [state, setState] = useState<any>(planningToAdimnForm(planning));
  useEffect(() => setState(planningToAdimnForm(planning)), [planning]);
  if (!canEdit) return <></>;

  const handleChange = () => {
    setLoading(true);
    CRUD.post<WkTimeReportingWeek>(APIRoute.WkTimeReportingWeek + '/AdminEdit', state).then((result) => {
      if (result === null) return;
      const request = '/Administration/GetWeek/' + planning.userId + '?date=' + planning.mondayDate.toISOString();
      CRUD.getCustomObject<WkTimeReportingWeek>(APIRoute.WkTimeReportingWeek + request).then((result) => {
        result.mondayDate = new Date(result.mondayDate);
        result.dayList = parseDateOfDayList(result.dayList);
        setPlanning(result);
        setLoading(false);
        setOpen(false);
      });
    });
  };

  return (
    <>
      <CmsButton onClick={() => setOpen(!open)}>Éditer</CmsButton>
      <DialogVanillaUI open={open} onClose={() => setOpen(false)}>
        <CmsPaper
          style={{ marginBottom: 0 }}
          title="Éditer les attributs d'un utilisateur sur cette semaine (uniquement)"
          isLoading={loading}
        >
          <InputUI.DebouncedInput
            type="number"
            onChange={(x: number) => setState({ ...state, contractualHour: x })}
            value={state.contractualHour}
            label="Heures contractuelles"
          />
          <InputUI.SimpleSelect
            value={state.packageStatus}
            onChange={(x: number) => setState({ ...state, packageStatus: x })}
            label="Forfait"
            options={UserPackageStatus}
          />
          <InputUI.CmsSwitch
            inline
            value={state.haveArea}
            label="Calcul par zone"
            onChange={() => setState({ ...state, haveArea: !state.haveArea })}
          />
          <InputUI.CmsSwitch
            inline
            value={state.fullTravelingPaid}
            label="Trajet complet payé"
            onChange={() => setState({ ...state, fullTravelingPaid: !state.fullTravelingPaid })}
          />
          <InputUI.CmsSwitch
            inline
            value={state.detached}
            label="Détaché secteur"
            onChange={() => setState({ ...state, detached: !state.detached })}
          />
          <div className="flex-h end">
            <CmsButton onClick={handleChange}>Modifier</CmsButton>
            <CmsButton onClick={() => setOpen(false)}>Annuler</CmsButton>
          </div>
        </CmsPaper>
      </DialogVanillaUI>
    </>
  );
};

/**
 * Récupère les Zones payés
 * @param planning Semaine de temps de travail
 */
const getAreaList = (planning: WkTimeReportingWeek): string => {
  const areaDayList: WkTimeReportingDay[] = planning.dayList.filter((x: WkTimeReportingDay) => !!x.finalArea);
  let result = '';
  for (let i = 0; i < areaDayList.length; i++) {
    result += areaDayList[i].finalArea;
    if (i < areaDayList.length - 1) result += ', ';
  }
  if (result === '') return 'Aucune';
  return result;
};

/**
 * Affiche les alertes de la semaine
 * @param planning Semaine de temps de travail
 */
const WeeklyWarningList: FC<{ planning: WkTimeReportingWeek }> = ({ planning }) => {
  return (
    <CmsPaper title="Alertes de la semaine">
      <div className="flex-v commentBody" style={{ color: '#FF0' }}>
        {planning.warningList && planning.warningList.map((warning) => <div>{warning}</div>)}
      </div>
    </CmsPaper>
  );
};

/**
 * Affiche les commentaires de la semaine
 * @param planning Semaine de temps de travail
 */
const WeeklyComments: FC<{ planning: WkTimeReportingWeek }> = ({ planning }) => {
  return (
    <CmsPaper title="Commentaires de la semaine">
      <div className="flex-h-bet commentBody">
        <div>
          <h3>Technicien :</h3>
          {planning.comment}
        </div>
        <div>
          <h3>Manager :</h3>
          {planning.supervisorComment}
        </div>
        <div>
          <h3>RH :</h3>
          {planning.rhComment}
        </div>
      </div>
    </CmsPaper>
  );
};

/**
 * Affichage par défaut d'une semaine sans jours validés, sinon affichage normal
 * @param planning Semaine de temps de travail
 */
const TRDayList: FC<{ planning: WkTimeReportingWeek }> = ({ planning }) => {
  if (!planning.dayList || planning.dayList.length === 0) {
    return (
      <CmsPaper title="Semaine vide">
        <span style={{ textAlign: 'center' }}>Le technicien n'a pas encore validé de journée sur cette semaine</span>
      </CmsPaper>
    );
  }

  return (
    <>
      {planning.dayList.map((day, index) => (
        <DayTable key={index} index={index} day={day} />
      ))}
    </>
  );
};

const tableConfig: any[] = [
  { label: 'Action', value: null },
  { label: 'Ref Intervention', value: null },
  { label: 'Ref Chantier', value: '10em' },
  { label: 'Nom Site ou lieu', value: '10em' },
  { label: 'Enseigne', value: null },
  { label: 'Heure de début', value: null },
  { label: 'Heure de fin', value: null },
  { label: "Main d'œuvre", value: null },
  { label: "Main d'œuvre route", value: null },
  { label: 'KM', value: null },
  { label: 'Temps de pause', value: null },
  { label: 'Indemnité trajet', value: null },
  { label: 'Alerte', value: null },
  { label: <CmsIcon icon="edit" />, value: null },
];

/**
 * Tableau D'une journée de la semaine de feuille de temps
 * @param day Journée de la semaine
 * @param index Index de la journée dans la semaine (dans la liste du planning)
 */
const DayTable: FC<{ day: WkTimeReportingDay; index: number }> = ({ day, index }) => {
  const { planning, setPlanning } = useContext(PlanningContext);
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [isInvalidDay, setInvalidDay] = useState<boolean>(false);
  const [errorComment, setErrorComment] = useState<string>('');

  const handleChange = (value: any, attr: any) => {
    const tempDay: any = day;
    tempDay[attr] = value;
    planning.dayList[index] = tempDay;
    setPlanning(Object.assign({}, planning));
  };

  const modifyDay = () => {
    navigate(`/castres/timereporting/${planning.userId}/${Utils.setToKebabDate(day.dayDate)}`);
  };

  const refuseDay = () => {
    setLoading(true);
    CRUD.put<any>(APIRoute.WkTimeReportingDay + '/CancelDayValidated', {
      id: day.id,
      errorComment: errorComment,
      isModify: false,
    }).then((result) => {
      setLoading(false);
      if (result?.error) return;
      else NotificationService.success('Journée refusée avec succès');
      handleChange(0, 'status');
    });
    setInvalidDay(false);
  };

  return (
    <CmsPaper
      actions={[<DayUpdater day={day} />]}
      isLoading={isLoading}
      title={Utils.getDayOfTheWeek(day.dayDate) + ' ' + Utils.displayDate(day.dayDate)}
    >
      <DialogUI
        title="Refus de la journée"
        open={isInvalidDay}
        onClose={() => setInvalidDay(false)}
        onValidation={refuseDay}
      >
        <InputUI.DebouncedInput
          label="Motif de refus"
          delayTime={200}
          value={day.errorComment}
          onChange={(x: string) => setErrorComment(x)}
        />
      </DialogUI>
      <div className="chip-container">
        {planning.userId !== day.validatedBy && (
          <CMSChip label="Rempli par :" value={day.validatedByName} style={{ backgroundColor: '#907700' }} />
        )}
        {day.status === 0 && <WarningBubble customColor="#a81d26">Journée non validée par le technicien</WarningBubble>}
        <CMSChip label="Jour" value={Utils.getDayOfTheWeek(day.dayDate) + ' ' + Utils.displayDate(day.dayDate)} />
        <CMSChip label="Catégorie déplacement:" value={day.travelingTypeLabel} />
        <CMSChip label="Temps Travaillé:" value={pipeTime(day.totalWorkedTime ?? 0)} />
        {!!planning.totalAbsenceTime && <CMSChip label="Absence" value={pipeTime(day.totalAbsenceTime ?? 0)} />}
        {!!planning.totalPaidAbsenceTime && (
          <CMSChip label="Absence Idemnisé" value={pipeTime(day.totalPaidAbsenceTime ?? 0)} />
        )}
        {!!day.ticketRestaurant && <CMSChip label="Titre restaurant" value={day.ticketRestaurant} />}
        <CMSChip label="Indemnité trajet:" value={pipeTime(day.totalRouteTime ?? 0)} />
        <CMSChip label="Distance parcourue:" value={day.allTravelDistance + ' km'} />
        {(day?.finalArea ?? 0) > 0 && <CMSChip label="Zone payée:" value={day.finalArea} />}
      </div>
      <WarnDayList day={day} />
      <TableContainer style={{ borderRadius: '6px', border: '2px solid #888', overflow: 'auto' }}>
        <Table size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              {tableConfig.map((column, i) => (
                <TableCell key={i}>{column.label}</TableCell>
              ))}
              <TableCell style={{ backgroundColor: '#333' }}>Temps de Travail</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {day.actionList.map((action, index) => (
              <Row action={action} index={index} key={index} setLoading={setLoading} />
            ))}
            <FinalRow day={day} />
          </TableBody>
        </Table>
      </TableContainer>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', margin: '10px' }}>
        <InputUI.DebouncedInput
          style={{ width: '25%' }}
          disabled
          label={'Commentaire technicien'}
          value={day.comment}
          onChange={() => {}}
          multiline
        />
        <InputUI.DebouncedInput
          style={{ width: '25%' }}
          disabled={planning.status !== TimeReportingFormStep.TO_SUPERVISOR_VALIDATE}
          onChange={(e: any) => handleChange(e, 'SupervisorComment')}
          label={'Commentaire manager ' + (day.supervisorCommentName ?? '')}
          value={day.supervisorComment}
          multiline
        />
        <InputUI.DebouncedInput
          style={{ width: '25%' }}
          disabled={planning.status !== TimeReportingFormStep.TO_RH_VALIDATE}
          onChange={(e: any) => handleChange(e, 'rhComment')}
          label={'Commentaire RH ' + (day.rhCommentName ?? '')}
          value={day.rhComment}
          multiline
        />
        {planning.status !== TimeReportingFormStep.VALIDATED &&
          AccessFilter([ROLE.ADMIN_TIMEREPORTINGWEEK_VALIDATION, ROLE.USER_IS_MANAGER]) && (
            <>
              <CmsButton onClick={modifyDay}>Éditer la journée</CmsButton>
              {day.status !== 0 && <CmsButton onClick={() => setInvalidDay(true)}>Refuser la journée</CmsButton>}
            </>
          )}
      </div>
    </CmsPaper>
  );
};

const DayUpdater: FC<{ day: WkTimeReportingDay }> = ({ day }) => {
  const [open, setOpen] = useState(false);
  const [state, setState] = useState<any>({
    area: +(day.finalArea ?? 0),
    travelingType: day.travelingType,
    ticketRestaurant: day.ticketRestaurant,
  });
  const { planning, setPlanning, canEdit } = useContext(PlanningContext);

  useEffect(() => {
    setState({ area: +(day.finalArea ?? 0), travelingType: day.travelingType });
  }, [day.finalArea, day.travelingType]);

  if (!canEdit) return <></>;

  const update = () => {
    const payload = { id: day.id, ...state };
    CRUD.put<WkTimeReportingWeek>(APIRoute.WkTimeReportingWeek + '/DayAdminUpdate', payload).then((result) => {
      if (!result) return;
      NotificationService.success('Zone mise à jour avec succès');
      result.mondayDate = new Date(result.mondayDate);
      result.dayList = parseDateOfDayList(result.dayList);
      setPlanning(result);
      setOpen(false);
    });
  };

  const canEditArea = !planning.haveArea || [0, 3].indexOf(day.travelingType ?? 0) !== -1;

  return (
    <>
      <CmsButton onClick={() => setOpen(true)}>Mettre à jour la journée</CmsButton>
      <DialogVanillaUI open={open} onClose={() => setOpen(false)}>
        <CmsPaper style={{ marginBottom: 0 }} title="Mettre à jour la journée">
          {canEditArea && (
            <InputUI.DebouncedInput
              label="Zone"
              value={state.area}
              type="number"
              onChange={(area: number) => setState({ ...state, area })}
              valueCanUpdate
            />
          )}
          <InputUI.DebouncedInput
            label="Titre restaurant"
            value={state.ticketRestaurant}
            type="number"
            onChange={(ticketRestaurant: number) => setState({ ...state, ticketRestaurant })}
            valueCanUpdate
          />
          <InputUI.AutoCompletor
            label="Type de déplacement"
            value={day.travelingType}
            onChange={(travelingType: number) => setState({ ...state, travelingType })}
            options={travelingTypeList}
          />
          <CmsButton style={{ marginBottom: '0.5rem' }} onClick={update}>
            Mettre à jour
          </CmsButton>
          <CmsButton onClick={() => setOpen(false)}>Annuler</CmsButton>
        </CmsPaper>
      </DialogVanillaUI>
    </>
  );
};

const travelingTypeList = [
  { id: 0, label: 'Aucune' },
  { id: 1, label: 'Panier' },
  { id: 2, label: 'Petit déplacement' },
  { id: 3, label: 'Grand déplacement' },
  { id: 4, label: 'Retour grand déplacement' },
];

/**
 * Affichage des avertissements de la journée
 * @param day La journée
 */
const WarnDayList: FC<{ day: WkTimeReportingDay }> = ({ day }) => {
  if (!day.warningList || (day.warningList?.length ?? 0) < 1) return <></>;
  return (
    <div className="warn-day">
      {day.warningList.map((warning) => (
        <div>- {warning}</div>
      ))}
    </div>
  );
};

interface RowState {
  id?: number;
  labor?: number;
  roadLabor?: number;
  travelDistance?: number;
  routeIt?: number;
}

function createRowState(action: WkTimeReportingAction): RowState {
  return {
    id: action.id,
    labor: action.labor,
    roadLabor: action.roadLabor,
    travelDistance: action.travelDistance,
    routeIt: action.routeIt,
  };
}

interface RowProps {
  action: WkTimeReportingAction;
  index: number;
  setLoading: (isLoading: boolean) => void;
}

/**
 * Affichage d'une ligne de la table journée
 * @param action L'action (ligne)
 * @param index L'index de la ligne
 * @param setLoading Setter du chargement
 */
const Row: FC<RowProps> = ({ action, index, setLoading }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [state, setState] = useState(createRowState(action));
  const { setPlanning, canEdit } = useContext(PlanningContext);
  let className = (action?.warningList?.length ?? 0) > 0 ? 'table-row' : '';
  if (action?.warningList?.find((w) => w === "Cette tache n'était pas prévue")) className += ' not-planned';
  const interHref = `${BASE_URL}castres/work/intervention/${action.interventionId}/show`;

  useEffect(() => {
    setState(createRowState(action));
  }, [action]);

  const stopEdit = () => {
    setIsEditing(false);
    setState(createRowState(action));
  };

  const handleEdit = () => {
    if (!isEditing) {
      setIsEditing(true);
      return;
    }
    setLoading(true);
    CRUD.post<WkTimeReportingWeek>(APIRoute.WkTimeReportingWeek + '/UpdateAction', state)
      .then((result) => {
        result.mondayDate = new Date(result.mondayDate);
        result.dayList = parseDateOfDayList(result.dayList);
        setPlanning(result);
      })
      .finally(() => {
        setLoading(false);
        setIsEditing(false);
      });
  };
  let actionLabel = TRActionOption[action.actionType]?.label;
  if ([11, 12].includes(action.actionType) && action.absenceTypeLabel) actionLabel = action.absenceTypeLabel;
  return (
    <TableRow className={className}>
      <TableCell>{actionLabel}</TableCell>
      <TableCell>
        <MuiLink style={{ color: 'inherit' }} href={interHref} target="_blank">
          {action.interventionRef}
        </MuiLink>
      </TableCell>
      <TableCell>
        <TextEllipse text={action.taskRef} style={{ width: '80px' }} />
      </TableCell>
      <TableCell>
        <TextEllipse text={action.stationLabel} style={{ width: '110px' }} />
      </TableCell>
      <TableCell>{action.retailer}</TableCell>
      <TableCell>{Utils.displayTime(action.start)}</TableCell>
      <TableCell>{Utils.displayTime(action.end)}</TableCell>
      <EditableCell isEditing={isEditing} value={state.labor} onChange={(labor) => setState({ ...state, labor })} />
      <EditableCell
        isEditing={isEditing}
        value={state.roadLabor}
        onChange={(roadLabor) => setState({ ...state, roadLabor })}
      />
      <EditableCell
        isEditing={isEditing}
        value={state.travelDistance}
        type="number"
        onChange={(travelDistance) => setState({ ...state, travelDistance })}
      />
      <TableCell>{calcTimeIfAction(action, [TRActionType.PAUSE])}</TableCell>
      <EditableCell
        isEditing={isEditing}
        value={state.routeIt}
        onChange={(routeIt) => setState({ ...state, routeIt })}
      />
      <WarningActionInfo action={action} key={index} />
      <TableCell>
        {canEdit && (
          <div className="flex-h">
            <CmsIcon icon={isEditing ? 'save' : 'edit'} onClick={handleEdit} />
            {isEditing && <CmsIcon icon="close" onClick={stopEdit} />}
          </div>
        )}
      </TableCell>
      <TableCell style={{ backgroundColor: '#333' }}>{getTotalLabor(action)}</TableCell>
    </TableRow>
  );
};

/**
 * Calcule le temps total travaillé d'une action
 * @param action l'action à calculer
 */
function getTotalLabor(action: WkTimeReportingAction) {
  const result = (action.labor ?? 0) + (action.roadLabor ?? 0);
  return result > 0 ? Utils.minuteToStringHour(result) : null;
}

interface EditableCellProps {
  isEditing: boolean;
  value: any;
  onChange: (x: number) => void;
  type?: 'time' | 'number';
}

/**
 * Permet d'afficher un input ou un texte selon si on est en mode édition ou non
 * @param isEditing Si on est en mode édition
 * @param value La valeur à afficher
 * @param onChange La fonction de changement de valeur
 * @param type le type d'input
 */
const EditableCell: FC<EditableCellProps> = ({ isEditing, value, onChange, type = 'time' }) => {
  if (!value && !isEditing) return <TableCell />;
  if (!isEditing) return <TableCell>{type === 'time' ? Utils.minuteToStringHour(value) : value}</TableCell>;

  const getMinutesFromDate = (date: Date): number => {
    const result = date.getHours() * 60 + date.getMinutes();
    console.log('result', result);
    return result;
  };
  const minuteToDate = (minutes: number): Date => {
    const date = new Date();
    date.setHours(0);
    date.setMinutes(minutes);
    console.log('date', date);
    return date;
  };

  return (
    <TableCell>
      {(type === 'time' && (
        <InputUI.CMSTimePicker value={minuteToDate(value)} onChange={(x: any) => onChange(getMinutesFromDate(x))} />
      )) || (
        <InputUI.DebouncedInput
          style={{ marginBottom: 0, minWidth: '6em' }}
          type="number"
          value={value}
          onChange={onChange}
        />
      )}
    </TableCell>
  );
};

/**
 * Calcule de la ligne final d'un tableau de journée qui fait la somme des temps / distance
 * @param day La journée
 */
const FinalRow: FC<{ day: WkTimeReportingDay }> = ({ day }) => {
  const totalWork = day.actionList.reduce((accumulator: any, iterator) => accumulator + (iterator.labor ?? 0), 0);
  const totalRoadLabor = day.actionList.reduce((x, y) => x + (y.roadLabor ?? 0), 0);
  const totalRouteIT = day.actionList.reduce((x, y) => x + (y.routeIt ?? 0), 0);
  const totalPauseTime = day.actionList
    .filter((x) => x.actionType === TRActionType.PAUSE)
    .reduce((x, y) => x + (new Date(y.end).getTime() - new Date(y.start).getTime()), 0);
  const totalWorkedTime = day.actionList.reduce((x, y) => {
    return x + (y.labor ?? 0) + (y.roadLabor ?? 0);
  }, 0);
  return (
    <TableRow style={{ backgroundColor: '#333' }}>
      {[0, 0, 0, 0, 0, 0, 0].map(() => (
        <TableCell />
      ))}
      <TableCell>{Utils.minuteToStringHour(totalWork)}</TableCell>
      <TableCell>{totalRoadLabor > 0 && Utils.minuteToStringHour(totalRoadLabor)}</TableCell>
      <TableCell>{!day.allTravelDistance ? '' : day.allTravelDistance}</TableCell>
      <TableCell>{Utils.minuteToStringHour(totalPauseTime / 60000)}</TableCell>
      <TableCell>{totalRouteIT > 0 && Utils.minuteToStringHour(totalRouteIT)}</TableCell>
      <TableCell />
      <TableCell />
      <TableCell>{Utils.minuteToStringHour(totalWorkedTime)}</TableCell>
    </TableRow>
  );
};

// Calcule le temps entre deux dates
const calcTimeIfAction = (action: WkTimeReportingAction, actionType: Array<TRActionType>, bypass = false): string => {
  if (!bypass && !actionType.includes(action.actionType)) return '';
  return Utils.timeBetweenDates(action.start, action.end);
};

interface WarningActionInfoProps {
  // L'action de la feuille de temps
  action: WkTimeReportingAction;
  // La clé de l'action
  key: number;
}

/**
 * Affichage des avertissements d'une action
 * @param action L'action
 * @param key La clé de l'action
 */
const WarningActionInfo: FC<WarningActionInfoProps> = ({ action, key }) => {
  if (!action.warningList || action.warningList?.length < 1) return <TableCell />;
  return (
    <TableCell>
      <UI.Pop
        parent={
          <IconButton style={{ margin: '-1em' }} size="large">
            <WarningIcon fontSize="small" />
          </IconButton>
        }
      >
        {action.warningList.map((warning) => (
          <div> - {warning}</div>
        ))}
      </UI.Pop>
    </TableCell>
  );
};

/**
 * Pied de page d'une feuille de temps, contient les commentaires de la semaine, ainsi que les
 * boutons de refus ou de modification d'une semaine
 * @param planning la feuille de temps
 */
const TRFooter: FC<{ planning: WkTimeReportingWeek }> = ({ planning }) => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [comment, setComment] = useState(
    planning.status === TimeReportingFormStep.TO_RH_VALIDATE ? planning.rhComment : planning.supervisorComment,
  );

  if (
    planning.status > 2 ||
    (planning.status === TimeReportingFormStep.TO_RH_VALIDATE &&
      !AccessFilter([ROLE.ADMIN_TIMEREPORTINGWEEK_VALIDATION]))
  )
    return <></>;

  const goToList = () => navigate(`/castres/timereporting/administration`);
  const refusePlanning = () => {
    setLoading(true);
    planning.status === TimeReportingFormStep.TO_RH_VALIDATE
      ? (planning.rhComment = comment)
      : (planning.supervisorComment = comment);
    CRUD.put<any>(APIRoute.WkTimeReportingWeek + '/CancelWeekValidated', { id: planning.id })
      .then(() => {
        NotificationService.info('Semaine refusée avec succès');
        goToList();
      })
      .finally(() => setLoading(false));
  };
  const goToWeek = () => {
    navigate(`/castres/timereporting/${planning.userId}/${Utils.setToKebabDate(planning.mondayDate)}`);
  };

  const validateWeek = () => {
    setLoading(true);
    planning.status === TimeReportingFormStep.TO_RH_VALIDATE
      ? (planning.rhComment = comment)
      : (planning.supervisorComment = comment);
    CRUD.put<any>(APIRoute.WkTimeReportingWeek + '/Administration/ValidateWeek', planning).then(() => {
      setLoading(false);
      NotificationService.success('Semaine validée avec succès');
      goToList();
    });
  };

  let title = 'Validation ';
  if (planning.status === 1) title += 'manager';
  else if (planning.status === 2) title += 'RH';

  const GoToWeekButton: FC = () => (
    <CmsButton size="large" onClick={goToWeek}>
      Semaine non validée, aller à la semaine du technicien
    </CmsButton>
  );

  const RefuseButton: FC = () => (
    <CmsButton size="large" onClick={refusePlanning} color="secondary">
      Refuser la feuille de temps
    </CmsButton>
  );

  const ValidButton: FC = () => (
    <CmsButton size="large" color="primary" onClick={validateWeek}>
      Valider la feuille de temps
    </CmsButton>
  );

  return (
    <CmsPaper isLoading={isLoading} title={title}>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
        {(planning.status === TimeReportingFormStep.TO_COMPLETE && <GoToWeekButton />) ||
          (planning.status !== TimeReportingFormStep.VALIDATED && (
            <>
              <RefuseButton />
              <InputUI.DebouncedInput
                style={{ width: '25%' }}
                onChange={setComment}
                label="Commentaire hebdomadaire :"
                value={comment}
                multiline
              />
              <ValidButton />
            </>
          ))}
      </div>
    </CmsPaper>
  );
};

const downloadTimeReportingPDF = (event: any, userId: number, dateOfWeek: Date) => {
  CRUD.getBlob(`${APIRoute.WkTimeReportingWeek}/ExportPdf/${userId}?dateOfWeek=${dateOfWeek.toISOString()}`).then(
    (blob) => {
      Utils.downloadFile(blob, `Feuille_Heures_User_${userId}.pdf`);
    },
  );
};

export default TimeReportingAdministration;
