import React, { FC, useEffect, useState } from 'react';
import { CmsButton, CmsPaper, WarningBubble } from '../shared/Ui';
import { Buttons, InputUI, UI } from '../shared';
import { TabStyleDataView } from '../shared/TabStyleDataView';
import CmsIcon from '../shared/CmsIcon';
import Utils from '../../helper/Utils';
import codesNaf from '@socialgouv/codes-naf/index.json';
import { Grid } from '@mui/material';
import CRUD from 'service/CRUD.service';
import APIRoute from 'constant/API.constant';

const enterpriseURl = 'https://annuaire-entreprises.data.gouv.fr/entreprise/';
const webUrl = 'https://annuaire-entreprises.data.gouv.fr/rechercher?terme=';

export const SiretLinkIcon: FC<{ siret?: string }> = ({ siret }) => {
  if (!siret || siret.length !== 14) return <></>;
  const url = enterpriseURl + siret?.slice(0, 9);
  const params = { style: { margin: '-0.3rem 0' }, icon: 'info', onClick: () => window.open(url, '_blank') };
  return <CmsIcon {...(params as any)} tooltip="Voir dans l'annuaire" />;
};

export const InseeNameCityLinkIcon: FC<{ name: string; city: string }> = ({ name, city }) => {
  let url = webUrl + name;
  if (city) url = `${webUrl + name}, ${city}`;
  const params = { style: { margin: '-0.3rem 0' }, icon: 'info', onClick: () => window.open(url, '_blank') };
  return <CmsIcon {...(params as any)} tooltip="Voir dans l'annuaire proposé" />;
};

interface ImportClientInseeDataProps {
  form: any;
  mandatory?: boolean;
  getSiege?: boolean;
}

export const ImportClientInseeData: FC<ImportClientInseeDataProps> = ({ form, mandatory, getSiege = true }) => {
  const formData = form.watch();
  const siret = formData.siret;
  let defaultSearch = siret;
  if (!defaultSearch)
    defaultSearch = (
      (formData.companyName ?? formData.name ?? formData.label ?? '') +
      ' ' +
      (formData.postcode ?? '')
    ).trim();
  const [open, setOpen] = useState(false);
  const [firstOpen, setFirstOpen] = useState(true);
  const [searching, setSearching] = useState(false);
  const [search, setSearch] = useState(defaultSearch);
  const [data, setData] = useState<any>();

  useEffect(() => {
    setSearch(defaultSearch);
  }, [defaultSearch]);

  const getData = () => {
    setSearching(true);
    getSiretData(search, getSiege)
      .then(setData)
      .finally(() => setSearching(false));
  };

  if (firstOpen && open) {
    setFirstOpen(false);
    if (search) getData();
  }

  const handleImport = () => {
    const lastApiSync = data.lastApiSync;
    data.lastApiSync = null;
    form.reset({ ...form.watch(), ...data });
    // Malheureusement c'est la seule manière de forcer le dirty sur le composant useForm
    setTimeout(() => {
      form.setValue('lastApiSync', lastApiSync, { shouldDirty: true });
    }, 100);
    setOpen(false);
  };

  const handleHelpClick = () => {
    let url = webUrl + (formData?.label ?? formData?.name);
    if (formData?.city) url += ', ' + formData?.city;
    window.open(url, '_blank');
  };

  const handleSearchHelpLink = () => window.open(webUrl + search, '_blank');
  const help = <CmsButton onClick={handleHelpClick}>Annuaire Entreprises</CmsButton>;
  const dataField = getSiege
    ? importDataFields.filter((x) => x.siege !== false)
    : importDataFields.filter((x) => x.siege !== true && x.isSupplier !== false);
  return (
    <>
      <CmsButton className={mandatory ? 'hint-button' : ''} onClick={() => setOpen(true)}>
        Import Insee
      </CmsButton>
      <UI.Dialog maxWidth="xl" open={open} onClose={() => setOpen(false)}>
        <CmsPaper
          title="Import des données INSEE"
          style={{ marginBottom: 0, minWidth: '60vw' }}
          actions={[help, <CmsIcon icon="close" onClick={() => setOpen(false)} />]}
        >
          <div className="flex-h align-center">
            <div style={{ width: '70%' }}>
              <InputUI.CMSTextField value={search} onChange={setSearch} />
            </div>
            <div>
              <Buttons.Default
                style={{ margin: ' 0 0 1rem 1rem' }}
                size="medium"
                disabled={searching || !search}
                onClick={getData}
              >
                Rechercher
              </Buttons.Default>
            </div>
          </div>
          <Grid container spacing={2}>
            <Grid item sm={12} lg={6}>
              <h2>Données actuelles</h2>
              <TabStyleDataView conf={dataField} mapFrom={formData} />
            </Grid>
            <Grid item sm={12} lg={6}>
              <h2>Données trouvées</h2>
              {!data?.lastApiSync ? (
                <div>Aucun import effectué</div>
              ) : !data.multipleResults ? (
                <TabStyleDataView conf={dataField} mapFrom={data ?? []} />
              ) : (
                <WarningBubble style={{ marginTop: '1em' }}>
                  Votre recherche a remonté plusieurs résultats, veuillez choisir le bon dans cette liste
                  <CmsButton onClick={handleSearchHelpLink}>Annuaire Entreprises</CmsButton>
                  et coller son SIREN dans le champ de recherche
                </WarningBubble>
              )}
            </Grid>
          </Grid>
          {!!data && !!siret && siret.length === 14 && data.siret !== siret && !data.multipleResults && (
            <UI.WarningBubble style={{ marginTop: '1em' }}>
              Attention ! Le numéro SIRET trouvé ne correspond pas à celui actuel <br />
              (SIRET Actuel : {siret})<br />
              (SIRET Trouvé : {data?.siret})<br />
              Cela peut être dû à un changement de siège de la part de l'entreprise ou à une erreur de saisie. <br />
              Veuillez vérifier les données ici <SiretLinkIcon siret={data?.siret} /> avant d'importer.
            </UI.WarningBubble>
          )}
          <UI.Divider />
          <div className="flex-h end">
            <Buttons.Cancel onClick={() => setOpen(false)}>Annuler</Buttons.Cancel>
            <Buttons.Valid
              style={{ marginLeft: '0.5rem' }}
              disabled={searching || !data || !!data?.multipleResults}
              onClick={handleImport}
            >
              Importer
            </Buttons.Valid>
          </div>
        </CmsPaper>
      </UI.Dialog>
    </>
  );
};

const importDataFields: any[] = [
  { label: 'Raison sociale', value: (data: any) => data.label ?? data.name, siege: true },
  { label: 'Nom', value: (data: any) => data.label ?? data.name, siege: false },
  { label: 'Nom commercial', value: (data: any) => data.commercialName, siege: true },
  { label: 'Adresse', value: (data: any) => data.address },
  { label: 'Code Postal', value: (data: any) => data.postcode },
  { label: 'Ville', value: (data: any) => data.city },
  { label: 'TVA', value: (data: any) => data.tva, isSupplier: false },
  { label: 'APE', value: (data: any) => data.ape, isSupplier: false },
  {
    label: 'Titre APE',
    value: (data: any) => codesNaf.find((x) => x.id === data.ape)?.label ?? 'Non trouvé',
    isSupplier: false,
  },
  {
    label: 'Date création entreprise',
    value: (data: any) => Utils.displayDate(data.companyCreationDate),
    isSupplier: false,
  },
  { label: 'Latitude', value: (data: any) => data.latitude },
  { label: 'Longitude', value: (data: any) => data.longitude },
  { label: 'En activité', value: (data: any) => (data.active ? 'Oui' : 'Non'), isSupplier: false },
  { label: 'SIRET', value: (data: any) => [data.siret, <SiretLinkIcon siret={data.siret} />] },
];

export interface InseeCompanyResource {
  label: string;
  commercialName: string;
  address: string;
  postcode: string;
  city: string;
  country: string;
  siret: string;
  siren: string;
  ape: string;
  latitude: string;
  longitude: string;
  companyCreationDate: string;
  etablissementCreationDate: string;
  active: boolean;
  tva: string;
  contacts: [InseeDirigeantResource];
  // champs ajoutés par le front
  professional?: boolean;
  lastApiSync?: Date;
  multipleResults?: boolean;
  name?: string;
}

export interface InseeDirigeantResource {
  lastname: string;
  firstname: string;
  nationality: string;
  birthdate: string;
  job: string;
}

export async function getSiretData(siret: string, getSiege = true): Promise<InseeCompanyResource | null> {
  const result = await CRUD.getList<InseeCompanyResource>(
    `${APIRoute.Other}/SearchInsee?search=${siret}&headOffice=${getSiege}`,
  );
  if (result.length === 0) return null;
  const firstResult = result[0];
  firstResult.professional = true;
  firstResult.lastApiSync = new Date();
  firstResult.multipleResults = result.length > 1;
  firstResult.name = firstResult.label;
  return firstResult;
}

export async function getCompanyLeaderData(siret: string): Promise<InseeDirigeantResource[]> {
  const result = await CRUD.getList<InseeCompanyResource>(`${APIRoute.Other}/SearchInsee?search=${siret}`);
  if (result.length === 0) return [];
  return result[0].contacts;
}

export async function getCompanySupplierData(siret: string): Promise<InseeCompanyResource[]> {
  const result = await CRUD.getList<InseeCompanyResource>(
    `${APIRoute.Other}/SearchInsee?search=${siret}&fetchEstablishments=true`,
  );
  result.forEach((company) => {
    company.professional = true;
    company.lastApiSync = new Date();
    company.name = company.label;
  });
  return result;
}
