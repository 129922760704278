import React, { FC, ReactNode, useEffect } from 'react';
import AccessFilter from '../../../helper/AccessFilter';
import {
  AddNavigationButton,
  ArrowBackNavigationButton,
  EditNavigationButton,
  LinkWithAccess,
  ViewNavigationButton,
} from '../../../component/shared/Buttons';
import APIRoute from '../../../constant/API.constant';
import { CmsPaper, HeaderPanel } from '../../../component/shared/Ui';
import { ClContact, ClStation } from '../../../interface/ClType';
import { useNavigate } from 'react-router-dom';
import CRUD from '../../../service/CRUD.service';
import LoadingScreen from '../../../component/LoadingScreen';
import { LabelValue } from '../../../interface/CommonType';
import { TabStyleDataView } from '../../../component/shared/TabStyleDataView';
import Grid from '@mui/material/Grid';
import { CmsColumnDef, CmsFrontendTable } from '../../../component/table/CmsTable';
import CmsTableFilter from '../../../component/table/helper/CmsTableFilter';
import { CmsForm } from '../../../component/form/CmsForm';
import { CmsFormInput } from 'component/form/CmsFormInput';
import { UI } from '../../../component/shared';
import { JoinTableHandler } from '../../../component/table/JoinTableHandler';
import { StationListConfig } from '../../station/StationList';
import ROLE from '../../../constant/role.constant';

export const ClContactConfigList: CmsColumnDef<ClContact>[] = [
  { header: 'Nom', id: 'name', Filter: CmsTableFilter.Text },
  {
    header: 'Code Client',
    id: 'codeId',
    Filter: CmsTableFilter.Select,
    filterOptions: { optionList: APIRoute.ClClientCode },
    hide: true,
  },
  { header: 'Client', id: 'clientLabel', Filter: CmsTableFilter.Text },
  { header: 'Téléphone', id: 'tel', Filter: CmsTableFilter.Text },
  { header: 'Mobile', id: 'mobile', Filter: CmsTableFilter.Text, hide: 'hiddenByDefault' },
  { header: 'Code Postal', id: 'postcode', Filter: CmsTableFilter.Text },
  { header: 'Ville', id: 'city', Filter: CmsTableFilter.Text },
  { header: 'Qualité', id: 'job', Filter: CmsTableFilter.Search },
  { header: 'Email', id: 'email', Filter: CmsTableFilter.Text },
  { header: 'Identifiant Client', id: 'clientId', Filter: CmsTableFilter.Text, hide: true },
  { header: 'Actif', id: 'active', Filter: CmsTableFilter.Bool, hide: 'hiddenByDefault', defaultFilterValue: true },
];

export const ClContactList: FC = () => {
  const actions = AccessFilter([ROLE.ADMIN_CONTACT_CREATE])
    ? [<AddNavigationButton title="Ajouter un contact" to="/castres/client/contact/create" />]
    : [];
  return (
    <CmsFrontendTable
      columns={ClContactConfigList}
      title="Liste des contacts"
      route={APIRoute.ClContact}
      navigateTo={(id: number) => `/castres/client/contact/${id}/show`}
      downloadable="csv&excel"
      actions={actions}
      setFiltersInUrl
    />
  );
};

export const ClContactShow: FC = ({ id }: any) => {
  const [contact, setContact] = React.useState<ClContact | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!id) navigate('/castres/client/contact/list');
    else CRUD.getById<ClContact>(APIRoute.ClContact, id).then(setContact);
  }, [navigate, id]);

  if (!contact) return <LoadingScreen />;
  return (
    <>
      <HeaderPanel
        title={`${contact?.name}`}
        actions={[
          <ArrowBackNavigationButton title="Retourner à la liste" to="/castres/client/contact/list" />,
          AccessFilter([ROLE.ADMIN_CONTACT_EDIT]) && (
            <EditNavigationButton title="Éditer le contact" to={`/castres/client/contact/${contact?.id}/edit`} />
          ),
        ]}
      />
      <Grid container spacing={2}>
        <Grid item sm={12} lg={4}>
          <CmsPaper title="Général" className="contact-general">
            <TabStyleDataView conf={ContactGeneralConfig} mapFrom={contact} />
          </CmsPaper>
        </Grid>
        <Grid item sm={12} lg={4}>
          <CmsPaper title="Contact" className="contact-contact">
            <TabStyleDataView conf={ContactContactConfig} mapFrom={contact} />
          </CmsPaper>
        </Grid>
      </Grid>
      <ClContactSiteList contact={contact} />
    </>
  );
};

const ContactGeneralConfig: LabelValue[] = [
  {
    label: 'Client',
    value: (x: ClContact) => (
      <LinkWithAccess
        title={x.clientLabel}
        to={`/castres/client/client/${x.clientId}/show`}
        access={[ROLE.ADMIN_CLIENT_VIEW]}
      />
    ),
  },
  { label: 'Prénom', value: (x: ClContact) => x.firstname },
  { label: 'Nom', value: (x: ClContact) => x.lastname },
  { label: 'Qualité', value: (x: ClContact) => x.job },
  { label: 'Email', value: (x: ClContact) => <UI.MailLink mail={x.email} /> },
  { label: 'Actif', value: (x: ClContact) => (x.active ? 'Oui' : 'Non') },
];

const ContactContactConfig: LabelValue[] = [
  { label: 'Adresse', value: (x: ClContact) => <UI.Multiline text={x.address} /> },
  { label: 'Code Postal', value: (x: ClContact) => x.postcode },
  { label: 'Ville', value: (x: ClContact) => x.city },
  { label: 'Portable', value: (x: ClContact) => <UI.PhoneLink phone={x.mobile} /> },
  { label: 'Téléphone fixe', value: (x: ClContact) => <UI.PhoneLink phone={x.tel} /> },
];

export const ClContactSiteList: FC<{ contact: ClContact }> = ({ contact }) => {
  const [contactStation, setContactStation] = React.useState<ClStation[] | null>([]);

  useEffect(() => {
    CRUD.getList<ClStation>(APIRoute.ClStation + '/ListByContact/' + contact.id).then(setContactStation);
  }, [contact.id]);

  const manyToMany: ReactNode[] = [
    <JoinTableHandler
      buttonLabel="Gérer les sites du contact"
      originId={contact.id}
      route={APIRoute.ClStation}
      columns={StationListConfig}
      originType={APIRoute.ClContact}
      onUpdate={() =>
        CRUD.getList<ClStation>(APIRoute.ClStation + '/ListByContact/' + contact.id).then(setContactStation)
      }
    />,
  ];

  return (
    <CmsFrontendTable
      title="Liste des sites du contact"
      columns={StationListConfig}
      actions={manyToMany}
      route="none"
      controlledState={{ state: contactStation ?? [], setState: setContactStation }}
      navigateTo={(id: number) => `/castres/client/station/${id}/show`}
      setFiltersInUrl
    />
  );
};

export const ClContactCreateEdit: FC = ({ id }: any) => {
  let view = <ViewNavigationButton title="Voir" to={`/castres/client/contact/${id}/show`} />;
  if (!id) view = <></>;
  return (
    <CmsForm
      id={id}
      title="un contact"
      actions={[view]}
      currentUrl="/castres/client/contact/"
      defaultValues={{ active: true }}
      route={APIRoute.ClContact}
      paperStyle={{ maxWidth: '1200px' }}
      goToOnValidForm={(id) => `/castres/client/contact/${id}/show`}
      deletable
    >
      <Grid container spacing={2}>
        <Grid item sm={12} lg={6}>
          <h2 style={{ textAlign: 'center' }}>Général</h2>
          <CmsFormInput.Select id="clientId" label="Client" options={APIRoute.ClClient} required />
          <CmsFormInput.Text id="firstname" label="Prénom" required max={255} />
          <CmsFormInput.Text id="lastname" label="Nom" required max={255} />
          <CmsFormInput.Text id="job" label="Qualité" max={255} />
          <CmsFormInput.Text id="email" label="Email" max={100} />
          <CmsFormInput.Switch id="active" label="Actif" />
        </Grid>
        <Grid item sm={12} lg={6}>
          <h2 style={{ textAlign: 'center' }}>Contact</h2>
          <CmsFormInput.Text id="address" label="Adresse" max={50} multiline />
          <CmsFormInput.Text id="postcode" label="Code Postal" max={10} />
          <CmsFormInput.Text id="city" label="Ville" max={100} />
          <CmsFormInput.Text id="mobile" label="Mobile" max={20} />
          <CmsFormInput.Text id="tel" label="Téléphone fixe" max={20} isPhone />
        </Grid>
      </Grid>
    </CmsForm>
  );
};
