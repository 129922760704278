import React, { FC, ReactNode, useEffect, useMemo } from 'react';
import { CmsButton, CmsDialog } from './Ui';
import { CmsColumnDef, CmsFrontendTable } from '../table/CmsTable';
import { InputUI, UI } from './index';
import { Cell } from '@tanstack/react-table';
import './shared.scss';
import CRUD from '../../service/CRUD.service';
import { Control, FieldValues, FormState } from 'react-hook-form';
import { Autocomplete, TextField } from '@mui/material';

interface AutocompletorProps {
  // Libellé du champ
  label?: string;
  // Nom du champ
  name?: string;
  variant?: 'outlined' | 'standard' | 'filled';
  // Style à appliquer sur le champ
  style?: React.CSSProperties;
  // Style à appliquer sur le champ
  inputStyle?: React.CSSProperties;
  // Défini si le champ est requis ou non
  required?: boolean;
  // Retourne tout l'objet ou seulement la valeur
  returnObject?: boolean;
  // La valeur à afficher dans le champ si ce dernier est vide
  placeholder?: string;
  // Gestionnaire d'erreurs, affiche un message d'erreur si la valeur est incorrect (à vous de gérer les erreurs)
  errors?: any;
  // Taille du champ (horizontal) (par défaut 100%)
  width?: string;
  // Défini si le champ est désactivé ou non (à ne pas confondre avec visible ou non)
  disabled?: boolean;
  // Ajouter un contrôle sur le champ (react-hook-form) pour qu'il puisse être gérer par le formulaire
  control?: Control<any>;
  // Les règles de validation du champ
  rules?: object;
  // Liste des options proposées format par défaut : [{id: 1, label: 'test'}]
  options: Array<any>;
  // Propriété à utiliser pour la valeur de l'option, surcharge la valeur par défaut 'id'
  optionValue?: string;
  // Propriété à utiliser pour le label de l'option, surcharge la valeur par défaut 'label'
  optionLabel?: string;
  // Défini si le champ est multi-select ou non
  multiple?: boolean;
  // Défini le nombre d'élément à afficher dans le champ
  limitTags?: number;
  // Fonction appelée lors de la selection d'un élément (la méthode onChange est géré par le hookForm),
  // celle-ci permet de récupérer la valeur de l'élément sélectionné
  directOnChange?: any;
  // Valeur de l'élément(s) sélectionné (a ne pas confondre avec la valeur afficher dans le champ)
  value: any;
  // Fonction appelée lors de la selection d'un élément
  onChange: any;
  // Erreur du champ (géré par le hookForm)
  error?: any;
  // Texte d'aide du champ (géré par le hookForm) lorsqu'une erreur est présente
  helperText?: string;
  // Propriété supplémentaire à passer au composant Autocomplete directement
  extendsProps?: any;
  renderOption?: (props: any, option: any) => ReactNode;
  onOpen?: () => void;
  formState?: FormState<FieldValues>;
  readOnly?: boolean;
  inputRef?: any;
}

/**
 * Champ de type Selection avec option de remplissage, intégralement géré côté front,
 * Ce composant propose la selection OU la multi-selection d'élément dans une liste donnée
 * Liste étant proposé / filtré au fur et à mesure que l'ont rempli le champ
 * @param options Liste des options proposées format par défaut : [{id: 1, label: 'test'}]
 * @param optionLabel Propriété à utiliser pour le label de l'option, surcharge la valeur par défaut 'label'
 * @param optionValue Propriété à utiliser pour la valeur de l'option, surcharge la valeur par défaut 'id'
 * @param required Champ requis ou non
 * @param name Nom du champ
 * @param label Libellé du champ
 * @param width Largeur du champ (par défaut 100%)
 * @param multiple Défini si le champ est multi-select ou non
 * @param value Valeur de l'élément(s) sélectionné (a ne pas confondre avec la valeur afficher dans le champ)
 * @param disabled Défini si le champ est désactivé ou non
 * @param onChange Fonction appelée lors de la selection d'un élément
 * @param error Erreur du champ (géré par le hookForm)
 * @param helperText Texte d'aide du champ (géré par le hookForm) lorsqu'une erreur est présente
 * @param extendsProps Propriété supplémentaire à passer au composant Autocomplete directement
 * @param style Style du champ FormField
 * @param inputStyle Style du champ Autocomplete
 * @param inputRef référence de l'input pour le focus
 */
export const AutoCompletor: FC<AutocompletorProps> = ({
  options,
  optionLabel = 'label',
  variant = 'outlined',
  optionValue,
  required = false,
  name,
  label,
  width,
  multiple = false,
  value,
  disabled = false,
  limitTags = 10,
  onChange,
  error,
  extendsProps,
  style,
  inputStyle,
  renderOption,
  onOpen,
  formState,
  readOnly = false,
  returnObject,
  inputRef,
}) => {
  const safeOptions = useMemo(() => {
    return options.filter((x) => x[optionValue ?? 'id'] !== undefined && !!x[optionLabel] && x[optionLabel] !== '');
  }, [optionLabel, optionValue, options]);

  const handleChange = (dataOutput: any): any => {
    if (dataOutput === undefined || dataOutput === null) return undefined;
    if (returnObject) return dataOutput;
    if (!multiple) return dataOutput[optionValue ?? 'id'];
    const result: Array<any> = [];
    dataOutput.map((item: any) => result.push(item[optionValue ?? 'id']));
    return result;
  };
  const handleValue = (value: any): any => {
    if ((!value && value !== 0) || !options || options?.length < 1) return multiple ? [] : null;
    if (!multiple) return options.find((option: any) => option[optionValue ?? 'id'] === value) ?? null;
    if (!Array.isArray(value)) {
      console.warn('Warning: value is not an array', value);
      return [];
    }
    let valueToReturn: any[] = value;
    if (returnObject) valueToReturn = value.map((x: any) => x[optionValue ?? 'id']);
    const result: any = [];
    for (const val of valueToReturn) {
      const opt = options.find((option: any) => option[optionValue ?? 'id'] === val);
      if (opt) result.push(opt);
    }
    return result;
  };
  if (!safeOptions) return <></>;
  return (
    <UI.FormField style={style}>
      <UI.LabelWithError label={label} required={required} name={name ?? ''} formState={formState} />
      <Autocomplete
        disableCloseOnSelect={multiple}
        multiple={multiple}
        id={name}
        className={'auto-completor' + (multiple ? ' multi' : '')}
        readOnly={readOnly}
        limitTags={limitTags}
        onOpen={onOpen}
        open={onOpen ? false : undefined}
        disabled={disabled}
        options={safeOptions}
        size="small"
        style={{ width: width ?? '100%', ...inputStyle }}
        onChange={(_, data: any) => onChange(handleChange(data))}
        getOptionLabel={(option) => (option[optionLabel] ? option[optionLabel] : multiple ? [] : '')}
        filterSelectedOptions
        renderInput={(params) => <TextField variant={variant} inputRef={inputRef} {...params} error={error} />}
        renderOption={renderOption}
        value={handleValue(value)}
        {...extendsProps}
      />
    </UI.FormField>
  );
};

interface AutoCompletorWithTableProps {
  value: any | any[];
  onChange: any;
  options: any[] | string;
  label?: string;
  optionLabel?: string;
  tableTitle: string;
  columns: CmsColumnDef<any>[];
  multiple?: boolean;
  showAsButton?: boolean;
  tableActions?: ReactNode[] | Function[];
}

export const AutoCompletorWithTable: FC<AutoCompletorWithTableProps> = ({
  value,
  label,
  tableTitle,
  tableActions = [],
  options,
  onChange,
  columns,
  optionLabel,
  multiple,
  showAsButton,
}) => {
  const [open, setOpen] = React.useState(false);
  const [state, setState] = React.useState<any[]>([]);

  const col = useMemo(() => {
    const cell = (info: any) => <InputUI.CmsSwitch value={info.getValue() ?? false} onChange={() => {}} />;
    return [{ header: 'Sélection', id: 'checked', size: 0, cell }, ...columns] as CmsColumnDef<any>[];
  }, [columns]);

  useEffect(() => {
    const fillData = (data: any[]) => data.map((x: any) => ({ ...x, checked: false }));
    if (Array.isArray(options)) setState(fillData(options));
    else CRUD.getList<any>(options).then((res) => setState(fillData(res)));
  }, [multiple, options]);

  const dataList = useMemo(() => {
    return state.map((x: any) => ({ ...x, checked: multiple ? value?.includes(x.id) : value === x.id }));
  }, [multiple, state, value]);

  const handleCellClick = (cell: Cell<any, any>) => {
    const id = cell.row.original.id;
    onChange(!multiple ? id : value?.includes(id) ? value.filter((x: any) => x !== id) : [...value, id]);
  };

  const actionList = [
    ...tableActions.map((x: any) => (typeof x === 'function' ? x(setOpen) : x)),
    <CmsButton onClick={() => onChange(multiple ? [] : undefined)}>Vider</CmsButton>,
    <CmsButton onClick={() => setOpen(false)}>Fermer</CmsButton>,
  ];

  return (
    <>
      {(showAsButton && <CmsButton onClick={() => setOpen(true)}>{label ?? tableTitle}</CmsButton>) || (
        <AutoCompletor
          optionValue="id"
          label={label ?? tableTitle}
          value={value}
          onChange={onChange}
          options={state}
          optionLabel={optionLabel}
          onOpen={() => setOpen(true)}
          multiple={multiple}
        />
      )}
      <CmsDialog
        className="auto-completor-with-table"
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth="xl"
      >
        <CmsFrontendTable
          route="none"
          columns={col}
          title={tableTitle}
          actions={actionList}
          onCellClick={handleCellClick}
          controlledState={{ state: dataList, setState }}
          paperStyle={{ marginBottom: '0px', width: '100%' }}
          disableResetPageIndex
        />
      </CmsDialog>
    </>
  );
};
