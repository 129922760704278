import React, { FC, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { authenticationService } from '../service/Authentication.service';
import ErrorPage from './ErrorPage';
import AccessFilter from '../helper/AccessFilter';
import { NotificationContext } from '../context/Notification.context';
import { ENV_ISDEV } from '../constant/API_URL';
import { WaitingConnectionScreen } from './shared/Ui';
import { PathRouteProps } from 'react-router';

interface PrivateRouteProps {
  Component: any;
  // Droits d'accès à la page (voir le fichier AccessFilter.ts) et les features / roles / ActionPermission (backend)
  access?: string[];
  // Titre de la page
  title: string;
  // Si la page est réservée à l'environnement de développement (localhost & cms-develop)
  devOnly?: boolean;
}

/**
 * Composant permettant de gérer les routes privées (avec accès) de l'application
 * @param Component Composant à afficher
 * @param access Droits d'accès à la page (voir le fichier AccessFilter.ts) et les features / roles / ActionPermission (backend)
 * @param title Titre de la page
 * @param devOnly Si la page est réservée à l'environnement de développement (localhost & cms-develop)
 * @param rest Autres paramètres
 */
const PrivateRoute: FC<PrivateRouteProps> = ({
  Component,
  access = [],
  title,
  devOnly = false,
  ...rest
}: PrivateRouteProps) => {
  const [isLoggedIn, setLoggedIn] = useState(authenticationService.isLoggedIn);
  useContext(NotificationContext).setTitle(title ?? '');
  const params = useParams();
  let timeout: ReturnType<typeof setTimeout>;

  // Page is OK until otherwise
  const [httpCodePage, setHttpCodePage] = useState(200);

  useEffect(() => {
    if (devOnly && !ENV_ISDEV) return;
    const validConnexion = authenticationService.checkTokenExpiration(authenticationService.currentUserValue);
    if (!isLoggedIn || !validConnexion) {
      authenticationService.loginByToken();
      //// eslint-disable-next-line react-hooks/exhaustive-deps
      timeout = setTimeout(() => authenticationService.logout(), 10000);
    } else {
      if (access.length && !AccessFilter(access)) setHttpCodePage(403);
      else setHttpCodePage(200);
    }

    authenticationService.loggedInSubject.subscribe((isLoggedIn) => {
      setLoggedIn(isLoggedIn);
    });

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [isLoggedIn, Component, access]);

  // NOTE generate logout on first login because AccessFilter test too soon the user (async login not done yet)
  // function have been moved to line 28
  // When the route change, PrivateRoute is not recreated
  // useEffect(() => {
  //   if (access.length && !AccessFilter(access)) setHttpCodePage(403);
  //   else setHttpCodePage(200);
  // }, [Component, access]);
  if (devOnly && !ENV_ISDEV) return <ErrorPage errorCode={418} />;
  if (!isLoggedIn) return <WaitingConnectionScreen />;
  if (httpCodePage >= 200 && httpCodePage < 300) return <Component {...params} setHttpCodePage={setHttpCodePage} />;
  return <ErrorPage errorCode={httpCodePage} />;
  // return (
  //   <Route
  //     {...rest}
  //     Component={(routeProps) =>
  //       isLoggedIn ? (
  //         httpCodePage >= 200 && httpCodePage < 300 ? (
  //           <Component {...routeProps} params={params} setHttpCodePage={setHttpCodePage} />
  //         ) : (
  //           <ErrorPage errorCode={httpCodePage} />
  //         )
  //       ) : (
  //         <WaitingConnectionScreen />
  //       )
  //     }
  //   />
  // );
};

interface OpenRouteProps extends PathRouteProps {
  // Si la route est exacte
  exact?: boolean;
  // Chemin de la route
  path?: string;
  // Composant à afficher
  Component: any;
  // Titre de la page
  title?: string;
}

/**
 * Composant permettant de gérer les routes ouvertes (sans accès) de l'application
 * @param exact Si la route est exacte
 * @param path Chemin de la route
 * @param Component Composant à afficher
 * @param title Titre de la page
 */
export const OpenRoute: FC<OpenRouteProps> = (props) => {
  useContext(NotificationContext).setTitle(props.title ?? '');
  const Component = props.Component;
  return <Component />;
};

export default PrivateRoute;
