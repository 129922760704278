import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import APIRoute from '../../constant/API.constant';
import AccessFilter from '../../helper/AccessFilter';
import { AddNavigationButton, ListNavigationButton, ViewNavigationButton } from '../../component/shared/Buttons';
import { SfFormation, SfFormationEvent, SfHabilitation, SfHabilitationType } from '../../interface/SfType';
import { Buttons, InputUI, UI } from '../../component/shared';
import CRUD from '../../service/CRUD.service';
import { IdLabel, LabelCalc } from '../../interface/CommonType';
import NotificationService from '../../service/NotificationService';
import notificationService from '../../service/NotificationService';
import Utils, { tryParseDate } from '../../helper/Utils';
import {
  CaptionProps,
  ContextOptions,
  getSchedulerFilterValues,
  Scheduler,
  SchedulerFilter,
} from '../../component/scheduler/Scheduler';
import { useNavigate, useParams } from 'react-router-dom';
import { DialogUI } from '../../component/shared/DialogUI';
import './rh.scss';
import { schedulerFormatter } from '../../component/scheduler/schedulerFormatter';
import { Popper } from '../../component/scheduler/Poppers';
import { BASE_URL } from '../../constant/API_URL';
import { GlobalContext } from '../../context/Global.context';
import { CmsColumnDef, CmsFrontendTable } from '../../component/table/CmsTable';
import CmsTableFilter from '../../component/table/helper/CmsTableFilter';
import CmsTableCell from '../../component/table/helper/CmsTableCell';
import CmsTableFilterCalculator from '../../component/table/helper/CmsTableFilterCalculator';
import { ButtonHolder, CmsButton, CmsPaper } from '../../component/shared/Ui';
import CmsIcon from '../../component/shared/CmsIcon';
import ROLE from '../../constant/role.constant';
import { useLocationToGetParams } from '../../component/shared/UseHook';

//#region HabilitationListPage

export const habilitationConf: CmsColumnDef<SfHabilitation>[] = [
  { header: 'n° Sage', id: 'sageNumber', hide: 'hiddenByDefault' },
  {
    header: 'Type',
    id: 'type',
    accessorFn: (x) => x.type?.label,
    Filter: CmsTableFilter.Search,
    filterOptions: { multiple: true },
  },
  { header: 'Utilisateur', id: 'user', Filter: CmsTableFilter.Select, filterOptions: { multiple: true } },
  { header: 'Service', id: 'service', Filter: CmsTableFilter.Select, filterOptions: { multiple: true } },
  { header: 'Commentaire', id: 'comment', Filter: CmsTableFilter.Text },
  {
    header: 'Statut habilitation',
    id: 'statusLabel',
    Filter: CmsTableFilter.Select,
    filterOptions: { multiple: true },
    cell: (info: any) => <StatusDisplay statusLabel={info.getValue()} isFormation={false} />,
  },
  {
    header: 'Date limite',
    id: 'deadline',
    Filter: CmsTableFilter.Date,
    cell: CmsTableCell.DateWithLimit,
    cmsSortingFn: CmsTableFilterCalculator.pureSort.date,
  },
  { header: 'Date de planification formation', id: 'futureFormationEndDate', Filter: CmsTableFilter.Date },
  {
    header: 'Date prévisionnelle formation',
    id: 'estimatedFormationDate',
    Filter: CmsTableFilter.Date,
    cell: CmsTableCell.DateWithLimit,
  },
  { header: 'Date de dernière mise à jour', id: 'lastFormationUpdate', Filter: CmsTableFilter.Date },
  { header: 'Entité juridique', id: 'legalEntityName', hide: true, Filter: CmsTableFilter.Select },
  { header: 'Agence', id: 'agencyLabel', hide: 'hiddenByDefault', Filter: CmsTableFilter.Select },
  { header: 'À renouveler', id: 'renewal', size: 50, defaultFilterValue: true, Filter: CmsTableFilter.Bool },
  {
    header: 'Utilisateur actif',
    id: 'activeUser',
    Filter: CmsTableFilter.Bool,
    hide: 'hiddenByDefault',
    defaultFilterValue: true,
  },
  {
    header: 'Statut de péremption',
    id: 'deadLineStatus',
    Filter: CmsTableFilter.Select,
    filterOptions: {
      optionList: [
        { id: 0, label: 'Sans date de péremption' },
        { id: 1, label: 'Dans moins de 90 jours' },
        { id: 2, label: 'Dans moins de 30 jours' },
        { id: 3, label: 'Dans moins de 15 jours' },
        { id: 4, label: 'Périmé' },
      ],
      multiple: true,
    },
    hide: true,
  },
];

const HabilitationIndicatorList: Array<LabelCalc> = [
  {
    label: 'Périmées',
    revertColor: true,
    calc: (rows: Array<any>) => {
      const expired = rows.filter((x: any) => {
        if (!x.original.activeUser || !x.original.renewal) return false;
        return !!x.original.deadline && (Utils.Date.tryParseDate(x.original.deadline)?.getTime() ?? 0) < Date.now();
      }).length;
      return { count: expired, ratio: expired / (rows.length === 0 ? 1 : rows.length) };
    },
  },
  {
    label: 'Périmées dans moins de 3 mois sans formation planifiée',
    revertColor: true,
    calc: (rows: Array<any>) => {
      const inThreeMonth = Utils.Date.addMonth(new Date(), 3);
      const now = new Date();
      const expired = rows.filter((x: any) => {
        if (!x.original.activeUser || !x.original.renewal || !x.original.deadline) return false;
        const futur = x.original.futureFormationEndDate;
        if (!!futur && new Date(futur).getTime() !== 0) return false;
        const deadLine = Utils.Date.tryParseDate(x.original.deadline);
        return !!deadLine && deadLine > now && deadLine < inThreeMonth;
      }).length;
      return { count: expired, ratio: expired / (rows.length === 0 ? 1 : rows.length) };
    },
  },
  {
    label: 'Formation planifiée',
    calc: (rows: Array<any>) => {
      const plannified = rows.filter((x: any) => {
        if (!x.original.activeUser || !x.original.renewal) return false;
        const futur = x.original.futureFormationEndDate;
        return !!futur && new Date(futur).getTime() !== 0;
      }).length;
      return { count: plannified, ratio: plannified / (rows.length === 0 ? 1 : rows.length) };
    },
  },
];

/**
 * Page de liste des habilitations
 */
export const SfHabilitationList: FC = () => {
  const actions = AccessFilter([ROLE.ADMIN_STAFF_TRAINING_CREATE])
    ? [<AddNavigationButton title="Ajouter une habilitation" to="/castres/staff/habilitation/create" />]
    : [];
  return (
    <CmsFrontendTable
      title="Liste des habilitations"
      actions={[<CmsIcon href="/help/Habilitations/" icon="help" tooltip="Aide" target="_blank" />, ...actions]}
      columns={habilitationConf}
      indicatorList={HabilitationIndicatorList}
      navigateTo={(id: number) => `/castres/staff/habilitation/${id}/edit`}
      route={APIRoute.SfHabilitation}
      initialSorting={{ id: 'deadline', desc: false }}
      downloadable={AccessFilter([ROLE.ADMIN_STAFF_TRAINING_EXPORT]) && 'csv&excel'}
      setFiltersInUrl
      showFooter
    />
  );
};

//#endregion

//#region FormationListPage

export const formationConf: CmsColumnDef<SfFormation>[] = [
  { header: 'n° Sage', id: 'sageNumber', hide: 'hiddenByDefault' },
  { header: 'Utilisateur', id: 'user', Filter: CmsTableFilter.Select, filterOptions: { multiple: true } },
  { header: 'Service', id: 'service', Filter: CmsTableFilter.Select, filterOptions: { multiple: true } },
  { header: "Type d'habilitation", id: 'habilitationType', Filter: CmsTableFilter.Search },
  { header: 'Début de validité', id: 'validityStart', Filter: CmsTableFilter.Date },
  { header: 'Fin de validité', id: 'validityEnd', cell: CmsTableCell.DateWithLimit, Filter: CmsTableFilter.Date },
  {
    header: 'Statut formation',
    id: 'statusLabel',
    filterOptions: { multiple: true },
    Filter: CmsTableFilter.Select,
    cell: (info: any) => <StatusDisplay statusLabel={info.getValue()} isFormation={true} />,
  },
  {
    header: 'Statut habilitation',
    id: 'habilitationStatus',
    filterOptions: { multiple: true },
    Filter: CmsTableFilter.Select,
    cell: (info: any) => <StatusDisplay statusLabel={info.getValue()} isFormation={false} />,
  },
  { header: 'Date prévisionnelle', id: 'estimatedDate', Filter: CmsTableFilter.Date, cell: CmsTableCell.DateWithLimit },
  { header: 'Commentaire', id: 'comment', cell: CmsTableCell.Ellipse, Filter: CmsTableFilter.Text },
  {
    header: 'Date limite prévisionnelle :',
    id: 'deadLineStatus',
    Filter: CmsTableFilter.Select,
    filterOptions: {
      multiple: true,
      optionList: [
        { id: 0, label: 'Sans date de péremption' },
        { id: 1, label: 'Dans moins de 90 jours' },
        { id: 2, label: 'Dans moins de 30 jours' },
        { id: 3, label: 'Dans moins de 15 jours' },
        { id: 4, label: 'Périmé' },
      ],
    },
    hide: true,
  },
  {
    header: 'Status de fin de validité :',
    id: 'validityEndStatus',
    Filter: CmsTableFilter.Select,
    filterOptions: {
      multiple: true,
      optionList: [
        { id: 0, label: 'Sans date de péremption' },
        { id: 1, label: 'Dans moins de 90 jours' },
        { id: 2, label: 'Dans moins de 30 jours' },
        { id: 3, label: 'Dans moins de 15 jours' },
        { id: 4, label: 'Périmé' },
      ],
    },
    hide: true,
  },
  { header: 'Entité juridique', id: 'legalEntityName', hide: true, Filter: CmsTableFilter.Select },
  { header: 'Agence', id: 'agencyLabel', hide: true, Filter: CmsTableFilter.Select, filterOptions: { multiple: true } },
  { header: 'À renouveler', id: 'habilitationRenewable', Filter: CmsTableFilter.Bool },
  { header: 'Utilisateur actif', id: 'activeUser', Filter: CmsTableFilter.Bool, hide: 'hiddenByDefault' },
];

/**
 * Page de liste des formations
 */

export const SfFormationListPage: FC = () => {
  const navigate = useNavigate();
  const handleRowClick = (cell: any) => {
    const { id, habilitationId } = cell.row.original;
    navigate(`/castres/staff/habilitation/${habilitationId}/edit?formation=${id}`);
  };

  return (
    <CmsFrontendTable
      route={APIRoute.SfFormation}
      actions={[<CmsIcon href="/help/Habilitations/" icon="help" tooltip="Aide" target="_blank" />]}
      columns={formationConf}
      title="Liste des formations"
      onCellClick={handleRowClick}
      initialSorting={{ id: 'deadline', desc: false }}
      downloadable={AccessFilter([ROLE.ADMIN_STAFF_TRAINING_EXPORT]) && 'csv&excel'}
      setFiltersInUrl
    />
  );
};

//#endregion

//#region Habilitation Context

// Context des habilitations
interface HabilitationContextProps {
  // L'habilitation en cours
  habilitation?: SfHabilitation;
  // Mets à jour l'habilitation en cours
  setHabilitation: any;
  // Mets à jour l'id de la page
  setPageId: any;
  // L'id de l'utilisateur en cours
  userId?: number;
  // Id du type d'habilitation en cours
  typeId?: number;
  // Mets à jour l'id de l'utilisateur en cours
  setUserId: any;
  // Mets à jour l'id du type d'habilitation en cours
  setTypeId: any;
  // Indique si on est en train de charger les données
  loading: boolean;
  // Fonction de recherche d'habilitation
  findHabilitation: (userId?: number, typeId?: number) => any;
  // La formation en cours
  currentFormation?: SfFormation;
  // Mets à jour la formation en cours
  setCurrentFormation: any;
  // Fonction de création d'habilitation
  createHabilitation: any;
  // Fonction de création de formation
  createFormation: any;
  // Fonction de rafraichissement des données
  refresh: any;
  // État du rafrachissement automatique des données du planning
  refreshSchedulerState: number;
  // Fonction de rafraichissement automatique des données du planning
  refreshScheduler: any;
}

/**
 *  Contexte des habilitations
 */
const HabilitationContext = React.createContext<HabilitationContextProps>({
  habilitation: undefined,
  setHabilitation: (x: SfHabilitation) => {},
  setPageId: null,
  setUserId: null,
  setTypeId: null,
  loading: false,
  findHabilitation: (userId?: number, typeId?: number) => {},
  setCurrentFormation: null,
  createHabilitation: null,
  createFormation: null,
  refresh: null,
  refreshSchedulerState: 0,
  refreshScheduler: null,
});

/**
 * Provider du context des habilitations
 * @param props Props contenant les enfants à afficher
 */
const HabilitationProvider = (props: { children: React.ReactNode }) => {
  const [habilitation, setHabilitation] = useState<SfHabilitation>();
  const [currentFormation, setCurrentFormation] = useState<SfFormation>();
  const [userId, setUserId] = useState<number>();
  const [typeId, setTypeId] = useState<number>();
  const [refreshSchedulerState, setRefreshSchedulerState] = useState(1);
  const [id, setId] = useState<number>();
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    const { idUser, idType }: any = params;
    if (!idUser || !idType) return;
    setUserId(+idUser);
    setTypeId(+idType);
  }, [params]);

  const findHabilitation = (user?: number, type?: number) => {
    if (userId !== user) setUserId(user);
    if (typeId !== type) setTypeId(type);
    if (!user || !type) {
      setHabilitation(undefined);
      return;
    }
    setLoading(true);
    CRUD.getById<SfHabilitation>(APIRoute.SfHabilitation + '/ByTypeAndUser', `${user}/${type}`)
      .then((result) => {
        navigate(`/castres/staff/habilitation/${result.id}/edit`);
        setHabilitation(result);
        if (!!result.sfFormation && result.sfFormation.length > 0) setCurrentFormation(result.sfFormation.at(-1));
      })
      .catch(() => {
        setHabilitation(undefined);
        setCurrentFormation(undefined);
        navigate(`/castres/staff/habilitation/create/${user}/${type}`);
      })
      .finally(() => setLoading(false));
  };

  const setPageId = useMemo(
    () =>
      (id: number, currentFormationId = 0) => {
        if (!id || id === 0) return;
        setId(id);
        CRUD.getById<SfHabilitation>(APIRoute.SfHabilitation, id).then((result) => {
          if (!result) return;
          const attrList = ['start', 'end', 'validityStart', 'validityEnd'];
          if (result.sfFormation) result.sfFormation = Utils.parseToDateInList(result.sfFormation, attrList);
          setHabilitation(result);
          if (!!result.sfFormation && result.sfFormation.length > 0) {
            let formation = result.sfFormation.find((x) => x.id === currentFormationId);
            if (!!currentFormationId && !formation)
              notificationService.error("L'id de formation présente dans l'url n'existe pas sur cette habilitation");
            formation ??= result.sfFormation.at(-1);
            if (formation?.sfFormationEvent)
              formation.sfFormationEvent = Utils.parseToDateInList(formation.sfFormationEvent, ['start', 'end']);
            setCurrentFormation(formation);
          } else setCurrentFormation(undefined);
          setUserId(result.userId);
          setTypeId(result.typeId);
        });
      },
    [],
  );

  const refresh = () => setPageId(id ?? 0, currentFormation?.id);

  const createHabilitation = () => {
    CRUD.post<SfHabilitation>(APIRoute.SfHabilitation, { typeId, userId }).then((result) => {
      navigate(`/castres/staff/habilitation/${result.id}/edit`);
      setHabilitation(result);
    });
  };

  const createFormation = () => {
    const payload = { habilitationId: habilitation!.id, addToExport: true };
    CRUD.post<SfFormation>(APIRoute.SfFormation, payload).then((result) => {
      if (!habilitation?.sfFormation) habilitation!.sfFormation = [];
      result.start = result.end = new Date('0001-01-01T00:00:00Z');
      habilitation!.sfFormation = [result, ...habilitation!.sfFormation];
      setHabilitation({ ...habilitation });
      setCurrentFormation(result);
    });
  };

  const handleSetCurrentFormation = (updatedFormation?: SfFormation) => {
    if (!updatedFormation || updatedFormation?.statusLabel !== currentFormation?.statusLabel)
      setPageId(habilitation?.id ?? 0, updatedFormation?.id);
    setCurrentFormation(updatedFormation);
  };

  const refreshScheduler = () => setRefreshSchedulerState(refreshSchedulerState + 1);

  return (
    <HabilitationContext.Provider
      value={{
        habilitation,
        setHabilitation,
        setPageId,
        userId,
        typeId,
        setUserId,
        setTypeId,
        loading,
        findHabilitation,
        setCurrentFormation: handleSetCurrentFormation,
        currentFormation,
        createHabilitation,
        createFormation,
        refresh,
        refreshScheduler,
        refreshSchedulerState,
      }}
    >
      {props.children}
    </HabilitationContext.Provider>
  );
};

//#endregion

/**
 * Page de création / édition d'une habilitation
 * @param id Id de l'habilitation
 */
export const SfHabilitationCreateEdit: FC = ({ id }: any) => {
  return (
    <HabilitationProvider>
      <div className="habilitation-dashboard">
        <HabilitationManager id={id} />
        <FormationManager />
        <FormationList />
        <FormationEventList />
      </div>
      <UserPlanning />
    </HabilitationProvider>
  );
};

//#region Habilitation Manager

/**
 * Gestionnaire d'habilitation, gère le type d'habilitation/l'utilisateur et la création d'une habilitation
 * si elle n'existe pas
 * @param id Id de l'habilitation (sinon c'est une création)
 */
const HabilitationManager: FC<{ id: number }> = ({ id }) => {
  const { userId, typeId, setPageId, habilitation, loading, findHabilitation, setHabilitation, createHabilitation } =
    useContext(HabilitationContext);
  const [typeList, setTypeList] = useState<SfHabilitationType[]>([]);
  const [userList, setUserList] = useState<IdLabel[]>([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const navigate = useNavigate();
  const params = useLocationToGetParams();

  useEffect(() => {
    let formationId: any = parseInt(params.formation ?? '0');
    if (Number.isNaN(formationId)) formationId = -1;
    setPageId(id, formationId);
  }, [id, setPageId, params.formation]);

  useEffect(() => {
    CRUD.getList<IdLabel>(APIRoute.UsUsers + '/Simplified?withDisabled=true').then(setUserList);
    CRUD.getList<SfHabilitationType>(APIRoute.SfHabilitationType).then(setTypeList);
  }, []);

  const switchRenewal = () => {
    setHabilitation({ ...habilitation, renewal: !habilitation?.renewal });
    setIsUpdate(true);
  };
  const setComment = (comment: string) => {
    setHabilitation({ ...habilitation, comment });
    setIsUpdate(true);
  };
  const update = () => {
    CRUD.put<SfHabilitation>(APIRoute.SfHabilitation, habilitation)
      .then(() => {
        setPageId(habilitation?.id ?? 0);
        NotificationService.success('Habilitation mit à jour');
      })
      .finally(() => setIsUpdate(false));
  };

  const isRenewable = (typeList.find((x) => x.id === typeId)?.validity ?? 0) > 0;
  const finalTypeList = !id ? typeList.filter((x: any) => !!x.active) : typeList;

  const getAction = () => {
    const actionList = [];
    if (AccessFilter([ROLE.SONATA_USER_ADMIN_USER_VIEW]) && !!userId)
      actionList.push(<ViewNavigationButton title="Voir l'utilisateur" to={`/sonata/user/user/${userId}/show`} />);
    actionList.push(<ListNavigationButton to="/castres/staff/habilitation/list" title="Retourner à la liste" />);
    return actionList;
  };

  const handleDelete = () => {
    if (!habilitation?.id) return;
    CRUD.deleteById(APIRoute.SfHabilitation, habilitation.id).then(() => {
      NotificationService.info('Habilitation supprimée');
      navigate('/castres/staff/habilitation/list');
    });
  };

  return (
    <UI.Paper
      title="Habilitation"
      isLoading={loading || userList.length === 0 || typeList.length === 0}
      actions={getAction()}
    >
      <InputUI.AutoCompletor
        label="Type d'habilitation"
        value={typeId}
        onChange={(x: number) => findHabilitation(userId, x)}
        options={finalTypeList}
      />
      <InputUI.AutoCompletor
        label="Utilisateur"
        value={userId}
        onChange={(x: number) => findHabilitation(x, typeId)}
        options={userList}
      />
      {!!habilitation && isRenewable && (
        <div className="flex-h space-between item-center">
          <InputUI.CmsSwitch inline value={!!habilitation?.renewal} label="À renouveler" onChange={switchRenewal} />
          {!!habilitation?.renewal && (
            <UI.LabelValue label="Date limite">
              {Utils.displayDate(habilitation?.deadline) ?? 'indéterminé'}
            </UI.LabelValue>
          )}
        </div>
      )}
      <UI.LabelValue label="Statut" style={{ marginBottom: '1em' }}>
        <StatusDisplay statusLabel={habilitation?.statusLabel ?? ''} isFormation={false} />
      </UI.LabelValue>

      {!!habilitation && (
        <InputUI.DebouncedInput label="Commentaire" value={habilitation?.comment ?? ''} onChange={setComment} />
      )}

      {!!habilitation && (habilitation.sfFormation?.length ?? 0) === 0 && (
        <CmsButton color="error" onClick={() => setOpenDeleteDialog(true)}>
          Supprimer
        </CmsButton>
      )}

      <UI.Dialog open={openDeleteDialog} onClose={setOpenDeleteDialog}>
        <CmsPaper title="Suppression" style={{ marginBottom: 0 }}>
          Attention, vous vous apprêtez à supprimer cette habilitation, êtes-vous sûr ?
          <UI.Divider />
          <ButtonHolder>
            <CmsButton color="inherit" onClick={() => setOpenDeleteDialog(false)}>
              Annuler
            </CmsButton>
            <Buttons.Delete onClick={handleDelete}>Supprimer</Buttons.Delete>
          </ButtonHolder>
        </CmsPaper>
      </UI.Dialog>

      <div>
        {isUpdate && !!habilitation && <Buttons.Valid onClick={update}>Mettre à jour</Buttons.Valid>}
        {!!typeId && !!userId && !habilitation && (
          <Buttons.Valid onClick={createHabilitation}>Créer l'habilitation</Buttons.Valid>
        )}
      </div>
    </UI.Paper>
  );
};

//#endregion

//#region Formation Manager

/**
 * Gestionnaire de formation, gère la création/édition d'une formation et l'ajout d'un fichier (certificat)
 */
const FormationManager: FC = () => {
  const { setPageId, habilitation, currentFormation } = useContext(HabilitationContext);
  const [formation, setFormation] = useState<SfFormation | any>(Object.assign({}, currentFormation));
  const [dialogType, setDialogType] = useState<'delete' | 'invalid' | undefined>(undefined);

  useEffect(() => {
    const events: SfFormationEvent[] = currentFormation?.sfFormationEvent ?? [];
    let lastEvent: Date = new Date(0);
    for (const event of events) if (new Date(event?.end ?? 0) > lastEvent) lastEvent = new Date(event?.end ?? 0);
    const start = parseDateOrUndefined(currentFormation?.validityStart) ?? parseDateOrUndefined(lastEvent);
    let end = parseDateOrUndefined(currentFormation?.validityEnd);
    if (!end) end = getValidityEndDate(start, currentFormation, habilitation);
    setFormation({ ...currentFormation, validityStart: start, validityEnd: end });
  }, [currentFormation, habilitation]);

  if (!habilitation || !currentFormation || !formation) return <div />;

  const handleDeleteDialog = () => {
    if ((currentFormation.sfFormationEvent?.length ?? 0) > 0)
      NotificationService.error('Des évènements sont encore rattaché à cette formation');
    else setDialogType('delete');
  };

  const downLoadCertificate = () => {
    const fileName = `Formation ${habilitation.typeLabel} ${habilitation.user} ${Utils.setToKebabDate(new Date())}.pdf`;
    CRUD.getBlob(`${APIRoute.SfFormation}/Certificate/${currentFormation.id}`).then((result) => {
      Utils.downloadFile(result, fileName);
    });
  };

  const saveModif = () => {
    const val = !formation.validityStart ? null : Utils.Date.addHours(formation.validityStart, 2).toISOString();
    const vEnd = formation.validityEnd;
    if (formation.estimatedDate) formation.estimatedDate = Utils.Date.toUtc(formation.estimatedDate);
    const valEnd = !vEnd || new Date(vEnd).getTime() === 0 ? null : Utils.Date.addHours(vEnd, 2).toISOString();
    const payload = { ...formation, validityStart: val, validityEnd: valEnd };
    CRUD.postFormData<any>(APIRoute.SfFormation, payload, true).then((x: any) => {
      if (!x) return;
      setPageId(currentFormation.habilitationId, currentFormation?.id ?? 0);
      NotificationService.success('Formation mise à jour');
    });
  };

  const handleUpdateValidityStart = (date: Date | undefined) => {
    if (!date) return setFormation({ ...formation, validityStart: undefined, validityEnd: undefined });
    let end = Utils.Date.addMonth(date, habilitation.typeValidity ?? 0);
    end = Utils.Date.addDays(end, -1); // Un jour de moins pour la gestion de Tantième
    setFormation({ ...formation, validityStart: date, validityEnd: end });
  };

  const isModif =
    !!formation.file ||
    formation.validityStart !== currentFormation.validityStart ||
    formation.comment !== currentFormation.comment ||
    formation.validityEnd !== currentFormation.validityEnd;

  const events: SfFormationEvent[] = currentFormation.sfFormationEvent ?? [];
  const isReadOnly =
    !currentFormation.validated || events.length === 0 || events.filter((x) => !x.validated).length > 0;
  return (
    <UI.Paper
      title={'Formation: ' + habilitation.type?.label}
      actions={[
        <Buttons.Valid disabled={!isModif} onClick={saveModif}>
          Sauvegarder
        </Buttons.Valid>,
        habilitation.type?.requiredCertificate ? (
          <Buttons.Valid hidden={true} disabled={!currentFormation.certificate} onClick={downLoadCertificate}>
            Télécharger le certificat
          </Buttons.Valid>
        ) : undefined,
        <Buttons.Cancel onClick={() => setDialogType('invalid')}>Invalider</Buttons.Cancel>,
        <Buttons.Delete onClick={handleDeleteDialog}>Supprimer</Buttons.Delete>,
      ]}
    >
      <div className="cms-grid fr2 h100">
        <div className="flex-v">
          <UI.LabelValue label="Date de début">{setEmptyIfNullDate(currentFormation.start)} </UI.LabelValue>
          <UI.LabelValue label="Date de fin">{setEmptyIfNullDate(currentFormation.end)} </UI.LabelValue>
          <UI.LabelValue label="Statut">
            <StatusDisplay statusLabel={currentFormation.statusLabel ?? ''} isFormation />
          </UI.LabelValue>
          {events.length === 0 && (
            <InputUI.DatePickerWithLabel
              value={parseDateOrUndefined(formation.estimatedDate)}
              onChange={(x: Date) => setFormation({ ...formation, estimatedDate: x })}
              label="Date prévisionnelle"
            />
          )}
          <InputUI.DatePickerWithLabel
            disabled={isReadOnly}
            value={formation.validityStart}
            onChange={handleUpdateValidityStart}
            label="Date de début de validité"
          />
          {(habilitation.typeValidity ?? 0) > 0 && (
            <InputUI.DatePickerWithLabel
              disabled={isReadOnly}
              value={parseDateOrUndefined(formation.validityEnd)}
              onChange={(x: Date) => setFormation({ ...formation, validityEnd: x })}
              label="Date de fin de validité"
            />
          )}
          {habilitation.type?.requiredCertificate && [
            <InputUI.InputFile
              pdfOnly
              style={{ marginBottom: '.5rem' }}
              disabled={isReadOnly}
              label="Téléverser un certificat pdf"
              onFileSelected={(x: File) => setFormation({ ...formation, file: x })}
              onClear={() => setFormation({ ...formation, file: undefined })}
            />,
            <InputUI.CmsSwitch
              value={formation.addToExport ?? false}
              label="Ajouter le certificat à l'export"
              onChange={(addToExport) => setFormation({ ...formation, addToExport })}
              inline
            />,
          ]}
          {!!habilitation.type?.isOtherFormation && (
            <InputUI.CmsSwitch
              value={formation.addToOtherFormation ?? false}
              label="Ajouter dans 'autres formations'"
              onChange={(addToOtherFormation) => setFormation({ ...formation, addToOtherFormation })}
              inline
            />
          )}
        </div>
        <div className="flex-v">
          <InputUI.DebouncedInput
            multiline
            valueCanUpdate
            value={formation.comment ?? ''}
            onChange={(x: string) => setFormation({ ...formation, comment: x })}
            label="Commentaire"
          />
          <InputUI.DebouncedInput
            multiline
            valueCanUpdate
            value={formation.privateComment ?? ''}
            onChange={(x: string) => setFormation({ ...formation, privateComment: x })}
            label="Commentaire Privé"
          />
          {!!habilitation.type?.vehicle && (
            <InputUI.DebouncedInput
              valueCanUpdate
              value={formation.caces ?? ''}
              onChange={(x: string) => setFormation({ ...formation, caces: x })}
              label="N° Caces"
              required
            />
          )}
          {habilitation.type?.code?.startsWith('AIPR') && (
            <InputUI.DebouncedInput
              valueCanUpdate
              value={formation.aiprNumber ?? ''}
              onChange={(x: string) => setFormation({ ...formation, aiprNumber: x })}
              label="Numéro AIPR"
              required
            />
          )}
        </div>
      </div>
      <FormationDialog type={dialogType} setOpen={setDialogType} />
    </UI.Paper>
  );
};

/**
 * Retourne la date de fin de validité d'une formation si elle n'est pas déjà définie, attention, les formations Certas
 * ont comme règle métier de systématiquement se finir sur un 31/12 d'une année impaire inférieur à 2 ans du startDate.
 * @param start Date de début de validité
 * @param formation Formation
 * @param habilitation Habilitation
 */
function getValidityEndDate(start?: Date, formation?: SfFormation, habilitation?: SfHabilitation): Date | undefined {
  if (!formation || !habilitation || !start) return undefined;
  const end = Utils.Date.addMonth(start, habilitation?.typeValidity ?? 0);
  Utils.Date.addHours(end, 2);
  if (!habilitation.type?.label?.includes('Certas')) return Utils.Date.addDays(end, -1);
  const endYear = end.getFullYear();
  return new Date(endYear % 2 === 1 ? endYear - 2 : endYear - 1, 11, 31);
}

/**
 * Renvoie la date si elle est valide, undefined sinon
 * @param validityStart Date à tester
 */
function parseDateOrUndefined(validityStart: Date | undefined) {
  if (!validityStart) return undefined;
  const dated = new Date(validityStart);
  return dated.getTime() > 0 ? dated : undefined;
}

/**
 * Renvoie la date au format dd/mm/yyyy si elle est valide, undefined sinon
 * @param dateToTest Date à tester
 */
function setEmptyIfNullDate(dateToTest: Date | undefined): string | undefined {
  const date = new Date(dateToTest ?? 0);
  return Utils.Date.isDateValid(date) ? Utils.displayDate(date) : undefined;
}

/**
 * Modal de confirmation de suppression ou d'invalidation d'une formation
 * @param type delete ou invalid
 * @param setOpen Fonction pour fermer la modal
 */
const FormationDialog: FC<{ type: 'delete' | 'invalid' | undefined; setOpen: any }> = ({ type, setOpen }) => {
  const { currentFormation, setPageId } = useContext(HabilitationContext);
  const handleClick = () => {
    if (type === 'delete') {
      CRUD.deleteById(APIRoute.SfFormation, currentFormation?.id ?? 0).then(() => {
        setPageId(currentFormation?.habilitationId, currentFormation?.id ?? 0);
        setOpen(false);
      });
    } else {
      CRUD.postFormData(APIRoute.SfFormation, { id: currentFormation?.id ?? 0, isFailed: true }, true).then(() => {
        setPageId(currentFormation?.habilitationId, currentFormation?.id ?? 0);
        setOpen(false);
      });
    }
  };
  const title = type === 'delete' ? 'Supprimer une formation' : 'Invalider une formation';
  return (
    <DialogUI open={!!type} onClose={() => setOpen(undefined)} onValidation={handleClick} title={title}>
      {(type === 'delete' && <p>Voulez vous supprimer cette formation ?</p>) || (
        <>
          <p>Êtes vous sûr de vouloir indiquer cette formation comme étant échoué ?</p>
          <p>Cette action est irréversible</p>
        </>
      )}
    </DialogUI>
  );
};

//#endregion

//#region Formation list

const formationListConfig: CmsColumnDef<SfFormation>[] = [
  {
    header: 'Date de début',
    id: 'start',
    Filter: CmsTableFilter.Date,
    cell: (x) => (!x.getValue() ? 'Indéterminé' : CmsTableCell.Date(x)),
  },
  {
    header: 'Date de fin',
    id: 'end',
    Filter: CmsTableFilter.Date,
    cell: (x) => (!x.getValue() ? 'Indéterminé' : CmsTableCell.Date(x)),
  },
  {
    header: 'Date de début de validité',
    id: 'validityStart',
    Filter: CmsTableFilter.Date,
    cell: (x) => (!x.getValue() ? 'Indéterminé' : CmsTableCell.Date(x)),
  },
  {
    header: 'Statut',
    id: 'statusLabel',
    Filter: CmsTableFilter.Select,
    cell: (x) => <StatusDisplay statusLabel={x.getValue()} isFormation={true} />,
  },
];

/**
 * Liste des formations d'une habilitation
 */
const FormationList: FC = () => {
  const { habilitation, createFormation, setCurrentFormation } = useContext(HabilitationContext);

  const handleRowClick = (id: any) => {
    console.log('id', id);
    const formation = habilitation?.sfFormation?.find((x) => x.id === id);
    setCurrentFormation(formation);
  };

  const memoisedList = useMemo(() => {
    if (!habilitation?.sfFormation) return [];
    return Utils.mapDateAttributesInArray(habilitation?.sfFormation, ['start', 'end', 'validityStart']);
  }, [habilitation]);

  if (!habilitation) return <></>;
  return (
    <CmsFrontendTable
      title="Liste des formations"
      showHeader="never"
      route="none"
      actions={[<Buttons.Valid onClick={createFormation}>Créer une formation </Buttons.Valid>]}
      initialPageSize={10}
      initialSorting={{ id: !habilitation?.typeValidity ? 'start' : 'end', desc: true }}
      columns={formationListConfig}
      onCellClick={(x) => handleRowClick(x.row.original.id)}
      controlledState={{ state: memoisedList, setState: () => {} }}
    />
  );
};

//#endregion

//#region FormationEventList

// const formationEventListConfig: CmsColumnDef<SfFormationEvent>[] = [
//   { header: 'Date de début', id: 'start', Filter: CmsTableFilter.Date },
//   { header: 'Date de fin', id: 'end', Filter: CmsTableFilter.Date },
//   { header: 'Validé', id: 'validated', Filter: CmsTableFilter.Bool },
//   {
//     header: 'Action',
//     id: 'id',
//     cell: (x) => {
//       const start = x.row?.original?.start;
//       const validated = !!x.row?.original?.validated;
//       const disabled = validated || !start || start.getTime() > Date.now();
//       return (
//         <div className="flex-h space-around event-actions">
//           <Buttons.Valid disabled={disabled} onClick={() => x.cell.cellClick(x.value, 'valid')}>
//             Valider
//           </Buttons.Valid>
//           <Buttons.Default disabled={validated} onClick={() => x.cell.cellClick(x.value, 'edit')}>
//             Éditer
//           </Buttons.Default>
//           <Buttons.Delete onClick={() => x.cell.cellClick(x.value, 'delete')}>Supprimer</Buttons.Delete>
//         </div>
//       );
//     },
//   },
// ];

/**
 * Liste des évènements d'une formation
 */
const FormationEventList: FC = () => {
  const { habilitation, currentFormation } = useContext(HabilitationContext);
  const [event, setEvent] = useState<SfFormationEvent>();
  const [openValid, setOpenValid] = useState<SfFormationEvent>();
  const [openDelete, setOpenDelete] = useState<number>();

  const memoisedList = useMemo(() => {
    if (!currentFormation?.sfFormationEvent) return [];
    return Utils.mapDateAttributesInArray(currentFormation?.sfFormationEvent, ['start', 'end']);
  }, [currentFormation]);

  if (!habilitation || !currentFormation) return <div />;
  const newEvent: SfFormationEvent = {
    start: Utils.Date.setHours(new Date(), 8),
    end: Utils.Date.setHours(new Date(), 18),
    validated: false,
  };

  const cellClick = (id: number, type: string) => {
    if (type === 'valid') setOpenValid(currentFormation?.sfFormationEvent?.find((x) => x.id === id));
    else if (type === 'delete') setOpenDelete(id);
    else if (type === 'edit') setEvent(currentFormation?.sfFormationEvent?.find((x) => x.id === id));
  };

  const formationEventListConfig: CmsColumnDef<SfFormationEvent>[] = [
    { header: 'Date de début', id: 'start', Filter: CmsTableFilter.Date },
    { header: 'Date de fin', id: 'end', Filter: CmsTableFilter.Date },
    { header: 'Validé', id: 'validated', Filter: CmsTableFilter.Bool },
    {
      header: 'Action',
      id: 'id',
      maxSize: 100,
      cell: (x) => {
        const start = x.row?.original?.start;
        const validated = !!x.row?.original?.validated;
        const disabled = validated || !start || start.getTime() > Date.now();
        return (
          <div className="event-actions">
            <Buttons.Valid disabled={disabled} onClick={() => cellClick(x.getValue(), 'valid')}>
              Valider
            </Buttons.Valid>
            <Buttons.Default disabled={validated} onClick={() => cellClick(x.getValue(), 'edit')}>
              Éditer
            </Buttons.Default>
            <Buttons.Delete onClick={() => cellClick(x.getValue(), 'delete')}>Supprimer</Buttons.Delete>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <CmsFrontendTable
        title="Liste des évènements de formations"
        route="none"
        actions={[<Buttons.Valid onClick={() => setEvent(newEvent)}>Créer un évènement</Buttons.Valid>]}
        showHeader="never"
        initialPageSize={10}
        initialSorting={{ id: 'end', desc: true }}
        columns={formationEventListConfig}
        controlledState={{ state: memoisedList, setState: () => {} }}
      />
      <CreateEditEventDialog event={event} setEvent={setEvent} userId={habilitation.userId} />
      <ValidEventDialog event={openValid} setOpen={setOpenValid} />
      <DeleteEventDialog id={openDelete} setOpen={setOpenDelete} />
    </>
  );
};

/**
 * Dialog de création / édition d'un évènement de formation
 * @param event l'évènement à éditer
 * @param setOpen fonction pour fermer le dialog
 */
const ValidEventDialog: FC<{ event?: SfFormationEvent; setOpen: any }> = ({ event, setOpen }) => {
  const { refresh } = useContext(HabilitationContext);
  const handleValidation = () => {
    CRUD.put<any>(`${APIRoute.SfFormation}/Event/Validate`, { ...event, validated: true }).then(() => {
      refresh();
      setOpen(undefined);
    });
  };

  return (
    <DialogUI
      open={!!event}
      onClose={() => setOpen(undefined)}
      onValidation={handleValidation}
      title="Validation d'un évènement de formation"
    >
      <p>Voulez vous validé cet évènement ?</p>
    </DialogUI>
  );
};

/**
 * Dialog de suppression d'un évènement de formation
 * @param id
 * @param setOpen
 * @constructor
 */
const DeleteEventDialog: FC<{ id?: number; setOpen: any }> = ({ id, setOpen }) => {
  const { refresh, refreshScheduler } = useContext(HabilitationContext);
  const handleValidation = () => {
    CRUD.deleteById(`${APIRoute.SfFormation}/Event`, id ?? 0, true).then((result: SfFormation) => {
      refresh();
      refreshScheduler();
      setOpen(false);
    });
  };

  return (
    <DialogUI
      open={!!id}
      onClose={() => setOpen(false)}
      onValidation={handleValidation}
      title="Supprimer un évènement de formation"
    >
      <p>Voulez vous supprimer cet évènement ?</p>
    </DialogUI>
  );
};

/**
 * Modale de création / édition d'un évènement de formation
 * @param event l'évènement à éditer
 * @param setEvent fonction pour mettre à jour l'évènement
 * @param userId l'id de l'utilisateur de l'habilitation
 */
const CreateEditEventDialog: FC<{ event?: SfFormationEvent; setEvent: any; userId?: number }> = ({
  event,
  setEvent,
  userId,
}) => {
  const { currentFormation, refresh, refreshScheduler } = useContext(HabilitationContext);
  const [start, setStart] = useState<Date>(event?.start ?? Utils.Date.setHours(new Date(), 8));
  const [end, setEnd] = useState<Date>(event?.end ?? Utils.Date.setHours(new Date(), 18));
  const [isLoading, setIsLoading] = useState(false);
  const [collisionTask, setCollisionTask] = useState<IdLabel[]>([]);
  useEffect(() => {
    if (!event || !event.start || !event.end) return;
    setStart(new Date(event.start.toString()));
    setEnd(new Date(event.end.toString()));
  }, [event]);

  useEffect(() => {
    const params = `?maxDate=${Utils.toGmtIsoString(end)}&minDate=${Utils.toGmtIsoString(start)}`;
    CRUD.getList<IdLabel>(APIRoute.WkTask + '/ByUser/' + userId, params).then((result) => {
      setCollisionTask(result);
    });
  }, [end, start, userId]);

  const handleValidation = () => {
    if (start > end) {
      NotificationService.error('Date de fin antérieur à la date de début');
      return;
    }
    setIsLoading(true);
    const payload: SfFormationEvent = { id: event?.id, start, end, formationId: currentFormation?.id };
    payload.validated = !event ? false : event?.validated;
    CRUD.post<any>(APIRoute.SfFormation + '/Event', payload)
      .then(() => {
        refresh();
        refreshScheduler();
        setEvent(undefined);
      })
      .finally(() => setIsLoading(false));
  };
  const handleSetStart = (date: Date | string) => {
    if (!date || date.toString() === 'Invalid Date') return;
    setStart(Utils.Date.setHours(date, start.getHours()));
    setEnd(Utils.Date.setHours(new Date(date), end.getHours()));
  };

  return (
    <DialogUI
      className="formation-event-modal"
      open={!!event}
      onClose={() => setEvent(undefined)}
      onValidation={handleValidation}
      title={`${!event?.id ? 'Créer' : 'Mettre à jour'} un évènement de formation`}
      isLoading={isLoading}
    >
      <InputUI.DatePickerWithLabel
        value={start}
        onChange={handleSetStart}
        label="Date d'évènement de formation"
        notNullable
      />
      {start != null && (
        <div className="flex-h space-between align-center">
          <InputUI.CMSTimePicker label="De" value={start} onChange={setStart} clearable={false} />
          <InputUI.CMSTimePicker label="à" value={end} onChange={setEnd} clearable={false} />
        </div>
      )}
      {collisionTask.length > 0 && (
        <div>
          <UI.Divider />
          <p style={{ color: 'yellow' }}>Collision avec des interventions détecter !</p>
          <div className="flex-v">
            {collisionTask.map((intervention: any) => (
              <Buttons.Nav.View
                to={`/castres/work/intervention/${intervention.id}/show`}
                title={intervention.label}
                style={{ margin: '5px' }}
                forceOpenNewTab
              />
            ))}
          </div>
        </div>
      )}
    </DialogUI>
  );
};

//#endregion

//#region UserPlanning

const initialFilters: Array<SchedulerFilter> = [
  {
    title: 'Date de début',
    accessor: 'minDate',
    value: Utils.Date.addDays(new Date(), -10),
    isBackendFilter: true,
    isStartFilter: true,
    filter: 'date',
  },
  {
    title: 'Date de fin',
    accessor: 'maxDate',
    value: Utils.Date.addDays(new Date(), 90),
    isBackendFilter: true,
    isEndFilter: true,
    filter: 'date',
  },
];

/**
 * Planning des formations / absences de l'utilisateur
 */
const UserPlanning: FC = () => {
  const { habilitation, userId, refreshSchedulerState } = useContext(HabilitationContext);
  const navigate = useNavigate();

  if (!habilitation || !userId) return <></>;

  const handleFiltering = (filterList: SchedulerFilter[]): boolean => {
    const { minDate, maxDate } = getSchedulerFilterValues(filterList);
    const min = tryParseDate(minDate);
    const max = tryParseDate(maxDate);
    if (!min || !max || max < min) return false;
    if (Utils.Date.getTimeSpan(min, max, 'day') > 370) {
      NotificationService.error("Pas plus d'un ans");
      return false;
    }
    return true;
  };

  const contextualHabilitation: ContextOptions[] = [
    {
      label: "Voir l'habilition",
      isGroupClick: true,
      onClick: (x) => navigate(`/castres/staff/habilitation/${x.group?.habilitationId}/edit`),
    },
  ];

  const contextualTask: ContextOptions[] = [
    {
      label: "Voir l'intervention",
      if: (item) => item.type === 'task',
      onClick: (x) => {
        const url = `${BASE_URL}castres/work/intervention/${x.item?.value?.interventionId}/show`;
        if (x.isMiddleClick) Utils.openInNewTab(url);
        else window.location.href = url;
      },
    },
    {
      label: "Voir l'intervention",
      isGroupClick: true,
      if: (item) => item.type === 'task',
      onClick: (x) => {
        const url = `${BASE_URL}castres/work/intervention/${x.group?.id}/show`;
        if (x.isMiddleClick) Utils.openInNewTab(url);
        else window.location.href = url;
      },
    },
  ];

  return (
    <Scheduler
      captionList={CaptionList()}
      reloadListener={userId + '-' + refreshSchedulerState}
      onFiltersChange={handleFiltering}
      paperTitle="Planning trimestriel (Tâches/Absences/Habilitations)"
      initialFilters={initialFilters}
      reloadButton
      fetch={[
        {
          type: 'habilitation',
          url: `${APIRoute.SfHabilitation}/User/${userId}`,
          formatter: schedulerFormatter.formatHabilitation,
          contextOptionList: contextualHabilitation,
        },
        {
          url: `${APIRoute.SfAbsence}/User/${userId}`,
          formatter: schedulerFormatter.formatAbsence,
          accessFilter: ROLE.ADMIN_STAFF_ABSENCE_LIST,
        },
        {
          url: `${APIRoute.SfAssignment}/User/${userId}`,
          formatter: schedulerFormatter.formatAssignment,
          accessFilter: ROLE.ADMIN_WORK_INTERVENTION_LIST,
        },
        {
          url: `${APIRoute.WkTask}/ByUser/${userId}`,
          formatter: schedulerFormatter.formatTask,
          type: 'task',
          itemPopper: Popper.TaskPopper,
          groupPopper: Popper.TaskGroupPopper,
          contextOptionList: contextualTask,
          accessFilter: ROLE.ADMIN_WORK_TASK_LIST,
        },
      ]}
    />
  );
};

const CaptionList = (): CaptionProps[] => {
  const { theming } = useContext(GlobalContext);
  return [
    { type: 'absence', label: 'Absence', color: theming.get().cms.scheduler.caption.absence, enabled: true },
    {
      type: 'habilitation',
      label: 'Habilitation',
      color: theming.get().cms.scheduler.caption.habilitation,
      enabled: true,
    },
    { type: 'assignment', label: 'Assignation', color: theming.get().cms.scheduler.caption.assignment, enabled: true },
    { type: 'task', label: 'Tâche', color: theming.get().cms.scheduler.caption.task, enabled: true },
  ];
};

//#endregion

//#region StatusDisplay

const statusConf = [
  { label: 'Planifié', backgroundColor: '#9811d7', color: '#ededed', isFormation: true },
  { label: 'En cours', backgroundColor: '#03ba36', color: '#d6d6d6', isFormation: true },
  { label: 'En attente de certification', backgroundColor: '#930057', color: '#d5d5d5', isFormation: true },
  { label: 'En attente de date de début de validité', backgroundColor: '#d5390d', color: '#dfdfdf', isFormation: true },
  {
    label: 'En attente de validation de tous les évènements de formation',
    backgroundColor: '#d5390d',
    color: '#dfdfdf',
    isFormation: true,
  },
  { label: 'En attente de début de validité', backgroundColor: '#dda70f', color: '#373737', isFormation: true },
  { label: 'Validé', backgroundColor: '#3077e0', color: '#d4d3d3', isFormation: true },
  { label: 'Échoué', backgroundColor: '#c20b0b', color: '#d4d3d3', isFormation: true },
  { label: 'Prévisionnelle', backgroundColor: '#d90382', color: '#d4d3d3', isFormation: true },
  { label: 'Aucune formation validée', backgroundColor: '#c2240b', color: '#d6d6d6' },
  { label: 'Périmé sans renouvellement', backgroundColor: '#8c00a5', color: '#ffffff' },
  { label: 'Périmé', backgroundColor: '#c2240b', color: '#d4d3d3' },
  { label: 'Valide', backgroundColor: '#0b5cdb', color: '#d4d3d3' },
  { label: 'Validé mais périmé', backgroundColor: '#802c00', color: '#d4d3d3', isFormation: true },
];

// const StatusConf = (): any[] => {
//   const { theming } = useContext(GlobalContext);
//   return [
//     {
//       label: 'Planifié',
//       backgroundColor: theming.get().cms.habilitation.planned,
//       color: theming.get().cms.habilitation.text,
//       isFormation: true,
//     },
//     {
//       label: 'En cours',
//       backgroundColor: theming.get().cms.habilitation.inProgress,
//       color: theming.get().cms.habilitation.text,
//       isFormation: true,
//     },
//     {
//       label: 'En attente de certification',
//       backgroundColor: theming.get().cms.habilitation.waitingCertificat,
//       color: theming.get().cms.habilitation.text,
//       isFormation: true,
//     },
//     {
//       label: 'En attente de date de début de validité',
//       backgroundColor: theming.get().cms.habilitation.pendingStartDate,
//       color: theming.get().cms.habilitation.text,
//       isFormation: true,
//     },
//     {
//       label: 'En attente de validation de tous les évènements de formation',
//       backgroundColor: theming.get().cms.habilitation.waitingValidationEvents,
//       color: theming.get().cms.habilitation.text,
//       isFormation: true,
//     },
//     {
//       label: 'En attente de début de validité',
//       backgroundColor: theming.get().cms.habilitation.pendingStartValidity,
//       color: theming.get().cms.habilitation.textDark,
//       isFormation: true,
//     },
//     {
//       label: 'Validé',
//       backgroundColor: theming.get().cms.habilitation.validity,
//       color: theming.get().cms.habilitation.text,
//       isFormation: true,
//     },
//     {
//       label: 'Échoué',
//       backgroundColor: theming.get().cms.habilitation.failed,
//       color: theming.get().cms.habilitation.text,
//       isFormation: true,
//     },
//     {
//       label: 'Prévisionnelle',
//       backgroundColor: theming.get().cms.habilitation.forecast,
//       color: theming.get().cms.habilitation.text,
//       isFormation: true,
//     },
//     {
//       label: 'Aucune formation validée',
//       backgroundColor: theming.get().cms.habilitation.noValidatedFormation,
//       color: theming.get().cms.habilitation.text,
//     },
//     {
//       label: 'Périmé sans renouvellement',
//       backgroundColor: theming.get().cms.habilitation.expiredWithoutRenewal,
//       color: theming.get().cms.habilitation.text,
//     },
//     {
//       label: 'Périmé',
//       backgroundColor: theming.get().cms.habilitation.expired,
//       color: theming.get().cms.habilitation.text,
//     },
//     {
//       label: 'Valide',
//       backgroundColor: theming.get().cms.habilitation.valid,
//       color: theming.get().cms.habilitation.text,
//     },
//   ];
// };

/**
 * Affiche le status d'une habilitation avec la bonne couleur
 * @param statusLabel le label du status
 * @param isFormation si c'est une formation ou une habilitation
 */
const StatusDisplay: FC<{ statusLabel: string; isFormation: boolean }> = ({ statusLabel, isFormation }) => {
  const conf = statusConf.find((x) => x.label === statusLabel && isFormation === !!x.isFormation);
  if (!conf) return <>Inexistant</>;
  const style = { backgroundColor: conf.backgroundColor, color: conf.color };
  return (
    <span className="habilitation-status" style={style}>
      {statusLabel}
    </span>
  );
};

//#endregion
