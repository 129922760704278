import React, { FC, useEffect, useState } from 'react';
import { CmsColumnDef, CmsFrontendTable } from '../../../component/table/CmsTable';
import CmsTableFilter from '../../../component/table/helper/CmsTableFilter';
import APIRoute from '../../../constant/API.constant';
import { AddNavigationButton } from '../../../component/shared/Buttons';
import ROLE from '../../../constant/role.constant';
import { CmsForm } from '../../../component/form/CmsForm';
import { CmsFormInput } from 'component/form/CmsFormInput';
import { BlReferenceStudyLine } from '../../../interface/BlType';
import CmsTableCell from '../../../component/table/helper/CmsTableCell';
import CmsIcon from '../../../component/shared/CmsIcon';
import { BlReferenceStudyGroupListConfigList } from './BlReferenceStudyGroup.pack';
import { TabStyleDataView } from '../../../component/shared/TabStyleDataView';
import { LabelValue } from '../../../interface/CommonType';
import CRUD from '../../../service/CRUD.service';
import { UseFormReturn } from 'react-hook-form';
import { WarningBubble } from '../../../component/shared/Ui';
import { BlReferenceStudyTypeOptions } from './BlStudyCategory.pack';

const BlReferenceStudyLineListConfigList: CmsColumnDef<BlReferenceStudyLine>[] = [
  { header: 'Libellé', id: 'label', Filter: CmsTableFilter.Text },
  {
    header: 'Type',
    id: 'type',
    Filter: CmsTableFilter.Select,
    filterOptions: { optionList: BlReferenceStudyTypeOptions },
    cell: (info) => BlReferenceStudyTypeOptions[info.getValue()].label,
  },
  { header: 'Unité', id: 'unitLabel', Filter: CmsTableFilter.Select },
  {
    header: "Catégorie d'étude",
    noClick: true,
    id: 'categoryLabel',
    Filter: CmsTableFilter.Select,
    cell: (x) =>
      CmsTableCell.Link({
        label: x.getValue(),
        url: `/castres/billing/studycategory/${x.row.original.categoryId}/edit`,
      }),
  },
  {
    header: 'Article',
    noClick: true,
    id: 'articleLabel',
    Filter: CmsTableFilter.Select,
    cell: (x) =>
      CmsTableCell.Link({ label: x.getValue(), url: `/castres/stock/article/${x.row.original.articleId}/edit` }),
  },
  {
    header: "Corps d'état de la référence d'étude",
    id: 'jobLabel',
    noClick: true,
    Filter: CmsTableFilter.Text,
    cell: (x) =>
      CmsTableCell.Link({
        label: x.getValue(),
        url: `/castres/work/job/${x.row.original.jobId}/edit`,
      }),
  },
  { header: 'Prix de revient', id: 'costPrice', Filter: CmsTableFilter.Number, cell: CmsTableCell.Price },
  { header: 'Prix vente', id: 'markupPrice', Filter: CmsTableFilter.Number, cell: CmsTableCell.Price },
  { header: 'Commentaire', id: 'comment', Filter: CmsTableFilter.Text, hide: 'hiddenByDefault' },
  { header: 'Taux de perte (%)', id: 'lossFactor', Filter: CmsTableFilter.Number, hide: 'hiddenByDefault' },
  { header: 'Coût unitaire', id: 'unitPrice', Filter: CmsTableFilter.Number, hide: 'hiddenByDefault' },
];

export const BlReferenceStudyLineList: FC = () => {
  return (
    <CmsFrontendTable
      route={APIRoute.BlReferenceStudyLine}
      columns={BlReferenceStudyLineListConfigList}
      title="Liste des lignes de références d'études"
      navigateTo={(id: number) => `/castres/billing/referencestudyline/${id}/edit`}
      actions={[
        <AddNavigationButton
          title="Ajouter une ligne de référence d'étude"
          to="/castres/billing/referencestudyline/create"
          access={[ROLE.ADMIN_STUDY_REFERENCE_EDIT]}
        />,
      ]}
      setFiltersInUrl
    />
  );
};

export const BlReferenceStudyLineCreateEdit: FC = ({ id, onSubmit }: any) => {
  const [state, setState] = useState<any>();
  return [
    <BlReferenceStudyLineForm id={id} onSubmit={onSubmit} state={state} setState={setState} />,
    !!id && (
      <CmsFrontendTable
        columns={BlReferenceStudyGroupListConfigList}
        title="Groupes de référence d'études contenant cette ligne"
        route={APIRoute.BlReferenceStudyGroup + '/ContainsLine/' + id}
        navigateTo={(id: number) => `/castres/billing/referencestudygroup/${id}/edit`}
      />
    ),
  ];
};

export const BlReferenceStudyLineForm: FC<any> = ({ id, onSubmit, state, setState, noReturn, onCancel, style }) => {
  const [unitList, setUnitList] = useState<any[]>([]);
  const [blRefStudyCategory, setBlRefStudyCategory] = useState<any[]>([]);
  const hourUnit = unitList.find((x) => x.code === 'HEURE');
  useEffect(() => {
    CRUD.getList(APIRoute.WkUnit).then(setUnitList);
    CRUD.getList(APIRoute.BlStudyCategory).then((list) => {
      setBlRefStudyCategory(list.map((x: any) => ({ ...x, label: `${x.label} - ${x.markupFactor}%` })));
    });
  }, []);

  return (
    <CmsForm
      id={id}
      currentUrl="/castres/billing/referencestudyline/"
      route={APIRoute.BlReferenceStudyLine}
      title="ligne de référence d'étude"
      noReturn={noReturn}
      onCancel={onCancel}
      onSubmit={onSubmit}
      onGetEditData={(data) => {
        data.unitPrice = data.calculatedUnitPrice;
        return data;
      }}
      defaultValues={{ articleQuantity: 1 }}
      paperStyle={{ minWidth: '54vw', ...style }}
      onChange={(x) => (!state && !!x.blReferenceStudyLineCostHistory && setState ? setState(x) : undefined)}
      renderForm={(form) => {
        const { unitPrice, quantity, lossFactor, markupFactor, type } = form.watch();
        const costPrice = ((unitPrice ?? 0) + (unitPrice ?? 0) * ((lossFactor ?? 0) / 100)) * (quantity ?? 0);
        const markupPrice = costPrice * (1 + (markupFactor ?? 0) / 100);
        const filteredCategory = blRefStudyCategory.filter((x) => x.type === type);
        return [
          <DeprecatedPriceHandler form={form} />,
          <div className="cms-grid fr2">
            <CmsFormInput.Select
              id="type"
              label="Type (à choisir avant de remplir les autres champs)"
              required
              options={BlReferenceStudyTypeOptions}
              readOnly={!!id || (type !== undefined && type !== null)}
              onChange={(value) => {
                if (value === 2) {
                  form.setValue('manpowerId', null);
                  form.setValue('articleId', null);
                } else if (value === 1) {
                  form.setValue('unitId', hourUnit?.id);
                  if (!form.watch().articleId) form.setValue('articleId', null);
                } else if (value === 0) form.setValue('manpowerId', null);
              }}
            />
            {type !== undefined && (
              <>
                <CmsFormInput.Select
                  id="jobId"
                  label="Corps d'état de la référence d'étude"
                  options={APIRoute.WkJob}
                  optionLabel="name"
                  required
                />
                <CmsFormInput.Text id="label" label="Libellé" required min={2} max={255} />
                <CmsFormInput.Select
                  id="categoryId"
                  label="Catégorie d'étude"
                  returnObject
                  onChange={(x) => form.setValue('markupFactor', x.markupFactor)}
                  options={filteredCategory}
                />
                {type > 1 && <div />}
                <CmsFormInput.Select
                  hideIf={(x) => x.type !== 1}
                  required={type === 1}
                  id="manpowerId"
                  label="Coût main d'œuvre"
                  options={APIRoute.BlManPower}
                  onChange={(x) => form.setValue('unitPrice', x.hourlyRate)}
                  returnObject
                />
                <CmsFormInput.Suggest
                  id="articleId"
                  optionValue="id"
                  required={type === 0}
                  hideIf={(x) => x.type !== 0}
                  label={[
                    <span>Article fournisseur</span>,
                    <CmsIcon
                      style={{ margin: '-0.15rem 0' }}
                      typeColor="primary"
                      href="/castres/stock/article/list?t-active=true"
                      target="_blank"
                      icon="visible"
                      tooltip="voir tout les articles"
                    />,
                  ]}
                  route={APIRoute.SkArticle + '/WithData'}
                  onValueSelected={(x) => {
                    form.setValue('unitPrice', x?.price);
                    form.setValue('label', x?.label);
                  }}
                  defaultValueHolder={form.watch().article?.refWithLabel}
                />
                <CmsFormInput.Number
                  disableIf={(x) => x.type < 2}
                  id="unitPrice"
                  label="Prix unitaire (€)"
                  required={type > 1}
                />
                <CmsFormInput.Select
                  disableIf={(x) => x.type === 1}
                  id="unitId"
                  label="Unité"
                  options={unitList}
                  required
                />
                <CmsFormInput.Number id="quantity" label="Quantité" required />
                <CmsFormInput.Number id="lossFactor" label="Taux de perte (%)" />
                <CmsFormInput.Switch id="active" label="Actif" />
                <CmsFormInput.Text id="comment" label="Commentaire" multiline />
                <div style={{ gridArea: '6/2/8/2' }}>
                  <TabStyleDataView
                    conf={tabStyleDataViewConf}
                    mapFrom={{ costPrice, markupPrice, quantity, lossFactor, markupFactor }}
                  />
                </div>
              </>
            )}
          </div>,
        ];
      }}
    />
  );
};

const DeprecatedPriceHandler: FC<{ form: UseFormReturn }> = ({ form }) => {
  const { warningList } = form.watch();
  if (!warningList || warningList.length === 0) return [];
  return <WarningBubble style={{ marginBottom: '1rem' }}>{warningList.map((x: string) => x)}</WarningBubble>;
};

export function getDeprecatedMessage(isManpower: boolean, unitPrice: number, deprecatedPrice: number): string {
  const title = isManpower ? "Le taux horaire de main d'œuvre a " : "Le prix de l'article a ";
  let message = title + (unitPrice < deprecatedPrice ? 'diminué' : 'augmenté') + ' de ';
  message += Math.abs(unitPrice - deprecatedPrice).toFixed(2) + ' € ';
  message += '(ancien prix: ' + deprecatedPrice.toFixed(2) + ' €)';
  return message + " depuis la dernière mise à jour de cette ligne de référence d'étude";
}

const tabStyleDataViewConf: LabelValue[] = [
  { label: 'Quantité avec perte', value: (x: any) => ((x.quantity ?? 0) * (1 + (x.lossFactor ?? 0) / 100)).toFixed(2) },
  { label: 'Cout de revient', value: (x: any) => priceShow(x.costPrice) },
  { label: 'Coeff. vente', value: (x: any) => (x.markupFactor ?? 0) + ' %' },
  { label: 'Prix vente', value: (x: any) => priceShow(x.markupPrice) },
];

const priceShow = (price: number) => <>{price.toFixed(2)} €</>;
