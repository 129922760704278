import React, { FC, useState, useEffect } from 'react';
import { MapContainer, Marker, Popup, TileLayer, useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import ReactDOMServer from 'react-dom/server';
import { CmsForm } from '../form/CmsForm';
import CmsIcon from 'component/shared/CmsIcon';
import APIRoute from '../../constant/API.constant';
import { CmsPaper } from '../shared/Ui';
import { DatePickerVanilla } from '../shared/InputUi';
import Grid from '@mui/material/Grid';
import { AutoCompletor } from '../shared/AutoCompletorWithTable';
import { UI } from '../shared';
import CRUD from '../../service/CRUD.service';
import { BASE_URL } from 'constant/API_URL';
import { authenticationService } from '../../service/Authentication.service';
import NotificationService from '../../service/NotificationService';
import Utils from '../../helper/Utils';

//Icône personnalisée des markers pour la carte
const MarkerIcon = ({ color = 'blue', number = 1 }) => {
  const textColor = color.toLowerCase() === 'fafafa' ? '#000000' : '#FFFFFF'; //Si tâche superposée, texte noir sinon blanc
  const strokeColor = color.toLowerCase() === 'fafafa' ? '#000000' : '#' + color; //Si tâche superposée, bordure noire sinon couleur de la tâche

  return (
    <svg width="20" height="31" viewBox="0 0 25 41" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.5 0C6.149 0 0 6.307 0 14.089c0 10.502 12.5 26.911 12.5 26.911s12.5-16.409 12.5-26.911C25 6.307 18.851 0 12.5 0z"
        fill={'#' + color}
        stroke={strokeColor}
      />
      <text x="12.5" y="20" textAnchor="middle" fontSize="14px" fontWeight="bold" fill={textColor}>
        {number}
      </text>
    </svg>
  );
};

//création du marker customisé
const createCustomMarkerIcon = (color: any, number: any) => {
  return new L.DivIcon({
    html: ReactDOMServer.renderToString(<MarkerIcon color={color} number={number} />),
    className: 'custom-marker',
    iconSize: [20, 31],
    iconAnchor: [12, 31],
  });
};

//Popup de description des tâches
const TaskPopup: FC<any> = ({ task }) => {
  const { interventionId, teamName, station, intervention, startDate, endDate, description } = task;

  return (
    <div>
      <p>
        {teamName}
        <br />
        Site : {station}
        <br />
        Intervention :{' '}
        <a
          href={`${BASE_URL}castres/work/intervention/${interventionId}/show`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {intervention}
        </a>
        <br />
        du{' '}
        {startDate
          ? new Date(startDate).toLocaleString('fr-FR', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
            })
          : 'inconnue'}
        {' au '}
        {endDate
          ? new Date(endDate).toLocaleString('fr-FR', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
            })
          : 'inconnue'}
        <br />
        {description && description.split('\n')}
      </p>
    </div>
  );
};

//Composant utilisé pour zoom automatique sur l'ensemble des markers
const MapUpdater: FC<any> = ({ selectedTasks }: any) => {
  const map = useMap();
  useEffect(() => {
    if (selectedTasks.length > 0) {
      const bounds = L.latLngBounds(selectedTasks.map((task: any) => [task.latitude, task.longitude]));
      map.fitBounds(bounds);
    }
  }, [map, selectedTasks]);
  return null;
};

//Composant utilisé pour afficher les markers sur la carte i.e les différentes tasks
export const OsmMap: FC = () => {
  const maxMarkers = 1000; // nombre maximal de markers à afficher sur la carte et fournit par la requête du backend
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const user = authenticationService.getCurrentUser();
  const [services, setServices] = useState<any[]>([]);
  const [selectedService, setSelectedService] = useState<string | null>(user?.serviceId);
  const [regions, setRegions] = useState<any[]>([]);
  const [selectedRegion, setSelectedRegion] = useState<string | null>(null);
  const [tasks, setTasks] = useState<any[]>([]);
  const [filteredTasks, setFilteredTasks] = useState<any[]>([]); //tasks filtrées suivant la région et/ou les équipes
  const [selectedTasks, setSelectedTasks] = useState<any[]>([]);
  const [teams, setTeams] = useState<any[]>([]);
  const [selectedTeams, setSelectedTeams] = useState<string[]>([]);
  const [filteredTeams, setFilteredTeams] = useState<string[]>([]);
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(tomorrow);
  const [isCustomisedQuery, setIsCustomisedQuery] = useState<boolean>(false);

  //Récupération des données d'initialisation des filtres depuis le backend
  useEffect(() => {
    CRUD.getList<any>(APIRoute.SfService + '/Simplified').then(setServices);
    CRUD.getList<any>(APIRoute.LoRegion).then(setRegions);
    CRUD.getList<any>(APIRoute.SfTeam).then(setTeams);
  }, []);

  //Récupération des données des markers depuis le backend
  //si un service et une plage de dates sont sélectionnés
  useEffect(() => {
    setSelectedTeams([]); //On vide le filtre des teams afin d'éviter des erreurs sur la liste des selected tasks
    setSelectedRegion(null);
    setTasks([]);
    if (!selectedService || !startDate || !endDate) {
      setSelectedTasks([]);
      setFilteredTasks([]);
      setFilteredTeams(teams);
      setIsCustomisedQuery(false);
    } else {
      const url = `${APIRoute.WkTask}/Markers?serviceId=${selectedService}&startDate=${Utils.Date.dateOnlyFormat(startDate)}&endDate=${Utils.Date.dateOnlyFormat(endDate)}`;
      CRUD.getList<any>(url).then(setTasks);
    }
    //Si un service est sélectionné, on filtre la liste des équipes pour ne conserver que les équipes de ce service
    !!selectedService && setFilteredTeams(teams.filter((team: any) => team.serviceId === selectedService));
    //// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedService, startDate, endDate]);

  //Filtrage des markers suivant la région et/ou les équipes
  //si un service et une plage de dates sont sélectionnés et
  //si la liste des markers est inférieure au nombre maximal de markers
  //les filtres sont appliqués sur la liste des markers sans refaire un appel au backend
  //sinon la liste des markers est supérieure à maxMarkers on inclut la région et/ou les équipes dans la requête
  useEffect(() => {
    if (!selectedRegion && selectedTeams.length === 0) {
      setSelectedTasks(tasks);
      setIsCustomisedQuery(false);
      if (tasks.length >= maxMarkers && !isCustomisedQuery) {
        NotificationService.info(
          `Nombre maximal de marqueurs atteint! Veuillez appliquer plus de filtres ou réduire la plage de dates!`,
        );
      }
    } else if (!!startDate && !!endDate && !!selectedService) {
      let url = `${APIRoute.WkTask}/Markers?serviceId=${selectedService}&startDate=${Utils.Date.dateOnlyFormat(startDate)}&endDate=${Utils.Date.dateOnlyFormat(endDate)}`;
      !!selectedRegion && (url += `&regionId=${selectedRegion}`);
      selectedTeams.length > 0 && (url += selectedTeams.map((teamId) => `&teamIdList=${teamId}`).join(''));
      if (tasks.length < maxMarkers && tasks.length > 0 && !isCustomisedQuery) {
        const selectedTasksList = tasks
          .filter((task: any) => !selectedRegion || task.regionId === selectedRegion)
          .filter((task: any) => selectedTeams.length === 0 || selectedTeams.includes(task.teamId));
        setSelectedTasks(selectedTasksList);
      } else if (tasks.length < maxMarkers && tasks.length > 0 && isCustomisedQuery) {
        CRUD.getList<any>(url).then(setFilteredTasks);
        setIsCustomisedQuery(true);
      } else if (tasks.length >= maxMarkers && (!!selectedRegion || selectedTeams.length > 0)) {
        CRUD.getList<any>(url).then(setFilteredTasks);
        setIsCustomisedQuery(true);
      }
    }
    //// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRegion, selectedTeams, tasks]);

  //Si requête customisée, on modifie la liste des selectedTasks
  //Si requête customisée, on affiche une notif si la liste des filteredTasks est supérieure au nombre maximal de markers
  useEffect(() => {
    let message: string = ``;
    if (filteredTasks.length >= maxMarkers && !!selectedRegion && selectedTeams.length === 0)
      message = `Nombre maximal de marqueurs atteint! Veuillez sélectionner des équipes ou réduire la plage de dates!`;
    else if (filteredTasks.length >= maxMarkers && selectedTeams.length >= 1 && !!selectedRegion)
      message = `Nombre maximal de marqueurs atteint! Veuillez sélectionner moins d'équipes ou réduire la plage de dates!`;
    else if (filteredTasks.length >= maxMarkers && !selectedRegion)
      message = `Nombre maximal de marqueurs atteint! Veuillez sélectionner une région ou réduire la plage de dates!`;

    message.length > 0 && NotificationService.info(message);
    setSelectedTasks(filteredTasks);
    //// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredTasks]);

  //Une fois les tasks sélectionées, on filtre la liste des équipes
  //pour ne pas afficher les équipes qui ne sont pas incluses dans la liste des selectedTasks
  //mais si une ou plusieurs équipes sont sélectionnées, on affiche toutes les équipes du service dans le filtre
  useEffect(() => {
    const tasksList: any = isCustomisedQuery ? filteredTasks : tasks;
    const tasksTeamMap = selectedRegion ? tasksList.filter((task: any) => task.regionId === selectedRegion) : tasksList;
    tasksList.length > 0 &&
      tasksList.length < maxMarkers &&
      selectedTeams.length === 0 &&
      setFilteredTeams(teams.filter((team: any) => tasksTeamMap.some((task: any) => task.teamId === team.id)));
    //// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTasks]);

  return (
    <CmsForm
      {...{ currentUrl: '/schedule/map', route: APIRoute.ClAgency }}
      renderForm={(form: any) => {
        return (
          <div style={{ height: '100%', width: '100%', zIndex: 40 }}>
            <CmsPaper>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={3.5}>
                  <AutoCompletor
                    optionValue="id"
                    label="Service"
                    options={services}
                    onChange={setSelectedService}
                    value={selectedService}
                    required
                  />
                </Grid>
                <Grid item xs={2}>
                  <AutoCompletor
                    label="Région"
                    options={regions}
                    onChange={setSelectedRegion}
                    value={selectedRegion}
                    optionValue="id"
                  />
                </Grid>
                <Grid item xs={1.5}>
                  <DatePickerVanilla label="Début *" customLabel={true} value={startDate} onChange={setStartDate} />
                </Grid>
                <Grid item xs={1.5}>
                  <DatePickerVanilla label="Fin *" customLabel={true} value={endDate} onChange={setEndDate} />
                </Grid>
                <Grid item xs={3}>
                  <AutoCompletor
                    optionValue="id"
                    label="Equipe"
                    multiple
                    limitTags={1}
                    options={filteredTeams}
                    onChange={setSelectedTeams}
                    value={selectedTeams}
                  />
                </Grid>
                <Grid item xs={0.5}>
                  <CmsIcon
                    icon="info"
                    tooltip="Les marqueurs blancs correspondent aux tâches non-clôtureés et superposées d'équipes différentes."
                  />
                </Grid>
              </Grid>
            </CmsPaper>
            <UI.Paper>
              <MapContainer
                center={[47.0243, 2.351]} //centre val de loire
                zoom={6.5}
                minZoom={3}
                maxBounds={[
                  [-90, -180],
                  [90, 180],
                ]}
                maxBoundsViscosity={1.0}
                scrollWheelZoom={true}
                style={{ height: '50rem', width: '100%', zIndex: 40 }}
              >
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {selectedTasks
                  .filter(
                    (task) => (!!task.latitude || task.latitude === 0) && (!!task.longitude || task.longitude === 0),
                  )
                  .map((task) => (
                    <Marker
                      key={task.Id}
                      position={[task.latitude, task.longitude]}
                      icon={createCustomMarkerIcon(task.teamColor, task.order)}
                    >
                      <Popup offset={L.point(0, -21)}>
                        <TaskPopup task={task} />
                      </Popup>
                    </Marker>
                  ))}
                <MapUpdater selectedTasks={selectedTasks} />
              </MapContainer>
            </UI.Paper>
          </div>
        );
      }}
    />
  );
};

export default OsmMap;
