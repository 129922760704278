import { RTColumn } from '../interface/RTColumn';
import { SchedulerFilter } from '../component/scheduler/Scheduler';
import { IsFilterArrayType } from '../component/table/helper/customFilters';
import CmsTableFilter, { CmsTableFilterType } from '../component/table/helper/CmsTableFilter';

/**
 * Méthode permettant de récuperer les filtres générer par la méthode setFiltersInURL dans l'url.
 * Cette méthode est utilisé pour récuperer les filtres dans l'url et les appliquer dans le tableau ou le planning.
 * Il est possible d'avoir plusieurs tableau dans la même url si un type (identifiant) leur est donné.
 * @param TableColumnConfig La configuration des colonnes du tableau (RTColumn)
 * @param globalHistory L'objet globalHistory du GlobalContext
 * @param navigate L'objet navigate de react-router
 * @param location L'objet location de react-router
 * @param type L'identifiant du tableau / planning / etc... il permet de différencier les filtres d'un tableau d'un autre
 * @param overLoadUrl Permet de surcharger l'url par défaut, si non renseigné, l'url est récupéré dans l'objet history
 */
export function GetFilterValueFromURL(
  TableColumnConfig: Array<RTColumn>,
  globalHistory: any,
  navigate: any,
  location: any,
  type = 't-',
  overLoadUrl?: string,
): Array<any> {
  let search = overLoadUrl ?? decodeURI(location?.search ?? '');
  const fromGlobalHistory = overLoadUrl ?? globalHistory.get(location?.pathname);
  if (search === '' && fromGlobalHistory !== '') navigate(location.pathname + fromGlobalHistory);
  if (search === '') search = decodeURI(globalHistory.get(location?.pathname));
  if (!search || search === '' || !TableColumnConfig) return [];
  const searchList = search.slice(1, search.length).split('&');
  const filterList = [];
  for (const item of searchList) {
    if (!item.startsWith(type)) continue;
    const itemSplited = item.split('=');
    const id = itemSplited[0].slice(type?.length ?? 0, itemSplited[0].length);
    if (!itemSplited || itemSplited?.length < 1) return [];
    if (id === 'globalFilter') {
      filterList.push({ id, value: itemSplited[1], filterType: itemSplited[2] });
      continue;
    }
    if (id === 'sortBy') {
      filterList.push({ id: itemSplited[1], value: itemSplited[2] === 'true', isSort: true });
      continue;
    }
    if (id === 'cmscol') {
      filterList.push({ id, value: itemSplited[1].split(','), isCol: true });
      continue;
    }
    if (id === 'pageIndex') {
      filterList.push({ id, value: +itemSplited[1], isPage: true });
      continue;
    }
    if (id === 'pageSize') {
      filterList.push({ id, value: +itemSplited[1], isPage: true });
      continue;
    }
    for (const column of TableColumnConfig) {
      if (column.accessor !== id) continue;
      if (column.filter === 'boolean') {
        filterList.push({ id, value: itemSplited[1] === 'true' });
      } else if (IsFilterArrayType(column.filter)) {
        const list: Array<any> = itemSplited[1].split(',');
        let result: any = [];
        if (list.length === 1 && list[0] === '') result = [];
        else if (isNaN(list[0])) result = list;
        else list.map((x: number) => result.push(+x));
        filterList.push({ id, value: result, filterType: itemSplited[2] });
      } else {
        let val: any = itemSplited[1];
        if (val && val.startsWith('time')) {
          const dateSplitted = val.split('time')[1].split(';');
          if (dateSplitted.length === 2) val = { begin: datify(dateSplitted[0]), end: datify(dateSplitted[1]) };
          else val = datify(dateSplitted[0]);
          filterList.push({ id, value: val, filterType: itemSplited[2] });
        } else {
          const parsedVal = column.Filter === CmsTableFilter.Text || isNaN(val) ? val : +val;
          filterList.push({ id, value: parsedVal, filterType: itemSplited[2] });
        }
      }
    }
  }
  return filterList;
}

// Transforme en date si c'est un nombre
const datify = (date: any) => (!!date && date !== 'NaN' ? new Date(date * 1000) : null);

/**
 * TODO: ajouter l'attribut type dans la configuration du reactTable pour différencier les filtres d'un tableau d'un autre
 * Cette méthode permet de valoriser en temps réel les filtres de ReactTable dans l'url.
 * @param reactTable L'objet reactTable
 * @param navigate L'objet navigate de react-router
 * @param location L'objet location de react-router
 * @param globalHistory L'objet globalHistory du GlobalContext
 */
export const setReactTableFiltersInURL = (reactTable: any, navigate: any, location: any, globalHistory: any): void => {
  const reactTableColumns: Array<any> = reactTable.columns;
  const globalFilter = reactTable?.state?.globalFilter;
  if (!reactTableColumns && !globalFilter) return;
  let queryString = !globalFilter ? '' : `t-globalFilter=${globalFilter}&`;
  for (const col of reactTableColumns) {
    if (!col.filterValue && col.filterValue !== false) continue;
    queryString += OldValueToString(col.id, col.filterValue, col.filter, 't-');
  }
  const sortBy = reactTable?.state?.sortBy[0];
  if (sortBy) queryString += `t-sortBy=${sortBy.id}=${sortBy.desc}&`;
  urlReconciliator(navigate, location, queryString, globalHistory, 't-');
};

export const SetCmsTableFiltersInURL = (
  filterList: any[],
  globalHistory: any,
  navigate: any,
  location: any,
  pagination: { pageIndex: number; pageSize: number; initialPageSize: number },
  globalFilter?: any,
  sorting?: any,
  colShowEdited?: string[],
): void => {
  let queryString = handleGlobalFilter(globalFilter);
  for (const filter of filterList?.filter((x) => x.value !== undefined && x.value !== null) ?? [])
    queryString += valueToString(filter, 't-');
  if (!!sorting && sorting[0]) queryString += `t-sortBy=${sorting[0].id}=${sorting[0].desc}&`;
  if ((colShowEdited?.length ?? 0) > 0) queryString += 't-cmscol=' + colShowEdited?.map((x) => x).join(',') + '&';
  if (pagination.pageIndex !== 0) queryString += `t-pageIndex=${pagination.pageIndex}&`;
  if (pagination.pageSize !== pagination.initialPageSize) queryString += `t-pageSize=${pagination.pageSize}&`;

  // Récupération des favoris de l'historique
  const favoritesFromHistory = globalHistory.getFavorite(location.pathname);
  const favorites = favoritesFromHistory.split('=')[1];
  // Conserver les favoris dans le queryString
  if (favorites) queryString += `t-favorites=${favorites}&`;

  //Récupération de l'état des menus de l'historique
  const menuStateFromHistory = globalHistory.getDropDownMenuState(location.pathname);
  const menuState = menuStateFromHistory.split('=')[1];
  // Conserver l'état des menus dans le queryString
  if (menuState) queryString += `t-dropdown=${menuState}&`;

  urlReconciliator(navigate, location, queryString, globalHistory, 't-');
};

function handleGlobalFilter(globalFilter: any): string {
  if (!globalFilter) return '';
  if (typeof globalFilter === 'string') return `t-globalFilter=${globalFilter}&`;
  return !globalFilter.value ? '' : `t-globalFilter=${globalFilter.value}=${globalFilter.type ?? 2}&`;
}

/**
 * Méthode permettant de découper l'url pour la recontruire avec les filtres du tableau.
 * la découpe et recolle doit être procédurale pour éviter les doublons qui vont créer une boucle infini de mis
 * à jour de l'url et donc de la page.
 * @param navigate L'objet navigate de react-router
 * @param location L'objet location de react-router
 * @param queryString La chaine de caractère contenant les filtres
 * @param globalHistory L'objet globalHistory du GlobalContext
 * @param type L'identifiant du tableau / planning / etc... il permet de différencier les filtres d'un tableau d'un autre
 */
function urlReconciliator(navigate: any, location: any, queryString: string, globalHistory: any, type: string) {
  const search = decodeURI(location?.search);
  const searchList = search.slice(1, search.length).split('&');
  // on en profite pour supprimer les anciens filtres V1 qui seraient encore dans l'URL
  const V1FilteredSearch = searchList.filter((x: string) => !/^filter\[.*]/.test(x));
  const otherSearch = V1FilteredSearch.filter((x: string) => !x.startsWith(type));
  let allSearch = [...otherSearch, ...queryString.split('&')];
  if (type === 't-') allSearch = [...queryString.split('&'), ...otherSearch];
  let result = '?' + encodeURI(allSearch.filter((x: string) => x !== '').join('&'));
  result = result.replace(/'/g, '%27'); // encodeUri ne prend pas en compte les apostrophes
  if (result === location?.search || result === '?' + location?.search) return;
  navigate(location.pathname + result, { replace: true });
  globalHistory.set(location.pathname, result);
}

/**
 * Cette méthode permet de valoriser en temps réel les filtres du scheduler dans l'url pour les plannings.
 * @param filterList La liste des filtres
 * @param navigate L'objet navigate de react-router
 * @param location L'objet location de react-router
 * @param globalHistory L'objet globalHistory du GlobalContext
 */
export const setSchedulerFiltersInURL = (
  filterList: SchedulerFilter[],
  navigate: any,
  location: any,
  globalHistory: any,
): void => {
  if (!filterList || !navigate || !location) return;
  let queryString = '';
  for (const filter of filterList) {
    if (!filter?.value) continue;
    let value = filter.value;
    if (filter.filter === 'date') value = new Date(value);
    queryString += OldValueToString(filter.accessor, value, filter.filter, 'p-');
  }
  urlReconciliator(navigate, location, queryString, globalHistory, 'p-');
};

/**
 * Transforme la valeur d'un filtre en chaine de caractère pour l'ajouter à l'url.
 * Pour l'instant le seul type transformé sont les dates.
 * @param id L'identifiant du filtre
 * @param val La valeur du filtre
 * @param filter Le type de filtre
 * @param type L'identifiant du tableau / planning / etc... il permet de différencier les filtres d'un tableau d'un autre
 */
function OldValueToString(id: string, val: any, filter: any, type: string) {
  if (typeof val?.getMonth === 'function') val = 'time' + Date.parse(val) / 1000;
  if (typeof val?.begin?.getMonth === 'function' || typeof val?.end?.getMonth === 'function')
    val = 'time' + Date.parse(val?.begin) / 1000 + ';' + Date.parse(val?.end) / 1000;
  return `${type}${id}=${val}${filter ? '=' + filter : ''}&`;
}

function valueToString(filter: any, type: string) {
  const { id, value } = filter;
  let realVal = value?.value;
  if (realVal && value?.type !== undefined) {
    const dateFilter = [CmsTableFilterType.DateEqual, CmsTableFilterType.DateGreater, CmsTableFilterType.DateLess];
    if (dateFilter.includes(value.type)) {
      realVal = 'time' + Date.parse(realVal) / 1000;
    } else if (value.type === CmsTableFilterType.DateBetween) {
      realVal = 'time' + Date.parse(realVal?.start) / 1000 + ';' + Date.parse(realVal?.end) / 1000;
    } else if (value.type === CmsTableFilterType.NumberBetween) realVal = realVal?.min + ';' + realVal?.max;
    return `${type}${id}=${realVal}=${value.type}&`;
  } else if (realVal === undefined || realVal === null) return `${type}${id}=${value}&`;
  else return `${type}${id}=${realVal}&`;
}

/**
 * Créer tous les filtres par défaut pour le reactTable, tous ceux fournis par le prefilter surchargent les valeurs
 * par défaut.
 * @param preFilter Les filtres de l'url
 * @param TableColumnConfig La configuration des colonnes du tableau (RTColumn)
 * @param isFirstRender Si c'est le premier rendu du tableau, si c'est le cas, on renvoie toujours preFilter
 * pour éviter une mise à jour infinie d'un état de ReactTable
 */
export function setDefaultValues(
  preFilter: Array<any> | undefined,
  TableColumnConfig: Array<RTColumn>,
  isFirstRender = true,
): Array<any> {
  if (!preFilter) return [];
  if (preFilter?.length > 0 || !isFirstRender) return preFilter;
  const defaultValues: any[] = [];
  for (const { defaultValue, filter, accessor } of TableColumnConfig) {
    if (defaultValue === null || defaultValue === undefined) continue;
    defaultValues.push({ id: accessor, value: defaultValue, filterType: filter });
  }
  return defaultValues;
}
