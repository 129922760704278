import React, { FC } from 'react';
import { CmsColumnDef, CmsBackendTable } from '../../../component/table/CmsTable';
import { Link } from '@mui/material';
import APIRoute from '../../../constant/API.constant';
import { BlDelivery } from '../../../interface/BlType';
import CmsTableFilter from '../../../component/table/helper/CmsTableFilter';
import CmsTableCell from '../../../component/table/helper/CmsTableCell';
import { BASE_URL } from 'constant/API_URL';
import { AddNavigationButton } from 'component/shared/Buttons';
import CmsIcon from '../../../component/shared/CmsIcon';
import AccessFilter from '../../../helper/AccessFilter';
import ROLE from '../../../constant/role.constant';
import Utils from 'helper/Utils';

const columns: CmsColumnDef<BlDelivery>[] = [
  {
    header: 'Reçu le / par',
    id: 'receivedDateBy',
    accessorFn: (row) => row?.receivedDate,
    cell: (info) => (
      <>
        <CmsTableCell.Date {...info} />
        <div style={{ fontSize: '.9em' }}>{info.row.original?.receivedByName}</div>
      </>
    ),
  },
  {
    header: 'Reçu le',
    id: 'receivedDate',
    Filter: CmsTableFilter.Date,
    hide: true,
    defaultFilterValue: { value: Utils.Date.addDays(new Date(), -366), type: 9 },
  },
  {
    header: 'Reçu par',
    id: 'receivedBy',
    Filter: CmsTableFilter.Select,
    filterOptions: {
      optionList: `${APIRoute.BlDelivery}/DeliveryUsers`,
      optionLabel: 'nameReversed',
      rawEndpoint: true,
    },
    hide: true,
  },
  {
    header: 'Référence',
    id: 'ref',
    Filter: CmsTableFilter.Text,
    cell: (info) => <CmsTableCell.Ref cell={info} />,
    defaultFilter: true,
  },
  {
    header: 'Numéro de commande',
    id: 'orderRef',
    Filter: CmsTableFilter.Text,
    hide: true,
  },
  {
    header: 'Bon de livraison',
    id: 'supplierRef',
    Filter: CmsTableFilter.Text,
  },
  {
    header: 'Commande',
    id: 'orders',
    noClick: true,
    cell(info) {
      return (
        <div>
          {info.row.original?.orders?.map((order, index) => (
            <React.Fragment key={order.id}>
              <CmsTableCell.Ref cell={info} url={`/castres/billing/order/${order.id}/show`} value={order.ref} />
              {index !== info.row.original?.orders.length - 1 && ', '}
            </React.Fragment>
          ))}
        </div>
      );
    },
  },
  {
    header: 'Fournisseur / Magasin',
    id: 'supplier',
    noClick: true,
    cell: (info) => (
      <>
        <Link
          key={info.row.original?.companyId}
          style={{ color: 'inherit' }}
          href={`${BASE_URL}castres/stock/company/${info.row.original?.companyId}/show`}
          target="_blank"
        >
          <div>{info.row.original?.companyName}</div>
        </Link>
        <Link
          key={info.row.original?.supplierId}
          style={{ color: 'inherit' }}
          href={`${BASE_URL}castres/stock/supplier/${info.row.original?.supplierId}/show`}
          target="_blank"
        >
          <div>{info.row.original?.supplierName}</div>
        </Link>
      </>
    ),
  },
  { header: 'Fournisseur', id: 'companyName', Filter: CmsTableFilter.Text, hide: true },
  { header: 'Magasin', id: 'supplierName', Filter: CmsTableFilter.Text, hide: true },
  {
    header: 'SIREN',
    id: 'supplierSiret',
    Filter: CmsTableFilter.Text,
    hide: 'hiddenByDefault',
    noClick: true,
    cell: (info) => (
      <CmsTableCell.Ref cell={info} url={`https://annuaire-entreprises.data.gouv.fr/entreprise/${info.getValue()}`} />
    ),
  },
  { header: 'Nb Lignes', id: 'lineCount', size: 10 },
  { header: 'Quantité totale', id: 'totalQuantity', size: 10 },
  { header: 'Montant', id: 'price', cell: CmsTableCell.Price, size: 10 },
  {
    header: 'Référence intervention',
    id: 'interventionRef',
    Filter: CmsTableFilter.Text,
    hide: true,
  },
  {
    header: 'Intervention',
    id: 'interventions',
    noClick: true,
    cell(info) {
      return (
        <div>
          {info.row.original?.interventions?.map((intervention, index) => (
            <React.Fragment key={intervention.id}>
              <CmsTableCell.Ref
                cell={info}
                url={`/castres/work/intervention/${intervention.id}/show`}
                value={intervention.ref}
              >
                <div> {intervention.name}</div>
              </CmsTableCell.Ref>
              {index !== info.row.original?.interventions.length - 1 && ', '}
            </React.Fragment>
          ))}
        </div>
      );
    },
    hide: 'hiddenByDefault',
  },
  {
    header: 'Station',
    id: 'interventionsStation',
    noClick: true,
    cell(info) {
      return (
        <div>
          {info.row.original?.interventions?.map((intervention, index) => (
            <React.Fragment key={intervention.station.id}>
              <CmsTableCell.Ref
                cell={info}
                url={`/castres/client/station/${intervention.station.id}/show`}
                value={intervention.station.ref}
              >
                <div> {intervention.station.name}</div>
              </CmsTableCell.Ref>
              {index !== info.row.original?.interventions.length - 1 && ', '}
            </React.Fragment>
          ))}
        </div>
      );
    },
    hide: 'hiddenByDefault',
  },
  {
    header: 'Service / Stock',
    id: 'serviceStock',
    cell: (info) => (
      <>
        <div>
          {info.row.original?.agency} - {info.row.original?.legalEntityCode}
        </div>
        <div>
          {info.row.original?.service}
          {info.row.original?.stock ? ` Stock ${info.row.original?.stock}` : ''}
        </div>
      </>
    ),
  },
  {
    header: 'Service',
    id: 'service',
    Filter: CmsTableFilter.Select,
    filterOptions: { optionList: APIRoute.SfService },
    hide: true,
  },
  {
    header: 'Stock',
    id: 'stock',
    Filter: CmsTableFilter.Select,
    filterOptions: { optionList: APIRoute.SkStock },
    hide: true,
  },
  {
    header: 'Agence',
    id: 'agency',
    Filter: CmsTableFilter.Select,
    filterOptions: { optionList: APIRoute.ClAgency },
    hide: true,
  },
  {
    header: 'Nature',
    id: 'nature',
    Filter: CmsTableFilter.Select,
    filterOptions: { optionList: APIRoute.BlNature },
    hide: 'hiddenByDefault',
  },
];

export const DeliveryList: FC = () => {
  return (
    <CmsBackendTable
      title="Liste des livraisons"
      navigateTo={(id: string | number) => `${BASE_URL}castres/billing/delivery/${id}/show`}
      actions={[
        <CmsIcon href="/help/Facturation/livraisons" icon="help" tooltip="Aide" target="_blank" />,
        <AddNavigationButton
          key="nav"
          title="Créer une livraison"
          href={`${BASE_URL}castres/billing/delivery/create`}
          access={[ROLE.ADMIN_ORDER_DELIVERY_CREATE]}
        />,
      ]}
      columns={columns}
      route={APIRoute.BlDelivery}
      globalFilterIcon={
        <CmsIcon
          icon="warning"
          typeColor="primary"
          tooltip="Attention, ce filtre ne s'applique que sur les champs Référence et Bon de livraison"
        />
      }
      setFiltersInUrl
      canExport={AccessFilter([ROLE.ADMIN_ORDER_DELIVERY_EXPORT])}
      exportList={[
        { title: 'Toutes Lignes CSV', type: 'csv', route: '/ExportLinesWithFilter' },
        { title: 'Toutes Lignes EXCEL', type: 'xlsx', route: '/ExportLinesWithFilter' },
      ]}
    />
  );
};
