import { BASE_URL, FRONTEND_VERSION } from '../constant/API_URL';
import { handleBlobResponse, handleCrudErrorNotif } from '../helper/handle-response';
import NotificationService from './NotificationService';
import CRUD from './CRUD.service';
import APIRoute from '../constant/API.constant';

/**
 * Vérifie la version front du serveur et compare avec la version du frontend présent dans le navigateur
 */
export function cacheService() {
  getServerVersion();
  setInterval(() => getServerVersion(), 600000);
}

/**
 * Récupère la version du serveur (simple récupération d'un fichier texte)
 */
function getServerVersion() {
  const headers = { 'Content-Type': 'application/json', Accept: 'application/octet' };
  fetch(`${BASE_URL}version.txt`, { headers: headers })
    .then(handleBlobResponse)
    .catch(handleCrudErrorNotif)
    .then((result: Blob) => {
      if (typeof result?.text === 'function') result?.text()?.then(checkVersion);
      else NotificationService.error('Impossible de récupérer la version du serveur');
    });
}

/**
 * Compare la version du serveur avec la version du frontend,
 * si différente, affiche une notification et recharge la page après 5 secondes (6 en réalité)
 * @param version la version du serveur
 */
function checkVersion(version: string) {
  if (FRONTEND_VERSION.includes(version.trim())) return;
  NotificationService.info('Nouvelle version du site détectée, rechargement dans 5 secondes');
  setTimeout(() => window.location.reload(), 6000);
}

export function sendUserPreferences() {
  const settings = localStorage.getItem('userSettings');
  const theme = localStorage.getItem('theme');
  const history = localStorage.getItem('history');
  const data = { settings, theme, history };
  CRUD.post(APIRoute.UsUsers + '/Preferences', { value: JSON.stringify(data) }).then(() => {});
}

export function setUserPreferences(user: any): any {
  let preferences = user?.preferences;
  if (!preferences) return undefined;
  try {
    preferences = JSON.parse(preferences);
    for (const key of ['settings', 'history', 'theme']) {
      if (preferences[key]) localStorage.setItem(key, preferences[key]);
      else localStorage.removeItem(key);
    }
  } catch (e) {
    console.error(e);
  }
  user.preferences = preferences;
}
