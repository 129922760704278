import React, { CSSProperties, Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from 'react';
import { CmsForm } from '../../../component/form/CmsForm';
import APIRoute from '../../../constant/API.constant';
import { CmsFormInput } from '../../../component/form/CmsFormInput';
import { CmsDialog, CmsPaper, WarningBubble } from '../../../component/shared/Ui';
import { UseFormReturn } from 'react-hook-form';
import {
  BlQuotation,
  BlQuotationAttachment,
  BlQuotationLine,
  BlQuotationStudyLine,
  BlStudyCategory,
  BlVat,
} from '../../../interface/BlType';
import { Buttons, FormUI, InputUI, UI } from '../../../component/shared';
import { CellDndContext, CmsColumnDndDef, CmsDndTable } from '../../../component/table/CmsDndTable';
import CmsIcon from '../../../component/shared/CmsIcon';
import notificationService from '../../../service/NotificationService';
import NotificationService from '../../../service/NotificationService';
import CRUD from '../../../service/CRUD.service';
import { CmsMenuButton } from '../../../component/shared/Menu';
import { DndCell, DndInput } from '../../../component/table/DnDTableCell';
import { QuotationImportPanel } from './QuotationImportPanel';
import {
  calculateQuotationOrPriceScheduleRefAndIndent,
  ChapterPicPopperIcon,
} from '../../configuration/schedule/BlPriceSchedule.all.pack';
import { SubRowChapterPic } from '../../configuration/schedule/BlPriceScheduleTools';
import { useNavigate } from 'react-router-dom';
import { useLocationToGetParams } from '../../../component/shared/UseHook';
import LoadingScreen from '../../../component/LoadingScreen';
import { IdLabel } from '../../../interface/CommonType';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { API_URL } from '../../../constant/API_URL';
import { ListStyleDataView } from '../../../component/shared/TabStyleDataView';
import Utils from '../../../helper/Utils';
import { authenticationService } from '../../../service/Authentication.service';
import { WkJob, WkUnit } from '../../../interface/WkType';
import Tooltip from '@mui/material/Tooltip';
import { BlReferenceStudyTypeOptions } from '../../configuration/schedule/BlStudyCategory.pack';

//#region QuotationPriceScheduleConfig

const designationCell: ((info: CellDndContext<any>) => any) | undefined = (x) => {
  const handleIndent = (right: boolean) => {
    const row = x.cell.stateHandler.state.find((y) => y.id === x.row.original.id);
    if (!row) return notificationService.error('Erreur Dnd Table: ligne non trouvée');
    const indent = (row?.chapterLevel ?? 0) + (right ? 1 : -1);
    row.chapterLevel = indent < 0 ? 0 : indent > 5 ? 5 : indent;
    x.cell.stateHandler.setState([...x.cell.stateHandler.state]);
  };

  const handleLabel = (label: string) => {
    const row = x.cell.stateHandler.state.find((y) => y.id === x.row.original.id);
    if (!row) return notificationService.error('Erreur Dnd Table: ligne non trouvée');
    row.label = label;
    x.cell.stateHandler.setState([...x.cell.stateHandler.state]);
  };

  if (x.row.original.chapter) {
    return (
      <div key={x.cell.key} className="cms-grid" style={{ gridTemplateColumns: 'auto 5rem' }}>
        <div style={{ marginLeft: (x.row.original.indent ?? 0) + 'em' }}>
          <InputUI.DebouncedInput variant="standard" value={x.getValue()} valueCanUpdate onChange={handleLabel} />
        </div>
        <div className="flex-h align-center">
          <CmsIcon icon="unIndent" style={{ margin: '-0.2rem 0' }} onClick={() => handleIndent(false)} />
          <CmsIcon icon="indent" style={{ margin: '-0.2rem 0' }} onClick={() => handleIndent(true)} />
          <ChapterPicPopperIcon imagePath={x.row.original.imagePath} />
        </div>
      </div>
    );
  }
  return (
    <div style={{ marginLeft: (x.row.original.indent ?? 0) + 'em' }}>
      <InputUI.DebouncedInput
        variant="standard"
        value={x.getValue()}
        valueCanUpdate
        onChange={handleLabel}
        multilineToggle
      />
    </div>
  );
};

const handleVat: ((info: CellDndContext<any>) => any) | undefined = (x) => {
  if (x.row.original.chapter) return [];

  const handleSelect = (value: any) => {
    const list = [...(x.cell.stateHandler.state as BlQuotationLine[])];
    const item = list.find((y) => y.id === x.row.original.id);
    if (!item) return notificationService.error('Erreur Dnd Table: ligne non trouvée');
    item.vatId = value;
    item.vatValue = x.cell.optionList?.find((y: BlVat) => y.id === value)?.value;
    x.cell.stateHandler.setState(list);
  };

  return (
    <InputUI.AutoCompletor
      variant="standard"
      options={x.cell.optionList ?? []}
      onChange={handleSelect}
      value={x.getValue()}
    />
  );
};

function getEndChapterIndex(table: BlQuotationLine[], startIndex: number, withChildren = false) {
  if (!withChildren) {
    const endIndex = table.slice(startIndex).findIndex((row, i) => i !== 0 && row.chapter);
    return endIndex === -1 ? table.length - startIndex : endIndex;
  }
  const currentChapterLevel = table[startIndex].chapterLevel;
  const endIndex = table
    .slice(startIndex)
    .findIndex((row, i) => i !== 0 && row.chapter && (row.chapterLevel ?? 0) <= (currentChapterLevel ?? 0));
  return endIndex === -1 ? table.length - startIndex : endIndex;
}

function getNewId(list: BlQuotationLine[]): number {
  for (let i = 0; i < 1000; i++) {
    const newId = +(-Math.random() * 100000).toFixed(0);
    if (list.find((x) => x.id === newId)) continue;
    return newId;
  }
  return -1;
}

const handleActions: ((info: CellDndContext<any>) => any) | undefined = (x) => {
  const getNewListAndStartIndex = (): [any[], number] => {
    const list = [...(x.cell.stateHandler.state as BlQuotationLine[])];
    const startIndex = list.findIndex((y) => y.id === x.row.original.id);
    if (startIndex === -1) notificationService.error('Erreur Dnd Table: ligne non trouvée');
    return [list, startIndex];
  };

  const handleDelete = () => {
    const [list, index] = getNewListAndStartIndex();
    list.splice(index, 1);
    x.cell.stateHandler.setState(list);
  };

  const handleChapterDelete = () => {
    const [list, startIndex] = getNewListAndStartIndex();
    list.splice(startIndex, getEndChapterIndex(list, startIndex));
    x.cell.stateHandler.setState(list);
  };

  const handleChapterAddEndChapter = () => {
    const [list, startIndex] = getNewListAndStartIndex();
    const endIndex = getEndChapterIndex(list, startIndex);
    list.splice(endIndex, 0, { id: getNewId(x.cell.stateHandler.state) } as any);
    x.cell.stateHandler.setState(list);
  };

  const handleAdd = (after = false) => {
    const [list, startIndex] = getNewListAndStartIndex();
    list.splice(startIndex + (after ? 1 : 0), 0, { id: getNewId(x.cell.stateHandler.state) } as any);
    x.cell.stateHandler.setState(list);
  };

  const handleReplace = (attr: string) => {
    const [list, startIndex] = getNewListAndStartIndex();
    list.splice(startIndex, 1, { id: getNewId(x.cell.stateHandler.state), [attr]: true } as any);
    x.cell.stateHandler.setState(list);
  };

  return (
    <div className="flex-center">
      <CmsMenuButton
        preventClose={false}
        title={<CmsIcon icon="moreVertical" />}
        overLoadButton={(props) => (
          <CmsIcon
            key={'action'}
            icon="moreVertical"
            getMouseEvent
            onClick={props.handleClick}
            style={{ margin: '-0.2rem' }}
          />
        )}
      >
        <span onClick={handleDelete}>Supprimer cette ligne</span>
        {(x.row.original.chapter && [
          <span onClick={() => x.cell.lineSharedFunctionList.setVatOpen(true)}>
            Appliquer une TVA à tout le chapitre
          </span>,
          <span onClick={handleChapterDelete}>Supprimer le chapitre et son contenu</span>,
          <span onClick={handleChapterAddEndChapter}>Ajouter une nouvelle ligne à la fin de ce chapitre</span>,
        ]) || [<span onClick={() => handleReplace('chapter')}>Transformer cette ligne en chapitre</span>]}
        <span onClick={() => handleAdd(false)}>Ajouter une nouvelle ligne avant</span>
        <span onClick={() => handleAdd(true)}>Ajouter une nouvelle ligne après</span>
      </CmsMenuButton>
    </div>
  );
};

const costPriceColumns = ['coef', 'tpsmo', 'mo', 'engineCost', 'coefpv', 'cr', 'pv'];
const configList: CmsColumnDndDef<any>[] = [
  {
    header: 'Ref',
    id: 'ref',
    size: 40,
    cell: (x) => (
      <InputUI.DebouncedInput
        variant="standard"
        key={x.cell.key}
        valueCanUpdate
        onChange={x.cell.setCellValue}
        value={x.getValue() ?? x.row.original.calculatedRef}
      />
    ),
  },
  { header: 'Bordereau/Désignation', id: 'label', size: 500, cell: designationCell },
  {
    header: 'Unité',
    id: 'unitId',
    size: 0,
    inputOptions: { data: APIRoute.WkUnit + '/Simplified' },
    cell: DndInput.Select,
    hideIfChapter: true,
  },
  {
    header: () => (
      <Tooltip title="Prix de vente souhaité (calculé à partir des minutes d'études rattachées à la ligne)">
        <span className="card-label">P.V.S</span>
      </Tooltip>
    ),
    id: 'calculatedPrice',
    hideIfChapter: true,
    size: 0,
    cell: (x) => {
      const studyList = x.row.original.blQuotationStudyLine;
      const calc = studyList.reduce((acc: number, y: BlQuotationStudyLine) => acc + (y.unitPrice ?? 0), 0);
      return <div style={{ textAlign: 'right' }}>{Utils.ThousandSpacing(calc, 2)} €</div>;
    },
  },
  { header: 'Montant H.T', id: 'price', size: 0, cell: DndInput.Number, hideIfChapter: true },
  { header: 'Quantité', id: 'quantity', size: 0, cell: DndInput.Number, hideIfChapter: true },
  {
    header: 'TVA',
    id: 'vatId',
    size: 0,
    cell: handleVat,
    inputOptions: { data: APIRoute.BlVat },
    hideIfChapter: true,
  },
  { header: 'Remise (%)', id: 'discount', size: 0, cell: DndInput.Number, hideIfChapter: true },
  {
    header: 'Total H.T',
    id: 'amount',
    size: 0,
    cell: (x) => {
      const val: number = x.cell.row.original.chapterAmount ?? x.cell.getValue() ?? 0;
      return <div style={{ textAlign: 'right' }}>{Utils.ThousandSpacing(val, 2)} €</div>;
    },
  },
  { id: 'coef', header: 'Coef de perte', size: 0, cell: DndInput.Number, hideIfChapter: true },
  { id: 'tpsmo', header: 'Tps unit MO', size: 0, cell: DndInput.Number, hideIfChapter: true },
  { id: 'engineCost', header: 'Coût u.Engin', size: 0, cell: DndInput.Number, hideIfChapter: true },
  {
    id: 'mo',
    header: 'Type MO',
    size: 0,
    cell: DndInput.Select,
    hideIfChapter: true,
    inputOptions: { data: APIRoute.WkJob + '/Simplified' },
  },
  { id: 'coefpv', header: 'Coef PV', size: 0, cell: DndInput.Number, hideIfChapter: true },
  { id: 'cr', header: 'Coût revient', size: 0, cell: DndCell.Price, hideIfChapter: true },
  { id: 'pv', header: 'P.V', size: 0, cell: DndCell.Price, hideIfChapter: true },
  { header: 'Action', id: 'action', size: 0, cell: handleActions },
];

export function getChapterAmount(table: BlQuotationLine[], rowId: number, attr = 'amount'): number {
  let amount = 0;
  let counting = false;
  let chapterIndent = 0;
  for (let i = 0; i < table.length; i++) {
    if (table[i].id !== rowId && !counting) continue;
    else if (table[i].id === rowId) {
      chapterIndent = table[i].chapterLevel ?? 0;
      counting = true;
    } else if (!table[i].chapter) amount += +((table[i] as any)?.[attr] ?? 0);
    if (table[i].id !== rowId && table[i].chapter && (table[i].chapterLevel ?? 0) <= chapterIndent)
      return +(amount?.toFixed(2) ?? 0);
  }
  return +(amount?.toFixed(2) ?? 0);
}

//#endregion

//#region QuotationCreateEdit

function useHandleBusinessRules(): [BlQuotationLine[], Dispatch<SetStateAction<BlQuotationLine[]>>] {
  const [state, setState] = React.useState<any[]>([]);
  const handleStateUpdate: any = (table: BlQuotationLine[]) => {
    if (!table || !table.length) return;
    for (const row of table.filter((x) => !x.chapter)) {
      row.amount = (row.price ?? 0) * (row.quantity ?? 0);
      row.amount = row.amount - (row.amount * (row.discount ?? 0)) / 100;
      row.vatAmount = !row.vatValue ? 0 : row.amount * (row.vatValue ?? 0);
    }
    setState(calculateQuotationOrPriceScheduleRefAndIndent(table, undefined, true));
  };
  return [state, handleStateUpdate];
}

const defaultApiRoutes = {
  client: APIRoute.ClClient,
  contact: APIRoute.ClContact,
  station: APIRoute.ClStation,
  intervention: APIRoute.WkIntervention,
};

export const QuotationCreateEdit: FC<any> = ({ id }) => {
  const serviceId = useMemo(() => authenticationService.getCurrentUser().serviceId, []);
  const [state, setState] = useHandleBusinessRules();
  const [defaultValues, setDefaultValues] = useState<any>();
  const [showForm, setShowForm] = useState(true); // TODO: remmetre !id quand fini de dev
  const [withCostPrice, setWithCostPrice] = useState(false);
  const [open, setOpen] = useState(false);
  const [vatOpen, setVatOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>();
  const baseUrl = '/castres/billing/quotation/';
  const [routeList, setRouteList] = useState<any>(defaultApiRoutes);
  const navigate = useNavigate();
  const { station, intervention } = useLocationToGetParams();
  const [unitList, setUnitList] = useState<WkUnit[]>();
  const [categoryList, setCategoryList] = useState<BlStudyCategory[]>();
  const [jobList, setJobList] = useState<WkJob[]>();

  useEffect(() => {
    CRUD.getList<WkUnit>(APIRoute.WkUnit).then(setUnitList);
    CRUD.getList<BlStudyCategory>(APIRoute.BlStudyCategory).then(setCategoryList);
    CRUD.getList<WkJob>(APIRoute.WkJob + '/Simplified').then(setJobList);
  }, []);

  const cols = useMemo(() => {
    return withCostPrice ? configList : configList.filter((x) => costPriceColumns.indexOf(x.id) === -1);
  }, [withCostPrice]);

  useEffect(() => {
    if (!station && !intervention) return setDefaultValues({ serviceId });
    if (station) {
      setRouteList({
        ...defaultApiRoutes,
        contact: APIRoute.ClContact + '/ByStation/' + station,
        client: APIRoute.ClClient + '/ByStation/' + station,
        intervention: APIRoute.WkIntervention + '/ByStation/' + station,
      });
      CRUD.getById(APIRoute.ClStation, station).then((station: any) => {
        setDefaultValues({ stationId: station.id, stationLabel: station.label, serviceId });
      });
    } else {
      CRUD.getById(APIRoute.WkIntervention, intervention).then((intervention: any) => {
        setDefaultValues({
          interventionId: intervention.id,
          interventionRef: intervention.ref + ' - ' + intervention.name,
          stationId: intervention.stationId,
          stationLabel: intervention.station.label,
          serviceId,
        });
        setRouteList({
          ...defaultApiRoutes,
          contact: APIRoute.ClContact + '/ByStation/' + intervention.stationId,
          client: APIRoute.ClClient + '/ByStation/' + intervention.stationId,
          intervention: APIRoute.WkIntervention + '/ByStation/' + intervention.stationId,
        });
      });
    }
  }, [station, intervention, serviceId]);

  const handleImport = (importItem: any) => {
    if (!selectedRow) return notificationService.error("Veuillez sélectionner une ligne avant d'importer");
    const list = [...state];
    const index = list.findIndex((x) => x.id === selectedRow.original.id);
    if (index === -1) return notificationService.error('Erreur Dnd Table: ligne non trouvée');
    Array.isArray(importItem) ? list.splice(index + 1, 0, ...importItem) : list.splice(index + 1, 0, importItem);
    setState(list);
  };

  const addFirstLine = () => {
    if (state.length === 0) setState([{ id: getNewId(state) } as BlQuotationLine]);
  };

  const handleSelectedRow = (row: any) => {
    if (selectedRow?.id === row.original?.id) return;
    setSelectedRow(row);
  };

  const handleSave = async (data: any) => {
    const errorList = [];
    for (const line of state.filter((x) => !x.chapter)) {
      line.rowColor = undefined;
      if (!line.label || !line.unitId || !line.price || !line.quantity || !line.vatId) {
        line.rowColor = 'rgba(255, 0, 0, 0.2)';
        errorList.push(line.position);
      }
      line.blQuotationStudyLineForm = line.blQuotationStudyLine;
    }
    if (errorList.length) {
      notificationService.error('Les lignes ' + errorList.join(', ') + ' sont incomplètes');
      return Promise.resolve({ ...data, quotationLines: state });
    }
    const attachmentIdList = data.blQuotationAttachment?.map((x: any) => x.id);
    const payload = { ...data, linesStringified: state, quotationLines: null, attachmentIdList };
    const result: BlQuotation = await CRUD.postFormData(APIRoute.BlQuotation, payload, !!id);
    notificationService.success('Devis ' + (id ? 'modifié' : 'ajouté') + ' avec succès');
    if (id) return result;
    navigate(baseUrl + (result?.id ?? 0) + '/edit');
  };

  const handleCustomReorder = (draggedRowIndex: number, targetRowIndex: number) => {
    if (!state) return;
    const data = [...state];
    const row = data[draggedRowIndex];
    if (!row.chapter) {
      data.splice(targetRowIndex, 0, data.splice(draggedRowIndex, 1)[0] as any);
      return setState(data);
    }
    const endIndex = getEndChapterIndex(data, draggedRowIndex);
    data.splice(targetRowIndex, 0, ...data.splice(draggedRowIndex, endIndex));
    return setState(data);
  };

  if (((!!station || !!intervention) && !defaultValues) || !categoryList || !unitList || !jobList)
    return <LoadingScreen />;
  return [
    <CmsForm
      id={id}
      defaultValues={defaultValues}
      currentUrl={'/castres/billing/quotation'}
      route={APIRoute.BlQuotation}
      onSubmit={handleSave}
      onGetEditData={(quotation: BlQuotation) => {
        setState(quotation?.quotationLines ?? []);
        return quotation;
      }}
      renderForm={(formState: UseFormReturn, submit: any) => {
        const { interventionRef, clientLabel, contactName, ref, clientId, stationLabel } = formState.watch();
        const title = [
          <CmsIcon icon={showForm ? 'down' : 'right'} onClick={() => setShowForm(!showForm)} />,
          id ? 'Éditer le devis ' + ref : 'Créer un devis',
        ];
        if (!showForm)
          return [
            <FormUI.HeaderFormPanel pageId={id} {...{ title, form: formState, baseUrl, onSubmit: submit }} />,
            <div style={{ display: 'none' }}>
              <CmsFormInput.Text id="title" label="Titre" required multiline />,
            </div>,
          ];
        return [
          <FormUI.HeaderFormPanel pageId={id} {...{ title, form: formState, baseUrl, onSubmit: submit }} />,
          <div className="cms-grid fr3">
            <CmsPaper title="Informations générales">
              <CmsFormInput.Text id="title" label="Titre" required multiline />
              <CmsFormInput.Select id="jobId" label="Corps d'état" options={APIRoute.WkJob + '/Simplified'} />
              <CmsFormInput.Select
                id="agreementId"
                label="Type de contrat"
                options={APIRoute.WkAgreement + '/Simplified'}
              />
              <CmsFormInput.Select id="groupId" label="Groupe" options={APIRoute.WkInterventionGroup + '/Simplified'} />
              <CmsFormInput.Suggest
                id="interventionId"
                label="Chantier – Intervention"
                defaultValueHolder={interventionRef}
                route={routeList.intervention}
              />
              <CmsFormInput.Select id="serviceId" label="Service" options={APIRoute.SfService + '/Simplified'} />
            </CmsPaper>
            <CmsPaper title="Destinataire">
              <CmsFormInput.Suggest
                id="clientId"
                label="Client"
                defaultValueHolder={clientLabel}
                route={routeList.client}
                onValueSelected={(item: any) => {
                  if (!item) return;
                  setRouteList({
                    ...routeList,
                    contact: APIRoute.ClContact + '/ByClient/' + item.id,
                    station: APIRoute.ClStation + '/ByClient/' + item.id,
                  });
                }}
                required
              />
              <CmsFormInput.Suggest
                id="contactId"
                label="Contact"
                defaultValueHolder={contactName}
                route={routeList.contact}
                onValueSelected={(item: any) => {
                  if (!item) return;
                  let payload: any = { ...formState.watch(), contactId: item.id };
                  if (!!item.value?.id && clientId !== item.value?.id)
                    payload = { ...payload, clientId: item.value.id, clientLabel: item.value.label };
                  formState.reset(payload);
                }}
                required
              />
              <CmsFormInput.Suggest
                id="stationId"
                label="Site"
                defaultValueHolder={stationLabel}
                route={routeList.station}
                onValueSelected={(item: any) => {
                  if (!item) return;
                  setRouteList({
                    ...routeList,
                    contact: APIRoute.ClContact + '/ByStation/' + item.id,
                    client: APIRoute.ClClient + '/ByStation/' + item.id,
                  });
                }}
                required
              />
            </CmsPaper>
            <QuotationPicAndFiles formState={formState} />
          </div>,
          <CmsPaper>
            <CmsFormInput.Text id="comment" label="Commentaire" multiline />
          </CmsPaper>,
        ];
      }}
    />,
    !!id && (
      <CmsDndTable
        title="Lignes du devis"
        actions={[
          state.length === 0 && (
            <Buttons.Valid onClick={addFirstLine}>Ajouter la première ligne du devis</Buttons.Valid>
          ),
          <AddLineButtons state={state} setState={setState} selectedRow={selectedRow} />,
          <Buttons.Valid onClick={() => setWithCostPrice(!withCostPrice)}>
            {withCostPrice ? 'Afficher en simplifié' : 'Afficher avec prix de revient'}
          </Buttons.Valid>,
          <Buttons.Valid onClick={() => setOpen(!open)}>Import</Buttons.Valid>,
        ]}
        bodyHeader={<QuotationTotalAmount state={state} />}
        stateHandler={{ state, setState }}
        handleCustomReorder={handleCustomReorder}
        columns={cols}
        lineSharedFunctionList={{ setVatOpen }}
        rowStyle={(row) => {
          let style: CSSProperties = {};
          if (row.original.id === selectedRow?.original?.id) style = { outline: '2px solid #88FFFF' };
          if (row.original.rowColor) style = { ...style, backgroundColor: row.original.rowColor };
          if (!row.original.chapter) return style;
          return { backgroundColor: handleChapterColor(row.original.indent), ...style };
        }}
        cellStyle={() => ({ padding: '0 0.2rem' })}
        // footerLines={QuotationFooter({ state, withCostPrice })}
        onRowClick={handleSelectedRow}
        SubRowComponent={(props) => {
          const data = props.row.original;
          const postRoute = APIRoute.BlQuotation + '/ChapterPic';
          if (!data.chapter)
            return (
              <SubRowRefStudy
                key={'sub-' + data.id}
                {...{
                  parentProps: props,
                  state,
                  setState,
                  unitList,
                  categoryList,
                  jobList,
                }}
              />
            );
          return <SubRowChapterPic key={'sub-' + data.id} {...{ data, state, setState, postRoute }} />;
        }}
      />
    ),
    <QuotationImportPanel {...{ open, setOpen, handleImport }} />,
    <BlVatByChapter {...{ state, setState, selectedRow, open: vatOpen, setOpen: setVatOpen }} />,
  ];
};

interface BlVatByChapterProps {
  state: BlQuotationLine[];
  setState: Dispatch<SetStateAction<BlQuotationLine[]>>;
  selectedRow: any;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const BlVatByChapter: FC<BlVatByChapterProps> = ({ state, setState, selectedRow, open, setOpen }) => {
  const [blVatList, setBlVatList] = useState<BlVat[]>([]);
  const [selectedVat, setSelectedVat] = useState<BlVat>();

  useEffect(() => {
    CRUD.getList<BlVat>(APIRoute.BlVat).then(setBlVatList);
  }, []);

  const handleApplyVat = () => {
    if (!selectedVat) return notificationService.error('Veuillez sélectionner une TVA');
    const list = [...state];
    const index = list.findIndex((x) => x.id === selectedRow.original.id);
    if (index === -1) return notificationService.error('Erreur Dnd Table: ligne non trouvée');
    const endIndex = getEndChapterIndex(list, index, true);
    for (let i = index + 1; i < endIndex + index; i++) {
      list[i].vatId = selectedVat.id;
      list[i].vatValue = selectedVat.value;
    }
    setState(list);
    setOpen(false);
  };

  return (
    <CmsDialog open={open} onClose={setOpen}>
      <CmsPaper title="Appliquer une TVA par lot" style={{ marginBottom: 0 }}>
        <p>Attention, Vous allez appliquer une TVA à l'ensemble des lignes du devis contenue dans ce chapitre</p>
        <InputUI.AutoCompletor options={blVatList} value={selectedVat} onChange={setSelectedVat} returnObject />
        <Buttons.Valid onClick={handleApplyVat}>Appliquer la TVA à la sélection</Buttons.Valid>
      </CmsPaper>
    </CmsDialog>
  );
};

const AddLineButtons: FC<{ state: any; setState: any; selectedRow: any }> = ({ state, setState, selectedRow }) => {
  if (state.length === 0) return <></>;
  const handleAddLine = (up: boolean) => {
    if (!selectedRow) return notificationService.error("Veuillez sélectionner une ligne avant d'ajouter une ligne");
    const list = [...state];
    const index = list.findIndex((x) => x.id === selectedRow.original.id);
    if (index === -1) return notificationService.error('Erreur Dnd Table: ligne non trouvée');
    list.splice(index + (up ? 0 : 1), 0, { id: getNewId(state) } as BlQuotationLine);
    setState(list);
  };

  return [
    <Buttons.Default style={{ marginRight: '0.5rem' }} onClick={() => handleAddLine(true)}>
      Ajouter une ligne avant
    </Buttons.Default>,
    <Buttons.Default onClick={() => handleAddLine(false)}>Ajouter une ligne après</Buttons.Default>,
  ];
};

export const QuotationTotalAmount: FC<{ state: BlQuotationLine[] }> = ({ state }) => {
  const ht = state.reduce((acc, x) => acc + (x.chapter ? 0 : (x.amount ?? 0)), 0);
  const taxTotal = state.reduce((acc, x) => acc + (x.chapter ? 0 : (x.vatAmount ?? 0)), 0);
  return (
    <div className="flex-h" style={{ marginBottom: '1rem' }}>
      <WarningBubble type="info" style={{ marginRight: '1rem' }}>
        Total H.T: {Utils.ThousandSpacing(ht.toFixed(2))} €
      </WarningBubble>
      <WarningBubble type="info" style={{ marginRight: '1rem' }}>
        TVA: {Utils.ThousandSpacing(taxTotal.toFixed(2))} €
      </WarningBubble>
      <WarningBubble type="info">Total TTC: {Utils.ThousandSpacing((ht + taxTotal).toFixed(2))} €</WarningBubble>
    </div>
  );
};

const handleChapterColor = (indent: number) => {
  return ['#10253f', '#17375d', '#375f91', '#548dd3', '#94b2d5', '#b8cbe3'][indent];
};

const ActionCell: ((info: CellDndContext<any>) => any) | undefined = (x) => {
  const getNewListAndStartIndex = (): [any[], number] => {
    const list = [...(x.cell.stateHandler.state as BlQuotationStudyLine[])];
    const startIndex = list.findIndex((y) => y.id === x.row.original.id);
    if (startIndex === -1) notificationService.error('Erreur Dnd Table: ligne non trouvée');
    return [list, startIndex];
  };

  const handleTransformChapter = () => {
    const [list, startIndex] = getNewListAndStartIndex();
    list.splice(startIndex, 1, { id: getNewId(x.cell.stateHandler.state), chapter: true } as any);
    x.cell.stateHandler.setState(list);
  };

  const handleDelete = () => {
    const [list, index] = getNewListAndStartIndex();
    list.splice(index, 1);
    x.cell.stateHandler.setState(list);
  };

  const handleCreateEmptyRow = (after = false) => {
    const [list, startIndex] = getNewListAndStartIndex();
    list.splice(startIndex + (after ? 1 : 0), 0, { id: getNewId(x.cell.stateHandler.state) } as any);
    x.cell.stateHandler.setState(list);
  };

  return (
    <div className="flex-center">
      <CmsMenuButton
        preventClose={false}
        title={<CmsIcon icon="moreVertical" />}
        overLoadButton={(props) => (
          <CmsIcon icon="moreVertical" getMouseEvent onClick={props.handleClick} style={{ margin: '-0.2rem' }} />
        )}
      >
        {(x.row.original.chapter && []) || [<span onClick={handleTransformChapter}>Transformer en chapitre</span>]}
        <span onClick={() => handleCreateEmptyRow(false)}>créer une minute avant</span>
        <span onClick={() => handleCreateEmptyRow(true)}>créer une minute après</span>
        <span onClick={handleDelete}>Supprimer la minute</span>
      </CmsMenuButton>
    </div>
  );
};

const QuotationRefStudyConfig: CmsColumnDndDef<any>[] = [
  { header: 'Invariable', id: 'isFlatRate', size: 0, cell: DndInput.Switch, hideIfChapter: true },
  { header: 'Libellé', id: 'label', size: 600, cell: DndInput.Text },
  {
    header: 'Type',
    id: 'type',
    size: 0,
    cell: DndInput.Select,
    hideIfChapter: true,
    inputOptions: { data: BlReferenceStudyTypeOptions },
  },
  {
    header: 'Categorie',
    id: 'categoryId',
    size: 120,
    cell: (x) => {
      return (
        <InputUI.AutoCompletor
          variant="standard"
          options={x.cell.lineSharedFunctionList.categoryList}
          value={x.getValue()}
          onChange={x.cell.setCellValue}
        />
      );
    },
    hideIfChapter: true,
  },
  {
    header: "Corps d'état",
    id: 'jobId',
    size: 120,
    cell: (x) => {
      return (
        <InputUI.AutoCompletor
          variant="standard"
          options={x.cell.lineSharedFunctionList.jobList}
          value={x.getValue()}
          onChange={x.cell.setCellValue}
        />
      );
    },
    hideIfChapter: true,
  },
  { header: 'Quantité', id: 'quantity', size: 0, cell: DndInput.Number, hideIfChapter: true },
  { header: 'Perte(%)', id: 'lossFactor', size: 0, cell: DndInput.Number, hideIfChapter: true },
  {
    header: 'Unité',
    id: 'unitId',
    size: 0,
    hideIfChapter: true,
    cell: (x) => {
      return (
        <InputUI.AutoCompletor
          variant="standard"
          options={x.cell.lineSharedFunctionList.unitList}
          value={x.getValue()}
          onChange={x.cell.setCellValue}
        />
      );
    },
  },
  {
    header: 'Prix U',
    id: 'calculatedUnitPrice',
    size: 0,
    hideIfChapter: true,
    cell: (x) => <div style={{ textAlign: 'right' }}>{Utils.ThousandSpacing(x.getValue() ?? 0, 2)} €</div>,
  },
  {
    header: 'P.R.U',
    id: 'calculatedMarkupPrice',
    size: 0,
    hideIfChapter: true,
    cell: (x) => <div style={{ textAlign: 'right' }}>{Utils.ThousandSpacing(x.getValue() ?? 0, 2)} €</div>,
  },
  {
    header: 'Montant H.T',
    id: 'unitPrice',
    size: 0,
    hideIfChapter: true,
    cell: (x) => {
      if (x.row.original.referenceStudyLineId) {
        return <div style={{ textAlign: 'right' }}>{Utils.ThousandSpacing(x.getValue(), 2)} €</div>;
      } else {
        return (
          <InputUI.DebouncedInput
            variant="standard"
            type="number"
            key={x.cell.key}
            valueCanUpdate
            onChange={x.cell.setCellValue}
            value={x.getValue()}
          />
        );
      }
    },
  },
  { header: 'Action', id: 'id', size: 0, cell: ActionCell },
];

interface SubRowRefStudyProps {
  parentProps: any;
  state: BlQuotationLine[];
  setState: Dispatch<SetStateAction<BlQuotationLine[]>>;
  unitList: WkUnit[];
  categoryList: BlStudyCategory[];
  jobList: WkJob[];
}

const SubRowRefStudy: FC<SubRowRefStudyProps> = ({ parentProps, state, setState, unitList, categoryList, jobList }) => {
  const row = parentProps.row.original;
  const handleStateUpdate = (table: any[]) => {
    const toUpdate = state.find((x) => x.id === row.id);
    if (!toUpdate) return notificationService.error('Erreur Dnd Table: ligne non trouvée');
    toUpdate.blQuotationStudyLine = table;
    setState([...state]);
  };

  const studyLines = useMemo(() => {
    for (const line of (parentProps.row.original.blQuotationStudyLine ?? []) as BlQuotationStudyLine[]) {
      if (line.referenceStudyLine) {
        line.calculatedUnitPrice = line.referenceStudyLine.calculatedUnitPrice;
        line.calculatedMarkupPrice = line.referenceStudyLine.markupPrice;
      } else if (line.article) {
        line.calculatedUnitPrice = line.article.price;
        if (line.category) {
          line.calculatedMarkupPrice = +((line.article.price ?? 0) * (line.category.markupFactor ?? 0)).toFixed(2);
        }
      }
    }
    return parentProps.row.original.blQuotationStudyLine;
  }, [parentProps.row.original]);

  const handleCustomReorder = (draggedRowIndex: number, targetRowIndex: number) => {
    if (!parentProps.row.original.blQuotationStudyLine) return;
    const data = [...parentProps.row.original.blQuotationStudyLine];
    data.splice(targetRowIndex, 0, data.splice(draggedRowIndex, 1)[0] as any);
    return handleStateUpdate(data.map((x, i) => ({ ...x, position: i })));
  };

  const handleCreateEmptyLine = () => {
    const data = [...parentProps.row.original.blQuotationStudyLine];
    data.push({ id: getNewId(state) } as any);
    handleStateUpdate(data);
  };

  return [
    <div
      key={'subrow-body-' + row.id}
      className="cms-grid"
      style={{ gridTemplateColumns: '6rem auto', padding: '0.2rem' }}
    >
      <div className="flex-v" style={{ alignItems: 'center', justifyContent: 'center' }}>
        <h4>Minutes </h4>
        <h4>d'études: </h4>
        <Buttons.Default onClick={handleCreateEmptyLine}>Créer une ligne</Buttons.Default>
      </div>
      <CmsDndTable
        withoutPaper
        lineSharedFunctionList={{ unitList, categoryList, jobList }}
        stateHandler={{ state: studyLines, setState: handleStateUpdate as any }}
        columns={QuotationRefStudyConfig}
        rowStyle={quotationStudyLineStyle}
        handleCustomReorder={handleCustomReorder}
      />
    </div>,
  ];
};

const quotationStudyLineStyle = (row: any) => {
  let style: CSSProperties = {};
  // if (row.original.id === selectedRow?.original?.id) style = { outline: '2px solid #88FFFF' };
  if (row.original.rowColor) style = { ...style, backgroundColor: row.original.rowColor };
  if (!row.original.chapter) return style;
  return { ...style, backgroundColor: '#1c103f' };
};

const QuotationPicAndFiles: FC<{ formState: UseFormReturn }> = ({ formState }) => {
  const [fileToUpload, setFileToUpload] = useState<File>();
  const { id, imagePath } = formState.watch() as BlQuotation;
  const [fileUrl, setFileUrl] = useState<string | undefined>(imagePath);
  const [files, setFiles] = useState<FileList | undefined>();
  const handleFileToUpload = (file: File) => {
    if (file.type === 'image/jpeg' || file.type === 'image/png') return setFileToUpload(file);
    NotificationService.error(`Le fichier ${file.name} n'est pas un jpg ou un png`);
    setFileToUpload(undefined);
  };

  const handleUpload = () => {
    if (!fileToUpload) return NotificationService.error('Veuillez sélectionner un fichier');
    CRUD.postFormData<IdLabel>(APIRoute.BlQuotation + '/Pic', {
      file: fileToUpload,
      id,
    }).then((x) => {
      NotificationService.success('Image téléversée avec succès');
      setFileToUpload(undefined);
      setFileUrl(x.label);
      formState.setValue('imagePath', x.label);
    });
  };

  const handleFileUpload = () => {
    if (!files) return NotificationService.error('Veuillez sélectionner un fichier');
    // TODO: Check file size
    // for (let file of files) {
    //   if (file.size < 52428800) continue;
    //   const message = 'Le fichier ' + file.name + ' est trop volumineux (50Mo max)';
    //   return NotificationService.error(message);
    // }
    CRUD.postFile(APIRoute.BlQuotation + '/Attachments/' + id, files).then(() => {
      NotificationService.success('Fichiers téléversés avec succès');
      setFiles(undefined);
    });
  };

  return (
    <CmsPaper className="pic-form" title="Pièce jointes">
      <div className="pic-buttons">
        {!!fileUrl && (
          <div className="flex-center">
            <LazyLoadImage
              alt="Image entete devis"
              effect="opacity"
              src={`${API_URL}${APIRoute.BlQuotation}/Pic/${fileUrl}`}
              style={{
                maxHeight: '10rem',
                maxWidth: '100%',
                borderRadius: '0.3rem',
                boxShadow: '0 0 0.5rem 0.1rem rgba(0,0,0,0.3)',
              }}
            />
          </div>
        )}
        {(!!id && (
          <InputUI.InputFile
            style={{ marginBottom: '0.5rem' }}
            name="quotation-pic"
            id="input-file"
            onFileSelected={handleFileToUpload}
            image
          />
        )) ||
          'Veuillez enregistrer le devis pour ajouter une photo'}
      </div>
      <Buttons.Valid style={{ marginBottom: '0.5rem' }} disabled={!fileToUpload} onClick={handleUpload}>
        Téléverser l'image
      </Buttons.Valid>
      <UI.Divider />
      {(!!id && <InputUI.InputFile id="linkedFiles" label="Pièce jointes" multiple onFileSelected={setFiles} />) ||
        'Veuillez enregistrer le devis pour ajouter des pièces jointes'}
      <Buttons.Valid
        style={{ marginBottom: '0.5rem', marginTop: '0.5rem' }}
        disabled={!files?.length}
        onClick={handleFileUpload}
      >
        Téléverser des pièces jointes
      </Buttons.Valid>
      <BlQuotationAttachmentList formState={formState} isShow={false} />
    </CmsPaper>
  );
};

export const BlQuotationAttachmentList: FC<{ formState: UseFormReturn; isShow: boolean }> = ({ formState, isShow }) => {
  const { blQuotationAttachment } = formState.watch() as BlQuotation;

  const handleDownload = (attachment: BlQuotationAttachment) => {
    CRUD.getBlob(APIRoute.BlQuotation + '/GetAttachments', { id: attachment.id }).then((blob) => {
      console.log('attachement', attachment);
      Utils.downloadFile(blob, attachment.filename);
    });
  };

  const handleDelete = (id: number) => {
    const filtered = blQuotationAttachment.filter((x) => x.id !== id);
    formState.setValue('blQuotationAttachment', filtered);
  };

  return (
    <ListStyleDataView
      title="Pièces Jointes"
      style={{ overflowY: 'auto', maxHeight: '20rem' }}
      fromList={blQuotationAttachment}
      cell={(x) => (
        <div className="flex-h-bet clickable">
          <div onClick={() => handleDownload(x)}>{x.filename}</div>
          {!isShow && <CmsIcon icon="delete" onClick={() => handleDelete(x.id)} />}
        </div>
      )}
    />
  );
};

//#endregion
