import React, { useState } from 'react';
import { authenticationService } from '../service/Authentication.service';
import { TranslateDateForURL } from '../helper/handle-request';
import CRUD from '../service/CRUD.service';
import Utils, { arrayReconciliation, mapDateAttributesInArray } from '../helper/Utils';
import { SchedulerData } from '../component/scheduler/Scheduler';
import {
  GCElement,
  GlobalContextElemProps,
  GlobalContextListProps,
  GlobalContextProps,
  GlobalContextSchedulerProps,
  GlobalPlanningContext,
  GlobalContext,
} from './Global.context';
import { Theme } from '@mui/material';
import { CmsGlobalThemeProps, darkTheme, lightTheme } from '../component/Theming';
import { RTColumn } from '../interface/RTColumn';
import { DateFilter } from '../component/table/helper/customFilters';
import themingService from '../component/ThemingService';
import { CmsColumnDef } from '../component/table/CmsTable';
import CmsTableFilter from '../component/table/helper/CmsTableFilter';
import { sendUserPreferences } from '../service/CacheService';

interface HistoryProps {
  saveFilter?: boolean;
  filter?: string;
  customDefaultFilter?: string;
  favorites?: string;
  dropDownMenuState?: string;
}

/**
 * Composant permettant de fournir le contexte global à l'ensemble de l'application.
 * Les context de ReactTable et Planning sont séparés pour éviter les boucles infinies de mise à jour l'un l'autre.
 * @param props On passe les enfants du composant
 */
export const GlobalProvider = (props: { children: React.ReactNode }) => {
  const [globalContext, setGlobalContextState] = useState<GlobalContextProps>(new GlobalContextProps());
  const [globalPlanning, setGlobalPlanningState] = useState<GlobalPlanningContext>(new GlobalPlanningContext());
  const [historyFilter, setHistoryFilter] = useState<HistoryProps[]>([]);
  const [currentUser, setCurrentUser] = useState<any>(authenticationService.getCurrentUser());
  const [firstHistoryRender, setFirstHistoryRender] = useState<boolean>(true);
  const [payload, setPayload] = useState<any>();
  const [themeState, setThemeState] = useState<Theme>(
    localStorage.getItem('theme') === 'light' ? lightTheme : darkTheme,
  );

  const globalHistory = {
    get: (path: any, getCustomDefaultFilterOnly = false): any => {
      if (!!currentUser && !firstHistoryRender) {
        if (getCustomDefaultFilterOnly) return historyFilter[path]?.customDefaultFilter ?? '';
        return historyFilter[path]?.filter ?? historyFilter[path]?.customDefaultFilter ?? '';
      }
      setFirstHistoryRender(false);
      const tempCurrentUser = authenticationService.getCurrentUser();
      if (tempCurrentUser === null) return '';
      setCurrentUser(authenticationService.getCurrentUser());
      const historyFromStorage = GetUrlHistoryFromStorage();
      setHistoryFilter(historyFromStorage);
      return historyFromStorage[path]?.filter ?? historyFromStorage[path]?.customDefaultFilter ?? '';
    },
    set: (path: any, value: string): void => {
      const tempHistory: HistoryProps[] = Object.assign({}, historyFilter);
      if (!tempHistory[path]?.saveFilter) return;
      tempHistory[path].filter = value;
      setHistoryFilter(tempHistory);
      if (currentUser == null) return;
      localStorage.setItem('history', JSON.stringify({ userId: currentUser.userId, history: tempHistory }));
    },
    getFavorite: (path: any): any => {
      if (!!currentUser && !firstHistoryRender) {
        return historyFilter[path]?.favorites ?? '';
      }
      return '';
    },
    setFavorite: (location: any, value: string): void => {
      const path = location?.pathname;
      if (!path) return;
      const tempHistory: HistoryProps[] = Object.assign({}, historyFilter);
      if (!tempHistory[path] || typeof tempHistory[path] === 'string') tempHistory[path] = {} as HistoryProps;
      tempHistory[path].favorites = value;
      setHistoryFilter(tempHistory);
      if (currentUser == null) return;
      localStorage.setItem('history', JSON.stringify({ userId: currentUser.userId, history: tempHistory }));
      sendUserPreferences();
    },
    getDropDownMenuState: (path: any): any => {
      if (!!currentUser && !firstHistoryRender) {
        return historyFilter[path]?.dropDownMenuState ?? '';
      }
      return '';
    },
    setDropDownMenuState: (location: any, value: string): void => {
      const path = location?.pathname;
      if (!path) return;
      const tempHistory: HistoryProps[] = Object.assign({}, historyFilter);
      if (!tempHistory[path] || typeof tempHistory[path] === 'string') tempHistory[path] = {} as HistoryProps;
      tempHistory[path].dropDownMenuState = value;
      setHistoryFilter(tempHistory);
      if (currentUser == null) return;
      localStorage.setItem('history', JSON.stringify({ userId: currentUser.userId, history: tempHistory }));
    },
    setSaveFilter: (location: any, saveIt: boolean, isCustomDefaultFilter = false): any => {
      const path = location?.pathname;
      if (!path) return;
      const tempHistory: HistoryProps[] = Object.assign({}, historyFilter);
      if (!tempHistory[path] || typeof tempHistory[path] === 'string') tempHistory[path] = {} as HistoryProps;
      if (isCustomDefaultFilter) {
        tempHistory[path].customDefaultFilter = location?.search;
      } else {
        tempHistory[path].saveFilter = saveIt;
        tempHistory[path].filter = saveIt ? location?.search : undefined;
      }
      setHistoryFilter(tempHistory);
      if (currentUser == null) return;
      localStorage.setItem('history', JSON.stringify({ userId: currentUser.userId, history: tempHistory }));
      sendUserPreferences();
    },
    isFilterSaved: (path: any): boolean => historyFilter[path]?.saveFilter ?? false,
    clean: () => {
      localStorage.removeItem('history');
      setHistoryFilter([]);
    },
  };

  const theming = {
    get: (): CmsGlobalThemeProps => themeState as CmsGlobalThemeProps,
    set: (newTheme: Theme) => setThemeService(newTheme) && setThemeState(newTheme),
  };

  const setGlobalContextElem = (elem: any, elemType: GCElement) => {
    const tempContext: any = Object.assign({}, globalContext);
    tempContext[elemType] = elem;
    setGlobalContextState(tempContext);
  };

  const setGlobalContextList = (props: GlobalContextListProps) =>
    setContextList(props, globalContext, setGlobalContextState);

  const setGlobalPlanning = (elem: GlobalContextSchedulerProps) =>
    setPlanning(elem, globalPlanning, setGlobalPlanningState);

  return (
    <GlobalContext.Provider
      value={{
        globalPlanning,
        globalContext,
        globalHistory,
        setGlobalContextElem,
        setGlobalContextList,
        setGlobalPlanning,
        payload,
        setPayload,
        theming,
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};

function setThemeService(theme: any): boolean {
  themingService.set(theme);
  localStorage.setItem('theme', theme === darkTheme ? 'dark' : 'light');
  sendUserPreferences();
  return true;
}

/**
 * Fonction permettant de récupérer l'historique des filtres depuis le local storage
 */
function GetUrlHistoryFromStorage(): HistoryProps[] {
  const history = localStorage.getItem('history');
  if (!history) return [];
  try {
    const parsedHistory = JSON.parse(history);
    if (!!parsedHistory?.userId && parsedHistory.userId === authenticationService.getCurrentUser().userId)
      return parsedHistory?.history ?? [];
    localStorage.removeItem('history');
    return [];
  } catch (e) {
    localStorage.removeItem('history');
    return [];
  }
}

/**
 * Fonction permettant de mettre à jour un élément du contexte global
 * @param target Cible de l'élement à mettre à jour
 * @param route Route de l'API à utiliser
 * @param sort Fonction de tri des éléments
 * @param stringToDateList Liste des attributs devant être convertis en date
 * @param configList Liste de configuration du tableau (RTColumn)
 * @param dataBuilder Fonction à utiliser pour construire les données (comme additionner des champs)
 * @param globalContext Contexte global
 * @param setGlobalContextState Fonction de mise à jour du contexte global
 */
const setContextList = (
  { target, route, sort, stringToDateList, configList, dataBuilder }: GlobalContextListProps,
  globalContext: GlobalContextProps,
  setGlobalContextState: React.Dispatch<React.SetStateAction<GlobalContextProps>>,
) => {
  const lastUpdated = new Date();
  const elem: GlobalContextElemProps<any> = globalContext[target];
  let url = route ? route : elem.route.toString();
  if (elem.isUpdatable && elem.lastUpdated) url += '/' + TranslateDateForURL(elem.lastUpdated);
  if (elem.customParams) {
    let params = ''; // ajout des paramètres à l'URL
    elem.customParams.forEach((param) => (params += '&' + param));
    url += params.replace('&', '?'); //replace first & by ?
    if (elem.clearParams) delete elem.customParams; //params uniquement à l'initialisation
  }
  CRUD.getList<any>(url).then((list) => {
    if (list == null) return;
    if (dataBuilder) list = dataBuilder(list);
    if (configList) list = parseDateWithConfig(list, configList); // conversion des dates
    if (elem.stringToDateList)
      list = mapDateAttributesInArray(list, stringToDateList ? stringToDateList : elem.stringToDateList);
    const newContext = Object.assign({}, globalContext);
    // intégration des modifications dans la liste existante
    if (elem.isUpdatable) list = arrayReconciliation(elem?.rawList ?? [], list);
    const rawList = list; // même objet si pas de tri
    if (sort) list = Object.assign([], rawList).sort(sort); // sinon on clone la liste avant de trier
    newContext[target] = { ...elem, list: Object.assign([], list), lastUpdated, rawList: Object.assign([], rawList) };
    setGlobalContextState(newContext);
  });
};

/**
 * Fonction permettant de convertir les attributs de type date en objet Date (le json renvoyé par l'API est un string)
 * @param list liste des objets à convertir
 * @param configList liste de configuration du tableau (RTColumn)
 */
function parseDateWithConfig(list: any[], configList: RTColumn[] | CmsColumnDef<any>[]): any[] {
  for (const col of configList as any[]) {
    if (col.Filter === DateFilter || col.Filter === CmsTableFilter.Date) {
      if (col.accessor) {
        for (const item of list) item[col.accessor] = Utils.Date.tryParseDate(item[col.accessor]);
      } else if (col.accessorKey) {
        for (const item of list) item[col.accessorKey] = Utils.Date.tryParseDate(item[col.accessorKey]);
      }
    }
  }
  return list;
}

/**
 * Fonction permettant de mettre à jour un planning du contexte global
 * @param elem élément à mettre à jour, se référer à GlobalContextSchedulerProps
 * @param globalPlanning contexte global
 * @param setGlobalPlanningState fonction de mise à jour du contexte global
 */
const setPlanning = (
  elem: GlobalContextSchedulerProps,
  globalPlanning: GlobalPlanningContext,
  setGlobalPlanningState: React.Dispatch<React.SetStateAction<GlobalPlanningContext>>,
) => {
  const tempPlanning: any = Object.assign({}, globalPlanning);
  const oldData: SchedulerData = tempPlanning[elem.target]?.data ?? {};
  const oldElem: GlobalContextSchedulerProps = Object.assign({}, globalPlanning[elem.target] ?? {});
  const since = tempPlanning[elem.target]?.since;
  tempPlanning[elem.target] = { ...(tempPlanning[elem.target] ?? {}), ...elem };
  if (elem.refreshFilter) {
    elem = { ...(globalPlanning[elem.target] ?? {}), filterList: elem.filterList };
    tempPlanning[elem.target] = elem;
    setGlobalPlanningState({ ...tempPlanning });
  }
  if (elem.refresh) {
    elem = { ...oldElem, ...elem };
    tempPlanning[elem.target] = elem;
    setGlobalPlanningState({ ...tempPlanning });
  }
  if (!!elem.fetchFunction && !!elem.fetchList && (!elem.data || elem.refresh)) {
    const finalFilterList: any[] = elem.filterList?.filter((x) => x.accessor !== 'since') ?? [];
    if (!!since && elem.refresh) finalFilterList.push({ accessor: 'since', value: since, hideFilter: true });
    elem.fetchFunction(elem.fetchList, finalFilterList).then((data) => {
      if (elem.refresh) {
        oldData.items = (oldData.items ?? []).filter((x) => !data.items.find((y) => y.id === x.id));
        oldData.items = [...oldData.items, ...data.items];
        oldData.groups = (oldData.groups ?? []).filter((x) => !data.groups.find((y) => y.id === x.id));
        oldData.groups = Utils.orderListByAttr([...oldData.groups, ...data.groups], 'title');
        tempPlanning[elem.target].data = { ...oldData };
      } else tempPlanning[elem.target].data = data;
      tempPlanning[elem.target].refresh = false;
      tempPlanning[elem.target].since = new Date();
      setGlobalPlanningState({ ...tempPlanning });
    });
  } else setGlobalPlanningState({ ...tempPlanning });
};
