import React, { FC, ReactNode, useContext, useEffect, useMemo, useRef, useState } from 'react';
import Timeline, {
  DateHeader,
  ReactCalendarItemRendererProps,
  SidebarHeader,
  TimelineHeaders,
} from 'react-calendar-timeline';
import 'react-calendar-timeline/lib/Timeline.css';
import moment from 'moment';
import { CmsPaper } from '../shared/Ui';
import './scheduler.scss';
import { Card, Menu, MenuItem, Popper, PopperPlacementType } from '@mui/material';
import { IdLabel } from '../../interface/CommonType';
import { DialogVanillaUI } from '../shared/DialogUI';
import Utils from '../../helper/Utils';
import CRUD from '../../service/CRUD.service';
import { InputUI, UI } from '../shared';
import AccessFilter from '../../helper/AccessFilter';
import { GlobalContext, GlobalContextSchedulerProps, GlobalPlanningType } from '../../context/Global.context';
import ReloadDataOption from '../table/helper/ReloadDataOption';
import { GetFilterValueFromURL, setSchedulerFiltersInURL } from '../../helper/UrlDataTableHandler';
import { useLocation, useNavigate } from 'react-router-dom';
import APIRoute from '../../constant/API.constant';

// interface de l'état initial du composant
interface InitialStateProps {
  // Défini l'élément sur lequel on a cliqué
  anchorEl: null | HTMLElement;
  // Défini le type d'action sur lequel on a cliqué
  type: 'over' | 'doubleClick' | 'rightClick' | 'groupDoubleClick' | 'groupRightClick' | null;
  // L'objet sur lequel on a cliqué (cela peut être une absence/tâches/etc...)
  item: any;
  // Lance l'animation de chargement
  isLoading: boolean;
}

// État initial du composant
const initialState: InitialStateProps = {
  anchorEl: null,
  type: null,
  item: null,
  isLoading: true,
};

export interface SchedulerData {
  // La liste des objets à afficher dans le calendrier, par exemple les tâches.
  items: any[];
  // La liste des lignes à afficher dans le calendrier, par exemple les tâches sont groupées par intervention.
  groups: any[];
  // État précédent des items dans le cas ou nous annulons une action, nous avons besoin de garder l'état précédent.
  previousItems?: any[];
  // État précédent des groupes dans le cas ou nous annulons une action, nous avons besoin de garder l'état précédent.
  previousGroups?: any[];
  // L'objet qui est actuellement en train d'être modifié. (cycle de vie du calendrier) à ne pas remplir manuellement.
  updatedItem?: any;
  // Action en cours (cycle de vie du calendrier) à ne pas remplir manuellement.
  action?: 'move' | 'resize';
}

// Interface des props du composant Dialog (modal)
export interface SchedulerDialogProps {
  // Date de fin de l'objet cliqué
  end_time: Date | number;
  // Date de début de l'objet cliqué
  start_time: Date | number;
  // Identifiant du groupe de l'objet cliqué
  group: number;
  // Couleur de l'objet afficher dans le calendrier
  color: string;
  // Identifiant de l'objet cliqué
  id: number;
  // Valeur de l'objet cliqué
  value: any;
  // Données globales du contexte du calendrier, utile pour croisé des informations.
  data?: SchedulerData;
  // Méthode à appeler pour fermer le modal
  handleClose: any;
  // Méthode à appeler pour mettre à jour l'objet cliqué
  updateItem: (item: SchedulerDialogProps) => any;
  // Titre afficher sur la modal
  title: string;
  // hook d'état permettant de modifier un objet sans créer de boucle infinie dans le calendrier,
  // ne pas tenter d'utiliser l'updateItem de manière récursive.
  // Cela peut être utile si vous voulez remplir un formulaire dans la modale, sans mêttre à jour le calendrier.
  fluidState: any;
  // Méthode à appeler pour modifier le hook d'état fluidState
  setFluidState: React.Dispatch<any>;
  // Si les items ont été générés avec un type, nous le retrouverons ici (utile pour les calendriers partageant
  // des données de multiple source différentes).
  type?: string;
}

export interface SchedulerFilter {
  // Titre afficher dans le filtre
  title: string;
  // Nom de la propriété de l'objet à filtrer
  accessor: string;
  // Valeur du filtre, optionnel car il existe des filtres par défaut (autochargé par le filter si ce champ n'est
  // par remplie).
  FilterComponent?: (filterProps: SchedulerFilterProps) => ReactNode;
  // Valeur du filtre à ne pas remplir manuellement, sauf pour préfiltrer par défaut sur ce champ.
  value?: any;
  // Type de filtre, il génère des composants FilterComponent par défaut, mais vous pouvez en créer un custom.
  filter?: 'string' | 'number' | 'date' | 'AutoMultiselect' | 'boolean' | 'trueOnly';
  // Surcharge le filtre par défaut, utile si vous voulez faire un filtre custom.
  customFilter?: (item: any[], accessor: string, value: any) => any[];
  // Définie si le filtre peut être caculé uniquement dans le front ou si un appel au backend est nécessaire.
  isBackendFilter?: boolean;
  // Définie le filtre comment étant celui qui va gérer la date de début du calendrier.
  isStartFilter?: boolean;
  // Définie le filtre comment étant celui qui va gérer la date de fin du calendrier.
  isEndFilter?: boolean;
  // Permet d'ajouter un filtre sans qu'il soit afficher dans les filtres. utilisé par le filtre "since" qui permet
  // de dire au backend de ne renvoyer uniquement les éléments qui ont été modifié depuis la dernière requête.
  hideFilter?: boolean;
  // Cycle de vie du calendrier, permet de savoir si le filtre est en train d'être mis à jour.
  // Cela est utile pour savoir si les données doivent être rechargées ou non.
  // Ne pas remplir manuellement.
  isUpdating?: boolean;
}

// En cours de développement, sera utilisé lorsque nous attaquerons la partie édition du temps à partir du calendrier.
export interface SchedulerItemEvent {
  // Données globales du contexte du calendrier, utile pour croisé des informations.
  data: SchedulerData;
  // Fonction de mise à jour des données globales du contexte du calendrier.
  setData: React.Dispatch<SchedulerData>;
  // fonction à appeler pour annuler la dernière action effectuée de type déplacement ou redimensionnement d'un
  // objet du planning.
  cancelAction: Function;
}

// Interface definissant les données transferer à un filtre
export interface SchedulerFilterProps {
  // Valeur du filtre
  filter: SchedulerFilter;
  // Fonction à appeler pour mettre à jour la valeur du filtre
  setFilter: (value: any) => void;
  // Données globales du contexte du calendrier, utile pour croisé des informations.
  data: SchedulerData;
}

// Interface des props du composant Calendrier, à savoir que plusieurs peuvent cohabiter dans un même contexte.
// Si vous faite du multi-fetch, pensez à typez (attr type) vos données pour éviter les conflits.
export interface SchedulerFetch {
  // Url de la source de données
  url: string;
  // Méthode à appeler pour formater les données reçues du backend.
  formatter: (data: any[]) => SchedulerData;
  // Méthode appeler pour savoir si l'utilisateur a le droit de voir ce type de données.
  accessFilter?: string;
  // Type, utile pour les calendriers partageant des données de multiple source différentes.
  type?: string;
  // Méthode appelée pour afficher les données dans le calendrier au survol de la souris.
  itemPopper?: (item: any) => ReactNode;
  // Méthode appelée pour afficher les données dans le calendrier au survol de la souris (colonne groupe).
  groupPopper?: (group: any) => ReactNode;
  // Méthode appelée pour afficher les options du menu contextuel au clic droit sur un objet du calendrier.
  contextOptionList?: ContextOptions[];
  // Méthode appelée pour afficher les options du menu contextuel au clic droit sur un objet du calendrier (groupe).
  contextGroupOptionList?: ContextOptions[];
}

// interface des options du menu contextuel au clic droit sur un objet du calendrier.
export interface ContextOptions {
  // Label de l'option
  label: string;
  // Fonction à appeler pour savoir si l'option doit être affichée ou non.
  if?: (item: any) => boolean;
  // est-ce que le click vient de la colonne groupe ou d'un objet du calendrier.
  isGroupClick?: boolean;
  // Fonction à appeler au clic sur l'option.
  onClick: (item: ContextClick) => void;
}

// interface des données transmises à la fonction onClick des options du menu contextuel au clic droit sur un objet
// du calendrier.
interface ContextClick {
  // L'objet cliqué
  item: any;
  // Le groupe de l'objet cliqué
  group: any;
  // Est-ce que le click est un click molette ou un click gauche.
  isMiddleClick: any;
  // La fonction à appeler pour mettre à jour l'objet cliqué.
  updateItem: any;
}

// Interface des données globales du composant du calendrier.
export interface SchedulerProps {
  // liste des filtres à afficher dans le composant.
  initialFilters?: Array<SchedulerFilter>;
  // Méthode appelée pour gérer exterieur des règles métier pour les filtres. (les règles métiers sont gérées
  // par défaut par le composant, mais vous pouvez en ajouter ici).
  handleFiltering?: (x: Array<SchedulerFilter>) => Array<SchedulerFilter>;
  // Méthode appelée à chaque mise à jour des filtres. Permets de récuperer les filtres à chaque changement.
  onFiltersChange?: (x: Array<SchedulerFilter>) => void;
  // Liste des réquetes à effectuer pour récupérer les données du calendrier.
  fetch: SchedulerFetch[];
  // Popper (effet de survol) à afficher dans le calendrier.
  itemPopper?: (item: any) => ReactNode;
  // Dialog à afficher dans le calendrier, il faut double-click.
  itemDialog?: FC<SchedulerDialogProps>;
  // Popper (effet de survol) à afficher dans le calendrier (colonne groupe).
  groupPopper?: (item: any) => ReactNode;
  // Dialog à afficher dans le calendrier, il faut double-click (colonne groupe).
  groupDialog?: (item: any) => ReactNode;
  // Définie si le calendrier peut changer un item de groupe durant un déplacement.
  canChangeGroup?: boolean;
  // Force avec laquelle on va "zoomer" le calendrier. (2 par défaut => zoom x2)
  zoomSize?: number;
  // peut-on déplacer un item du calendrier.
  canMove?: boolean;
  // Title à afficher au haut de la première colonne (groupe).
  columnTitle?: string | ReactNode;
  // Title à afficher au haut du composant calendrier.
  paperTitle?: string;
  // Définie si le calendrier peut redimensionner un item (pas encore utilisé).
  canResize?: false | true | 'left' | 'right' | 'both';
  // Fonction appeler au déplacement d'un item du calendrier (pas encore utilisé).
  onItemMove?: (event: SchedulerItemEvent) => ReactNode;
  // Fonction appeler au redimensionnement d'un item du calendrier (pas encore utilisé).
  onItemResize?: (event: SchedulerItemEvent) => ReactNode;
  // Liste des boutons/info à afficher dans le coin supérieur droit du calendrier.
  actions?: React.ReactNode[];
  // Modifier cette valeur dans le parent pour forcer une mise à jour des données dans le calendrier.
  reloadListener?: any;
  // Aide à la compréhension, liste des couleur => signification, pour une meilleure compréhension du calendrier.
  captionList?: CaptionProps[];
  // À fournir si vous souhaitez que les données soient sauvegardé dans le globalContext.
  globalScheduler?: GlobalContextSchedulerProps;
  // Ajoute les filtres dans l'url.
  setFilterInUrl?: boolean;
  // Ajoute le bouton de rafraichissement dans le calendrier.
  reloadButton?: boolean;
}

// Gestion de la visibilité du calendrier. (date de début et de fin)
interface TimeHandling {
  // Date de début considérer comme visible (non grisé dans le calendrier).
  visibleTimeStart: number;
  // Date de fin considérer comme visible (non grisé dans le canlendier).
  visibleTimeEnd: number;
}

// Propriétés interme du composant Popper pour le cycle de vie du calendrier
interface PopperPros {
  // L'objet cliqué, permets d'afficher le popper au bon endroit de la page
  anchor: HTMLElement | null;
  // Est-ce que le composant survolé est de la première colonne (groupe) ou non.
  isGroup: boolean;
}

/**
 * Fonction appelée pour récupérer les données du calendrier. elle effectue les requêtes en parallèle pour un gain de
 * rapidité.
 * @param fetchList liste des requêtes à effectuer.
 * @param filters liste des filtres à appliquer aux requêtes.
 */
async function fetchApi(fetchList: SchedulerFetch[], filters: Array<SchedulerFilter>): Promise<SchedulerData> {
  let finalData: SchedulerData = { items: [], groups: [] };
  const callList = [];
  for (let i = 0; i < fetchList.length; i++)
    callList.push(CRUD.getList<any>(fetchList[i].url + Utils.queryAccessorInArray(filters)));
  const resultList: any = await Promise.all(callList);
  for (let i = 0; i < fetchList.length; i++) {
    const dataResult = formatTime(fetchList[i].formatter(resultList[i]));
    finalData = {
      items: [...finalData.items, ...dataResult.items],
      groups: [...finalData.groups, ...dataResult.groups],
    };
  }
  return finalData;
}

/**
 * Reformat les dates en timestamp.
 * @param result données à reformater.
 * @returns données reformater.
 */
function formatTime(result: SchedulerData): SchedulerData {
  for (const item of result.items) {
    item.start_time = Utils.Date.dateOrStringDateToGetTime(item.start_time);
    item.end_time = Utils.Date.dateOrStringDateToGetTime(item.end_time);
  }
  return result;
}

// Composant calendrier. Se référer à la documentation de SchedulerProps pour plus d'informations.
export const Scheduler: FC<SchedulerProps> = ({
  initialFilters,
  onFiltersChange,
  handleFiltering,
  fetch,
  itemPopper,
  itemDialog,
  groupDialog,
  groupPopper,
  zoomSize = 2,
  canMove = false,
  canResize = false,
  canChangeGroup = false,
  onItemMove,
  onItemResize,
  columnTitle,
  paperTitle = 'Planning',
  actions,
  reloadListener,
  captionList,
  globalScheduler,
  setFilterInUrl = false,
  reloadButton = false,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { globalPlanning, setGlobalPlanning, globalHistory } = useContext(GlobalContext);
  const [isFirstRender, setFirstRender] = useState(true);
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState<SchedulerData>();
  const [captions, setCaptions] = useState(captionList);
  const [fluidState, setFluidState] = useState<any>();
  const [filters, setFilters] = useState<Array<SchedulerFilter>>(
    mapFilterValueFromUrl(isFirstRender, globalHistory, navigate, location, Utils.cloneList(initialFilters)),
  );
  const [state, setState] = useState(initialState);
  const [currentItem, setCurrentItem] = useState<any>();
  const [currentGroup, setCurrentGroup] = useState();
  const [popper, setPopper] = useState<PopperPros>({ anchor: null, isGroup: false });
  const initialTime = useMemo(() => getInitialVisibleSpan(filters), [filters]);
  const [timeHandling, setTimeHandling] = useState<TimeHandling>(initialTime);
  const [timeHandler, setTimeHandler] = useState<TimeHandling>(initialTime);
  const [timeChange, setTimeChange] = useState<any>(initialTime);
  const [refresh, setRefresh] = useState(false);
  const [holidayFullList, setHolidayFullList] = useState<Date[]>();
  const [backFilters, setBackFilters] = useState<Array<SchedulerFilter>>(
    Utils.cloneList(filters?.filter((x) => x.isBackendFilter) ?? []),
  );

  useEffect(() => {
    CRUD.getList<string>(APIRoute.Other + '/GetHoliday').then((result) => {
      setHolidayFullList(result?.map((x) => new Date(x)) ?? []);
    });
  }, []);

  const canSeeScheduler = useMemo(() => {
    return fetch.filter((x) => (!x.accessFilter ? true : AccessFilter([x.accessFilter]))).length > 0;
  }, [fetch]);

  const handleDataRefresh = (data: any) => {
    if (!globalScheduler) setData(data);
    else setGlobalPlanning({ data, target: globalScheduler.target });
  };

  useEffect(() => setFirstRender(true), [reloadListener]);
  useEffect(() => (refresh ? setRefresh(false) : undefined), [refresh]);

  const handleUpdateFilters: Function = useMemo(
    () => (newFilters: Array<SchedulerFilter>) => {
      if (!newFilters) return;
      if (handleFiltering) newFilters = handleFiltering(newFilters);
      const start = newFilters.find((x) => x.isStartFilter)?.value;
      const oldStart = filters.find((x) => x.isStartFilter)?.value;
      const end = newFilters.find((x) => x.isEndFilter)?.value;
      const oldEnd = filters.find((x) => x.isEndFilter)?.value;
      if (start && end && oldStart && oldEnd && (start !== oldStart || end !== oldEnd)) {
        const newTimeHandling = {
          ...timeHandling,
          visibleTimeStart: Utils.Date.dateOrStringDateToGetTime(start),
          visibleTimeEnd: Utils.Date.dateOrStringDateToGetTime(end),
        };
        setTimeHandling(newTimeHandling);
        setRefresh(true);
      }
      for (const filter of newFilters) filter.isUpdating = false;
      setFilters(newFilters);
    },
    [filters, timeHandling, handleFiltering],
  );

  // Mis à jour depuis le GlobalContext
  useEffect(() => {
    if (!globalScheduler) return;
    // @ts-ignore
    const item: any = globalPlanning[globalScheduler.target];
    if (item.refresh && !isLoading) setLoading(true);
    if (item.data === data) return;
    setData(item.data);
    setLoading(false);
  }, [globalPlanning, globalScheduler, data, isLoading]);

  // Mis à jour de l'url si défini
  useEffect(() => {
    if (setFilterInUrl) setSchedulerFiltersInURL(filters, navigate, location, globalHistory);
  }, [filters, globalHistory, navigate, location, setFilterInUrl]);

  // Gestion des appels API et de préparation des données pour les requêtes
  useEffect(() => {
    if (!isFirstRender && SchedulerFiltersAreEquals(filters, backFilters, true)) {
      if (!globalScheduler || SchedulerFiltersAreEquals(filters, backFilters)) return;
      // TODO set here for global filter update
      // if (SchedulerFiltersAreEquals(globalPlanning[globalScheduler.target]?.filterList ?? [], filters)) return;
      // setGlobalPlanning({ target: globalScheduler.target, refreshFilter: true, filterList: filters });
      if (onFiltersChange) onFiltersChange(filters);
      setBackFilters(filters);
      return; // Si les filtres backend sont identiques, on ne fait rien
    }
    if (onFiltersChange) onFiltersChange(filters);
    setLoading(true);
    if (!globalScheduler) {
      fetchApi(
        fetch.filter((x) => (!x.accessFilter ? true : AccessFilter([x.accessFilter]))),
        filters ?? [],
      )
        .then(setData)
        .finally(() => setLoading(false));
    } else {
      const item: any = globalPlanning[globalScheduler.target];
      if (!isFirstRender || !item?.filterList || item?.filterList?.length === 0) {
        setGlobalPlanning({
          target: globalScheduler.target,
          fetchList: fetch,
          filterList: filters,
          fetchFunction: fetchApi,
        });
      }
      if (isFirstRender && !!item?.filterList) setGlobalPlanning({ target: globalScheduler.target, refresh: true });
    }
    setBackFilters(filters);
    if (isFirstRender) setFirstRender(false);
  }, [backFilters, fetch, filters, globalPlanning, globalScheduler, isFirstRender, onFiltersChange, setGlobalPlanning]);

  // Génération des filtres
  const filterNodes = GenerateFilterDisplay(filters, handleUpdateFilters, data);

  // Filtrage des données pour rendu finale
  const filteredData = useMemo(() => {
    if (!data?.items) return data;
    const result: any = { ...data };
    if (!!captions && captions.length > 0)
      for (const caption of captions)
        if (!caption.enabled) result.items = result.items.filter((x: any) => x.type !== caption.type);
    for (const filter of filters) {
      if (filter.isBackendFilter) continue;
      if (filter.customFilter) result.items = filter.customFilter(result.items, filter.accessor, filter.value);
      else {
        // @ts-ignore
        result.items = SchedulerFilterFunction[filter.filter ?? 'string'](result.items, filter.accessor, filter.value);
      }
    }
    result.groups = result.groups.filter((x: any) => !!result.items.find((y: any) => y.group === x.id));
    return result;
  }, [data, captions, filters]);

  // Si des catpions sont définies, ils peuvent être cliquables pour service de filtres par type/couleur
  const handleCaptionClick = useMemo(
    () => (type: string) => {
      if (!captions || captions.length === 0) return;
      const caption = captions.find((x) => x.type === type);
      if (!caption) return;
      caption.enabled = !caption.enabled;
      setCaptions([...captions]);
    },
    [captions],
  );

  // mettre à jour les données du planning et du global planning (globalContext)
  const refreshView = useMemo(
    () => () => {
      if (!timeChange.isNew && !!globalScheduler) {
        setGlobalPlanning({ target: GlobalPlanningType.ABSENCE_PLANNING, refresh: true });
        return;
      }
      let filterList: any[] = Utils.cloneList(filters);
      filterList.find((x) => x.isStartFilter).value = new Date(timeChange.visibleTimeStart);
      filterList.find((x) => x.isEndFilter).value = new Date(timeChange.visibleTimeEnd);
      if (handleFiltering) filterList = handleFiltering(filterList);
      handleUpdateFilters(filterList);
      setTimeChange({ ...timeChange, isNew: false });
    },
    [filters, globalScheduler, handleFiltering, handleUpdateFilters, setGlobalPlanning, timeChange],
  );

  // Génération des actions du planning (boutons et caption en haut à droite du planning)
  const act = useMemo(() => {
    const result = actions ? [...actions] : [];
    if (!!globalScheduler || reloadButton) result.push(<ReloadDataOption updateListAction={refreshView} />);
    result.push(<Caption captionList={captionList} onCaptionClick={handleCaptionClick} />);
    return result;
  }, [actions, captionList, globalScheduler, handleCaptionClick, refreshView, reloadButton]);

  if (!canSeeScheduler) return <></>;
  if (!data || refresh || !holidayFullList) return <LoadingScheduler />;
  // Si la liste des données filtrées est vide, on affiche un message
  if (!filteredData?.items || filteredData.items.length === 0)
    return (
      <CmsPaper title={paperTitle} actions={act}>
        {filterNodes}
        <h2>Aucun résultat trouvé pour cette recherche</h2>
      </CmsPaper>
    );

  // Gestion du redimessionnement d'un objet (édition de temps etc...)
  const handleItemResize = (itemId: any, time: any, edge: any) => {
    if (!onItemResize) return;
    const updatedData = HandleItemResize(itemId, time, edge, data);
    const updatedItem = updatedData.items.find((x) => x.id === itemId);
    handleDataRefresh({
      ...updatedData,
      previousItems: data.items,
      previousGroups: data.groups,
      action: 'resize',
      updatedItem,
    });
  };

  // Gestion du déplacement d'un objet (dans le temps ou dans un groupe)
  const handleItemMove = (itemId: any, dragTime: any, newGroupOrder: any) => {
    if (!onItemMove) return;
    const updatedData = HandleItemMove(itemId, dragTime, newGroupOrder, data);
    const updatedItem = updatedData.items.find((x) => x.id === itemId);
    handleDataRefresh({
      ...updatedData,
      previousItems: data.items,
      previousGroups: data.groups,
      action: 'move',
      updatedItem,
    });
  };

  // Met à jour un objet dans la liste des données du planning
  const updateItem = (updatedItem: any) => handleDataRefresh(handleItemUpdate(updatedItem, data));

  // Annule les changements en cours en remettant les données précédentes
  const cancelAction = () => {
    if (!data.previousGroups || !data.previousItems) return;
    handleDataRefresh({ items: data.previousItems, groups: data.previousGroups });
  };

  // Remets à zéro les données de position et de type de l'objet en cours d'édition
  const handleClose = () => setState({ ...state, anchorEl: null, type: null });

  // Gère le suivi de la souris pour afficher les popups d'objets
  const handleMouseEnter = (item: any, anchor: HTMLElement, isGroup = false) => {
    isGroup ? setCurrentGroup(item) : setCurrentItem(item);
    setPopper({ anchor, isGroup });
  };

  // Gère le changement de vue (temps) du planning
  const handleTimeChange = (start: number, end: number, setter: any) => {
    setter(start, end);
    const t = timeChange.threshold ?? 0;
    if (start - t < timeChange.visibleTimeStart && start + t > timeChange.visibleTimeStart) return;
    if (end - t < timeChange.visibleTimeEnd && end + t > timeChange.visibleTimeEnd) return;
    const threshold = (end - start) / 10;
    setTimeChange({ visibleTimeStart: start, visibleTimeEnd: end, threshold, isNew: true });
  };

  // Gèle le clic gauche/droit sur le planning
  const handleAction = (
    event: React.MouseEvent<HTMLButtonElement>,
    item: any,
    type: 'doubleClick' | 'rightClick' | 'groupDoubleClick' | 'groupRightClick',
  ) => setState({ item, anchorEl: event?.currentTarget ?? event, type, isLoading: state.isLoading });

  const timeRange = timeHandler.visibleTimeEnd - timeHandler.visibleTimeStart;
  const isDialogOpen = !!state.anchorEl && (state.type === 'doubleClick' || state.type === 'groupDoubleClick');
  const { minDateTime, maxDateTime } = getMinMaxTime(filters);
  const holidayList = getHolidaysTimes(holidayFullList, minDateTime, maxDateTime);
  return (
    <CmsPaper title={paperTitle} actions={act} isLoading={isLoading}>
      {filterNodes}
      <div style={{ position: 'relative' }}>
        <Timeline
          itemHeightRatio={0.7}
          lineHeight={40}
          groups={filteredData.groups}
          items={filteredData.items}
          canMove={canMove}
          stackItems
          useResizeHandle
          onTimeChange={(x, y, z) => handleTimeChange(x, y, z)}
          canChangeGroup={canChangeGroup}
          canResize={canResize}
          // visibleTimeStart={timeHandler.visibleTimeStart}
          // visibleTimeEnd={timeHandler.visibleTimeEnd}
          defaultTimeStart={moment(timeHandling.visibleTimeStart).locale('fr')}
          defaultTimeEnd={moment(timeHandling.visibleTimeEnd).locale('fr')}
          minZoom={timeRange / zoomSize}
          maxZoom={timeRange * zoomSize}
          onZoom={setTimeHandler}
          onItemMove={handleItemMove}
          onItemResize={handleItemResize}
          verticalLineClassNamesForTime={(start, end) =>
            HandleColumnClass(start, end, minDateTime, maxDateTime, holidayList)
          }
          itemRenderer={(e: ReactCalendarItemRendererProps<any>) => (
            <ItemRenderer
              fullItem={e}
              onContextMenu={handleAction}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={() => setPopper({ anchor: null, isGroup: false })}
            />
          )}
          groupRenderer={(e: any) => (
            <GroupRenderer
              groupItem={e}
              isGroupClickable={true || !!groupDialog}
              onContextMenu={handleAction}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={() => setPopper({ anchor: null, isGroup: false })}
            />
          )}
        >
          <TimelineHeaders>
            <SidebarHeader>
              {({ getRootProps }) => (
                <div
                  {...getRootProps()}
                  className="col-header"
                  style={{ ...getRootProps().style, ...{ color: 'white' } }}
                >
                  {columnTitle}
                </div>
              )}
            </SidebarHeader>

            <DateHeader unit="primaryHeader" />
            <DateHeader />
          </TimelineHeaders>
        </Timeline>
      </div>
      <ContextualMenu
        schedulerFetch={fetch}
        state={state}
        handleClose={handleClose}
        currentItem={currentItem}
        currentGroup={currentGroup}
        updateItem={updateItem}
      />
      <PopperHandler popper={popper} fetch={fetch} currentItem={currentItem} currentGroup={currentGroup} />
      {((!!itemPopper && !popper.isGroup) || (!!groupPopper && popper.isGroup)) && (
        <PopperMenu anchorEl={popper.anchor} open={!!popper.anchor} placement={popper.isGroup ? 'right' : 'bottom'}>
          {popper.isGroup ? !!groupPopper && groupPopper(currentGroup) : !!itemPopper && itemPopper(currentItem)}
        </PopperMenu>
      )}
      {(!!itemDialog || !!groupDialog) && (
        <DialogVanillaUI open={isDialogOpen} onClose={handleClose}>
          {state.type === 'doubleClick'
            ? !!itemDialog &&
              itemDialog({ ...state.item, updateItem, handleClose, fluidState, setFluidState, data: data })
            : !!groupDialog && groupDialog(state.item)}
        </DialogVanillaUI>
      )}
      {(!!onItemMove || !!onItemResize) && (
        <DialogVanillaUI open={!!data.action} onClose={cancelAction}>
          {data.action === 'move'
            ? !!onItemMove && onItemMove({ data, setData: handleDataRefresh, cancelAction })
            : !!onItemResize && onItemResize({ data, setData: handleDataRefresh, cancelAction })}
        </DialogVanillaUI>
      )}
    </CmsPaper>
  );
};

/**
 * Récupère les valeurs des filtres depuis l'URL, les filtres par défaut sont toujours surchargés
 * s'ils sont présents dans l'URL
 * @param history L'objet history de react-router
 * @param fisrt Si c'est la première fois que la fonction est appelée
 * @param globalHistory Le global history du globalContext
 * @param filterlist La liste des filtres par défaut (prédéfinis)
 * @returns La liste des filtres
 */
function mapFilterValueFromUrl(
  fisrt: boolean,
  globalHistory: any,
  navigate: any,
  location: any,
  filterlist?: any[],
): SchedulerFilter[] {
  if (!fisrt || !filterlist) return filterlist ?? [];
  const mapCol: any[] = [];
  for (const x of filterlist) mapCol.push({ accessor: x.accessor, value: x.value, filter: x.filter });
  const urlFilter = GetFilterValueFromURL(mapCol, globalHistory, navigate, location, 'p-');
  if (urlFilter.length === 0) return filterlist;
  filterlist.forEach((x) => {
    const val = urlFilter.find((y) => y.id === x.accessor)?.value;
    if ((x.accessor !== 'minDate' && x.accessor !== 'maxDate') || !!val) x.value = val;
  });
  return filterlist;
}

/**
 * Gère les filtres de début et de fin (obligatoire) pour les requète backend et la gestion de la vue du calendrier
 * @param filter Le filtre mis à jour
 * @param filters La liste des filtres
 * @param setFilters La fonction de mise à jour des filtres
 * @param value La valeur du filtre
 */
const handleSchedulerFilter = (
  filter: SchedulerFilter,
  filters: SchedulerFilter[],
  setFilters: React.Dispatch<Array<SchedulerFilter>>,
  value: any,
) => {
  const updateFilter: SchedulerFilter = { ...filter };
  if ((updateFilter.isStartFilter || updateFilter.isEndFilter) && !Utils.isDate(value)) return;
  if (updateFilter.isStartFilter) {
    value.setHours(0, 0, 0, 0);
    const endFilter = filters.find((x) => x.isEndFilter);
    if (!!endFilter && new Date(endFilter.value).getTime() - value.getTime() < 86000000) {
      endFilter.value = new Date(value.getTime() + 86000000);
      endFilter.value.setHours(23, 59, 59, 999);
    }
  } else if (updateFilter.isEndFilter) {
    value.setHours(23, 59, 59, 999);
    const startFilter = filters.find((x) => x.isStartFilter);
    if (!!startFilter && value.getTime() - new Date(startFilter.value).getTime() < 86000000) {
      startFilter.value = new Date(value.getTime() - 86000000);
      startFilter.value.setHours(0, 0, 0, 0);
    }
  }
  const result: SchedulerFilter[] = [];
  for (const item of [...filters]) {
    item.accessor === filter.accessor ? result.push({ ...item, value, isUpdating: true }) : result.push(item);
    item.value = Utils.isDate(item.value) && filter.isBackendFilter ? Utils.toGmtIsoString(item.value) : item.value;
  }
  setFilters(result);
};

/**
 * Génère le composant de filtre (affichage)
 * @param filters La liste des filtres à afficher
 * @param setFilters La fonction de mise à jour des filtres
 * @param data Les données du calendrier
 * @returns Le composant contenant les filtres
 */
function GenerateFilterDisplay(
  filters: Array<SchedulerFilter> | undefined,
  setFilters: any,
  data?: SchedulerData,
): ReactNode {
  if (!filters || !Array.isArray(filters)) return <></>;
  return (
    <div className="scheduler-filter-container">
      {filters.map((filter: SchedulerFilter, index) => {
        const filterComponent: any = filter.FilterComponent ?? DefaultFilters[filter.filter ?? 'string'];
        if (filter.hideFilter) return <></>;
        return (
          <span className="filter-item" key={index}>
            {filterComponent({
              filter,
              setFilter: (value: any) => {
                handleSchedulerFilter(filter, filters, setFilters, value);
              },
              data,
            })}
          </span>
        );
      })}
    </div>
  );
}

interface PopperHandlerProps {
  // Le composant popper depuis le calendrier
  popper: any;
  // Liste des fonctions de récupération des données (contient également les poppers)
  fetch: SchedulerFetch | SchedulerFetch[];
  // Si le popper est un item, la donnée est ici
  currentItem?: any;
  // Si le popper est un groupe (première colonne), la donnée est ici
  currentGroup?: any;
}

/**
 * Gère l'affichage des poppers
 * @param popper Le composant popper depuis le calendrier
 * @param currentGroup La donnée du groupe (première colonne)
 * @param currentItem La donnée de l'item
 * @param fetch La liste des fonctions de récupération des données (contient également les poppers)
 * @constructor
 */
const PopperHandler: FC<PopperHandlerProps> = ({ popper, currentGroup, currentItem, fetch }) => {
  const type = currentItem?.type ?? currentGroup?.type ?? undefined;
  const isPopperItem = !popper.isGroup;
  let pop: any = undefined;
  if (!type && Array.isArray(fetch)) return <></>;
  if (!Array.isArray(fetch)) {
    if ((isPopperItem && !fetch.itemPopper) || (!isPopperItem && !!fetch.groupPopper)) return <></>;
    pop = isPopperItem ? fetch.itemPopper : fetch.groupPopper;
  } else if (Array.isArray(fetch)) {
    const poppingType = fetch.find((x) => !!x.type && x.type.indexOf(type) !== -1);
    if (!poppingType) return <></>;
    pop = isPopperItem ? poppingType.itemPopper : poppingType.groupPopper;
  } else return <></>;
  if (!pop) return <></>;
  return (
    <PopperMenu anchorEl={popper.anchor} open={!!popper.anchor} placement={popper.isGroup ? 'right' : 'bottom'}>
      {pop(isPopperItem ? currentItem : currentGroup)}
    </PopperMenu>
  );
};

// Génère le composant de chargement du planning
const LoadingScheduler: FC = () => (
  <CmsPaper title="Chargement du planning" isLoading={true} style={{ height: '200px' }}>
    {' '}
  </CmsPaper>
);

/**
 * Gestion des couleurs des colonnes (grisé == données non-remonter par le backend)
 * Il est possible que des données soient en partie sur la donnée qrisé mais jamais complètement
 * @param start Date de début de visibilité du calendrier
 * @param end Date de fin de visibilité du calendrier
 * @param minDate Date de début des données recherchée
 * @param maxDate Date de fin des données recherchée
 * @param holidatyList Liste des jours fériés
 * @returns La liste des classes à appliquer
 */
function HandleColumnClass(
  start: any,
  end: any,
  minDate?: number,
  maxDate?: number,
  holidatyList: number[] = [],
): string[] | undefined {
  if (!minDate || !maxDate) return [];
  if (start > maxDate || end < minDate) return [' out-limit'];
  for (const time of holidatyList) if (start >= time && end <= time + 86400000) return [' holiday'];
  return [];
}

/**
 * Retourne les dates min et max des filtres
 * @param filters Liste des filtres
 * @returns Les dates min et max
 */
function getMinMaxTime(filters: any[]) {
  const minDateString = filters.find((x) => x.accessor === 'minDate')?.value;
  const maxDateString = filters.find((x) => x.accessor === 'maxDate')?.value;
  const minDateTime = !minDateString ? undefined : new Date(minDateString).getTime();
  const maxDateTime = !maxDateString ? undefined : new Date(maxDateString).getTime();
  return { minDateTime, maxDateTime };
}

/**
 * Retourne la liste des jours fériés au format timestamp
 * @param holiDayList liste des jours fériés (envoyer par le backend)
 * @param minDate Date de début des données recherchée
 * @param maxDate Date de fin des données recherchée
 */
function getHolidaysTimes(holiDayList: Date[], minDate?: number, maxDate?: number): number[] {
  if (!minDate || !maxDate) return [];
  const result = [];
  for (const date of holiDayList) {
    const time = date.getTime();
    if (time >= minDate && time <= maxDate) result.push(time);
  }
  return result;
}

/**
 * Gère le déplacement d'un item
 * @param itemId L'id de l'item
 * @param dragTime Date à laquelle l'item a été déplacé
 * @param newGroupOrder Groupe dans lequel l'item a été déplacé
 * @param data Les données du calendrier
 * @returns Les données du calendrier
 */
const HandleItemMove = (itemId: any, dragTime: any, newGroupOrder: any, data: SchedulerData): SchedulerData => {
  const { items, groups } = data;
  const group = groups[newGroupOrder];
  const itemsUpdated = items.map((item: any) =>
    item.id === itemId
      ? Object.assign({}, item, {
          start_time: dragTime,
          end_time: dragTime + (item.end_time - item.start_time),
          group: group.id,
        })
      : item,
  );
  return { items: itemsUpdated, groups };
};

/**
 * Gère la mise à jour d'un item
 * @param updatedItem L'item mis à jour
 * @param items Les items du calendrier
 * @param groups Les groupes du calendrier
 * @returns Les données du calendrier
 */
const handleItemUpdate = (updatedItem: any, { items, groups }: SchedulerData) => {
  return {
    items: items.map((item: any) => (item.id === updatedItem.id ? Object.assign({}, updatedItem) : item)),
    groups,
  };
};

/**
 * Gère le redimensionnement d'un item
 * @param itemId L'id de l'item
 * @param time Date à laquelle l'item a été redimensionné
 * @param edge Côté de l'item qui a été redimensionné (left ou right)
 * @param data Les données du calendrier
 * @returns Les données du calendrier
 */
const HandleItemResize = (itemId: any, time: any, edge: any, data: SchedulerData): SchedulerData => {
  const { items, groups } = data;
  return {
    items: items.map((item: any) =>
      item.id === itemId
        ? Object.assign({}, item, {
            start_time: edge === 'left' ? time : item.start_time,
            end_time: edge === 'left' ? item.end_time : time,
          })
        : item,
    ),
    groups,
  };
};

interface GroupRendererProps {
  // Les données du groupe
  groupItem: any;
  // Fonction appelée lors d'un double click ou d'un click droit sur le groupe
  onContextMenu: any;
  // Fonction appelée lorsque la souris commence à survoler sur le groupe
  onMouseEnter: any;
  // Fonction appelée lorsque la souris sort de la zone du groupe
  onMouseLeave: any;
  // Indique si le groupe est cliquable
  isGroupClickable: boolean;
}

/**
 * Gestion du rendu d'un groupe
 * @param groupItem Les données du groupe
 * @param onMouseLeave Fonction appelée lorsque la souris sort de la zone du groupe
 * @param onMouseEnter Fonction appelée lorsque la souris commence à survoler sur le groupe
 * @param onContextMenu Fonction appelée lors d'un double click ou d'un click droit sur le groupe
 * @param isGroupClickable Indique si le groupe est cliquable
 * @returns Le rendu du groupe
 */
const GroupRenderer: FC<GroupRendererProps> = ({
  groupItem,
  onMouseLeave,
  onMouseEnter,
  onContextMenu,
  isGroupClickable,
}) => {
  const { group } = groupItem;
  const ref = useRef(null);
  const handleRightClick = (e: any) => {
    e.preventDefault();
    onContextMenu(e, group, 'groupRightClick');
  };
  const style = isGroupClickable ? { cursor: 'pointer' } : undefined;
  return (
    <div
      style={style}
      ref={ref}
      className="custom-group"
      onMouseEnter={() => onMouseEnter(group, ref.current, true)}
      onMouseLeave={onMouseLeave}
      onDoubleClick={(e) => onContextMenu(e, group, 'groupDoubleClick')}
      onContextMenu={handleRightClick}
    >
      <span className="title">{group?.title}</span>
    </div>
  );
};

interface ItemRendererProps {
  // Les données de l'item avec toutes les fonctions de rendu et d'état
  fullItem: ReactCalendarItemRendererProps<any>;
  // Fonction appelée lors d'un double click ou d'un click droit sur l'item
  onContextMenu: any;
  // Fonction appelée lorsque la souris commence à survoler sur l'item
  onMouseEnter: any;
  // Fonction appelée lorsque la souris sort de la zone de l'item
  onMouseLeave: any;
}

/**
 * Gestion du rendu d'un item
 * @param fullItem Les données de l'item avec toutes les fonctions de rendu et d'état
 * @param onContextMenu Fonction appelée lors d'un double click ou d'un click droit sur l'item
 * @param onMouseEnter Fonction appelée lorsque la souris commence à survoler sur l'item
 * @param onMouseLeave Fonction appelée lorsque la souris sort de la zone de l'item
 * @returns Le rendu de l'item
 */
const ItemRenderer: FC<ItemRendererProps> = ({ fullItem, onContextMenu, onMouseEnter, onMouseLeave }) => {
  const { item, itemContext, getItemProps }: any = fullItem;
  const { left: leftResizeProps, right: rightResizeProps } = fullItem.getResizeProps();
  const ref = useRef(null);

  return (
    <div
      {...getItemProps({
        className: itemContext.selected ? 'selected' : '',
        title: '',
        style: {
          border: '2px solid',
          background: item.color,
          color: Utils.isHexColorDark(item.color) ? '#EEE' : '#111',
          borderColor: item.color,
          borderRadius: 20,
        },
        onDoubleClick: (e: React.MouseEvent<HTMLButtonElement>) => onContextMenu(e, item, 'doubleClick'),
        onContextMenu: (e: React.MouseEvent<HTMLButtonElement>) => onContextMenu(e, item, 'rightClick'),
      })}
    >
      {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : null}
      <div
        title=""
        ref={ref}
        className={'rct-item-title test-test-' + item.id}
        onMouseEnter={() => onMouseEnter(item, ref.current)}
        onMouseLeave={onMouseLeave}
      >
        {itemContext.title}
      </div>
      {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : null}
    </div>
  );
};

interface PopoverMenuProps {
  // L'élément html sur lequel le menu est attaché
  anchorEl: HTMLElement | null;
  // Indique si le menu est ouvert ou non
  open: boolean;
  // Les éléments à afficher dans le menu
  children: ReactNode;
  // La position du menu par rapport à l'élément html
  placement?: PopperPlacementType;
}

/**
 * Gestion du rendu du popper (menu afficher au survol de la souris)
 * @param anchorEl L'élément html sur lequel le menu est attaché
 * @param open Indique si le menu est ouvert ou non
 * @param placement La position du menu par rapport à l'élément html
 * @param children Les éléments à afficher dans le menu
 * @returns Le rendu du menu
 */
const PopperMenu: FC<PopoverMenuProps> = ({ anchorEl, open, placement, children }) => {
  return (
    <Popper id="mouse-over-popover" placement={placement} open={open} anchorEl={anchorEl}>
      <Card className="rct-item-card">{children}</Card>
    </Popper>
  );
};

interface ContextualMenuProps {
  // Liste des appels à l'api, contient également plusieurs options de configuration tell que les options du menu contextuel
  schedulerFetch: SchedulerFetch[];
  // Les données du calendrier
  state: InitialStateProps;
  // Fonction appelée lorsque le menu contextuel est fermé
  handleClose: React.Dispatch<any>;
  // Les données de l'item sur lequel le menu contextuel est ouvert
  currentItem?: any;
  // Les données du groupe sur lequel le menu contextuel est ouvert
  currentGroup?: any;
  // Fonction appelée lorsque l'on clique sur une option du menu contextuel
  updateItem?: any;
}

/**
 * Gestion du rendu du menu contextuel
 * @param schedulerFetch Liste des appels à l'api, contient également plusieurs options de configuration tell que les options du menu contextuel
 * @param state Les données du calendrier
 * @param handleClose Fonction appelée lorsque le menu contextuel est fermé
 * @param currentItem Les données de l'item sur lequel le menu contextuel est ouvert
 * @param currentGroup Les données du groupe sur lequel le menu contextuel est ouvert
 * @param updateItem Fonction appelée lorsque l'on clique sur une option du menu contextuel
 * @returns Le rendu du menu contextuel
 */
const ContextualMenu: FC<ContextualMenuProps> = ({
  schedulerFetch,
  state,
  handleClose,
  currentItem,
  currentGroup,
  updateItem,
}) => {
  if (!currentItem && !currentGroup) return <></>;
  const isContextOpen = !!state.anchorEl && (state.type === 'rightClick' || state.type === 'groupRightClick');
  if (!isContextOpen) return <></>;
  const isGroupClick = state.type === 'groupRightClick';
  let optionList =
    schedulerFetch
      .find((x) => !!x.type && x.type.indexOf(isGroupClick ? currentGroup?.type : currentItem?.type) !== -1)
      ?.contextOptionList?.filter((x) => !!x.onClick && !!x.isGroupClick === isGroupClick) ?? [];
  optionList = optionList.filter((x) => !x.if || x.if(isGroupClick ? currentGroup : currentItem));
  return (
    <Menu
      id="contextual-menu"
      anchorEl={state.anchorEl}
      open={isContextOpen && optionList.length > 0}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      {optionList.map((option, key) => (
        <MenuItem
          key={key}
          onMouseDown={(event: any) => {
            option.onClick({
              item: currentItem,
              group: currentGroup,
              isMiddleClick: event?.button === 1,
              updateItem,
            });
          }}
        >
          {option.label}
        </MenuItem>
      ))}
    </Menu>
  );
};

/**
 * Gestion programmatique des filtres, c'est l'opposé de composant d'affichage des filtres
 */
export const SchedulerFilterFunction = {
  string: (rows: any[], accessor: string, text: string) => {
    if (!text) return rows;
    return rows.filter((x: any) => x?.value[accessor]?.toLowerCase().includes(text.toLowerCase()));
  },
  number: (rows: any[], accessor: string, number: number) => {
    if (number === undefined || number === null) return rows;
    return rows.filter((x: any) => x?.value[accessor] === +number);
  },
  AutoMultiselect: (rows: any[], accessor: string, list: any[]) => {
    if (!list || !Array.isArray(list) || list.length === 0) return rows;
    return rows.filter((x: any) => list.indexOf(x?.value[accessor]) !== -1);
  },
  boolean: (rows: any[], accessor: string, bool: boolean) => {
    if (bool === undefined || bool === null) return rows;
    return rows.filter((x: any) => x?.value[accessor] === bool);
  },
  trueOnly: (rows: any[], accessor: string, bool: boolean) => {
    return !bool ? rows : rows.filter((x: any) => x?.value[accessor] === true);
  },
  lessOrEqual: (rows: any[], accessor: string, filterValue: any) => {
    if (!filterValue) return rows;
    return rows.filter((row: any) => row.values[accessor] <= filterValue && row.values[accessor] !== 0);
  },
};

/**
 * Méthode permettant de comparer deux listes de filtres, si les filtres sont égaux, la méthode retourne true
 * @param firstList Liste de filtres
 * @param secondList Liste de filtres
 * @param backendOnly Si true, seuls les filtres backend sont comparés
 * @returns True si les filtres sont égaux, false sinon
 */
const SchedulerFiltersAreEquals = (
  firstList: Array<SchedulerFilter>,
  secondList: Array<SchedulerFilter>,
  backendOnly: boolean = false,
): boolean => {
  const first = !backendOnly ? firstList : firstList.filter((x) => x.isBackendFilter);
  const second = !backendOnly ? secondList : secondList.filter((x) => x.isBackendFilter);
  return first.every((x) => !!second.find((y) => y.accessor === x.accessor && y.value === x.value));
};

/**
 * Filtres par défaut, il permet de gérer les filtres de type string, number, date, boolean, etc...
 * Si vous souhaitez ajouter un nouveau type de filtre, il suffit de créer une nouvelle méthode dans ce composant
 * Les filtres customisé surchargent toujours les filtres par défaut
 */
const DefaultFilters = {
  date: ({ filter, setFilter }: SchedulerFilterProps) => (
    <UI.FormField>
      <UI.Label label={filter.title} name={filter.accessor} />
      <InputUI.DatePickerVanilla notNullable value={filter.value} onChange={setFilter} />
    </UI.FormField>
  ),
  string: ({ filter, setFilter }: SchedulerFilterProps) => (
    <UI.FormField>
      <UI.Label label={filter.title} name={filter.accessor} />
      <InputUI.DebouncedInput onChange={setFilter} value={filter.value} />
    </UI.FormField>
  ),
  number: ({ filter, setFilter }: SchedulerFilterProps) => (
    <UI.FormField>
      <UI.Label label={filter.title} name={filter.accessor} />
      <InputUI.DebouncedInput type="number" onChange={setFilter} value={filter.value} />
    </UI.FormField>
  ),
  boolean: ({ filter, setFilter }: SchedulerFilterProps) => (
    <UI.FormField>
      <InputUI.CmsSwitch value={filter.value} label={filter.title} onChange={setFilter} />
    </UI.FormField>
  ),
  trueOnly: ({ filter, setFilter }: SchedulerFilterProps) => (
    <UI.FormField>
      <InputUI.CmsSwitch value={filter.value} label={filter.title} onChange={setFilter} />
    </UI.FormField>
  ),
  AutoMultiselect: ({ filter, setFilter, data }: SchedulerFilterProps) => {
    const options = useMemo(() => {
      if (!data?.items) return [];
      const result: IdLabel[] = [];
      for (const item of data.items) {
        const val = item?.value[filter.accessor];
        if (!!val && !result.find((x) => x.id === val)) result.push({ id: val, label: val });
      }
      return result;
    }, [data, filter]);
    if (!data?.items) return <></>;
    return (
      <UI.FormField>
        <UI.Label label={filter.title} name={filter.accessor} />
        <InputUI.AutoCompletor
          style={{ marginBottom: 0 }}
          value={isNaN(filter.value) ? filter.value : +filter.value}
          onChange={setFilter}
          options={options}
          multiple
        />
      </UI.FormField>
    );
  },
};

/**
 * Retourne la valeur des filtres du calendrier dans la forme {accessor : value}.
 * Utilisé pour remonter les filtres vers le parent pour les synchroniser avec les filtres du tableau par exemple
 * @param list la liste des filtres du calendrier
 * @returns un objet {accessor : value} contenant les valeurs des filtres
 */
export function getSchedulerFilterValues(list: SchedulerFilter[]): any {
  const result: any = {};
  for (const item of list) result[item.accessor] = item.value;
  return result;
}

export interface CaptionProps {
  // Libellé de la légende
  label: any;
  // Est-ce que la légende est visible
  enabled?: boolean;
  // Type de la légende (le même que le type de fetch du calendrier), par exemple 'task' ou 'absence'
  type: string;
  // Couleur de la légende
  color: string;
}

interface CaptionListProps {
  // Liste des légendes
  captionList?: CaptionProps[];
  // Méthode appelée lors du clic sur une légende
  onCaptionClick: any;
}

/**
 * Composant permettant d'afficher la légende du calendrier
 * @param captionList Liste des légendes
 * @param onCaptionClick Méthode appelée lors du clic sur une légende
 */
const Caption: FC<CaptionListProps> = ({ captionList, onCaptionClick }) => {
  if (!captionList || captionList.length === 0) return <></>;
  return (
    <div className="flex-h item-center">
      <span>Légende couleur:</span>
      {captionList.map((caption, i) => (
        <UI.WarningBubble
          key={'warning-bubble' + i}
          style={{ opacity: caption.enabled ? 1 : 0.3 }}
          customColor={caption.color}
          onClick={() => onCaptionClick(caption.type)}
        >
          {caption.label}
        </UI.WarningBubble>
      ))}
    </div>
  );
};

/**
 * Génère les dates de début et de fin de la plage de temps visible par défaut,
 * peut être surchargé par les filtres de type date (isStartFilter et isEndFilter)
 * @param filters Liste des filtres du calendrier
 */
function getInitialVisibleSpan(filters: SchedulerFilter[]): TimeHandling {
  const init = {
    visibleTimeStart: new Date().getTime(),
    visibleTimeEnd: Utils.Date.addDays(new Date(), 30).getTime(),
  };
  if (!filters || filters.length === 0) return init;
  const start = filters.find((x) => x.isStartFilter);
  const end = filters.find((x) => x.isEndFilter);
  if (start) init.visibleTimeStart = new Date(start.value).getTime();
  if (end) init.visibleTimeEnd = new Date(end.value).getTime();
  return init;
}
