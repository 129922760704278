import React, { CSSProperties, FC, ReactNode, useContext, useMemo } from 'react';
import { FormProvider, useForm, useFormContext, UseFormReturn } from 'react-hook-form';
import { CmsButton, CmsDialog, CmsDivider, CmsPaper } from '../shared/Ui';
import CRUD from '../../service/CRUD.service';
import './../shared/shared.scss';
import notificationService from '../../service/NotificationService';
import { useBlocker, useLocation, useNavigate } from 'react-router-dom';
import { ArrowBackNavigationButton } from '../shared/Buttons';
import { Buttons, UI } from '../shared';
import { GlobalContext } from '../../context/Global.context';
import { DialogUI } from '../shared/DialogUI';
import Skeleton from '@mui/material/Skeleton';
import LoadingScreen from '../LoadingScreen';
import { CmsColumnDef, CmsFrontendTable } from '../table/CmsTable';

//#region Form

interface CmsFormProps {
  children?: any;
  currentUrl: string;
  defaultValues?: any;
  mode?: 'onTouched' | 'onChange' | 'onSubmit';
  id?: string | number;
  title?: string;
  actions?: any;
  route: string;
  paperStyle?: CSSProperties;
  goToOnValidForm?: (id: any, returnToList?: boolean) => string;
  noReturn?: boolean;
  goBackTo?: string | 'disable';
  goBackUseHref?: boolean;
  onChange?: (data: any, reset: any) => void;
  onBeforeSubmit?: (data: any) => any;
  onSubmit?: (data: any, form: UseFormReturn, isRedirect?: boolean) => Promise<any | void>;
  renderForm?: (form: UseFormReturn, submit: (x: any) => void) => ReactNode;
  sendAsFormData?: boolean;
  onGetEditData?: (data: any) => any;
  formSize?: { col?: number; row?: number };
  thin?: boolean;
  onDelete?: (id: number | string) => void;
  deletable?: boolean;
  duplicateHandler?: DuplicateHandler;
  onCancel?: () => void;
}

interface DuplicateHandler {
  columns: CmsColumnDef<any>[];
  navigateTo?: (id: number) => string;
}

interface CmsFormContextProps {
  onSubmit: (data: any, isRedirect?: boolean) => void;
  onDelete?: () => void;
  deletable?: boolean;
  returnToList?: string;
}

export const CmsFormContext = React.createContext<CmsFormContextProps>({
  onSubmit: (data: any, isRedirect?: boolean) => {},
  onDelete: () => {},
  deletable: false,
  returnToList: undefined,
});

/**
 * Formulaire CMS
 * @param id Identifiant de l'élément à éditer
 * @param children Contenu du formulaire
 * @param defaultValues Valeurs par défaut du formulaire
 * @param currentUrl Url courante
 * @param mode Mode de gestion du formulaire (onTouched, onChange, onSubmit)
 * @param title Titre du formulaire
 * @param route Route de l'api
 * @param actions Actions à afficher en haut à droite du formulaire
 * @param paperStyle Style du formulaire
 * @param noReturn Défini si le bouton de retour est affiché ou non
 * @param goToOnValidForm Url vers laquelle rediriger l'utilisateur après validation du formulaire
 * @param goBackTo Url vers laquelle rediriger l'utilisateur après annulation du formulaire
 * @param goBackUseHref Indique que le lien du goBackTo doit être utilisé en href (page v1) et non passer par le react router
 * @param onChange Callback à appeler à chaque changement de valeur du formulaire
 * @param onBeforeSubmit Callback à appeler avant la soumission du formulaire
 * @param sendAsFormData Défini si le formulaire doit être envoyé sous forme de FormData
 * @param onGetEditData Callback à appeler pour récupérer les données d'édition
 * @param onSubmit Callback à appeler à la soumission du formulaire
 * @param renderForm Callback à appeler pour afficher le formulaire
 * @param formSize Nombre de colonnes et de lignes du formulaire
 * @param thin Défini si le formulaire doit être affiché en version "thin" (maxwidth :600px)
 * @param onDelete Callback à appeler à la suppression de l'élément
 * @param deletable Défini si l'élément est supprimable ou non
 * @param duplicateHandler Configuration pour la gestion des doublons (colonnes à afficher dans le tableau + lien url)
 * @param onCancel Callback à appeler à l'annulation du formulaire
 */
export const CmsForm: FC<CmsFormProps> = ({
  id,
  children,
  defaultValues,
  currentUrl,
  mode = 'onChange',
  title,
  route,
  actions,
  paperStyle,
  noReturn = false,
  goToOnValidForm,
  goBackTo,
  goBackUseHref = false,
  onChange,
  onBeforeSubmit,
  sendAsFormData,
  onGetEditData = (x: any) => x,
  onSubmit,
  renderForm,
  formSize,
  thin = false,
  onDelete,
  deletable = false,
  duplicateHandler,
  onCancel,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { payload, setPayload } = useContext(GlobalContext);
  const [firstRender, setFirstRender] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(!!id);
  const [isSending, setIsSending] = React.useState(false);
  const [duplicateList, setDuplicateList] = React.useState<any[]>();
  const form = useForm<any>({ mode, defaultValues });
  if (onChange) onChange(form.watch(), form.reset);
  const withPaper = !!title || !!actions;
  const type: any = useMemo(() => (id ? 'editForm' : 'createForm'), [id]);
  const returnToList = goBackTo === 'disable' ? undefined : (goBackTo ?? `${currentUrl}list`);

  if (firstRender && !!id) {
    setFirstRender(false);
    CRUD.getById<any>(route, id).then((x) => {
      form.reset(onGetEditData(x));
      setIsLoading(false);
    });
  } else if (firstRender) {
    setFirstRender(false);
    if (!!payload && payload.to === location.pathname) {
      form.reset({ ...defaultValues, ...payload.data });
      setPayload(undefined);
    }
  }

  const onDefaultSubmit = (data: any, isRedirect?: boolean) => {
    const processedData = onBeforeSubmit ? onBeforeSubmit(data) : data;
    if (!processedData) return;
    if (typeof processedData === 'string') return notificationService.error(processedData);
    setIsSending(true);
    if (onSubmit) {
      return onSubmit(processedData, form, isRedirect)
        .then((result: any) => {
          form.reset(onGetEditData(result ?? data));
        })
        .finally(() => setIsSending(false));
    }
    (sendAsFormData ? CRUD.postFormData : CRUD.post)(route, processedData, !!id)
      .then((result: any) => {
        if (result.duplicateFound) return setDuplicateList(result.duplicateList);
        form.reset(onGetEditData(result));
        notificationService.success(id ? 'Édition réussie' : 'Création réussie');
        let goTo = `${currentUrl}${!isRedirect ? result.id + '/edit' : 'list'}`;
        if (goToOnValidForm) goTo = goToOnValidForm(result.id, isRedirect === true);
        goTo.startsWith('http') ? (window.location.href = goTo) : navigate(goTo);
      })
      .catch((e) => {
        if (e.message !== '400 : Doublon détecté.') return;
        const message = 'Aucune configuration de doublon trouvée, veuillez contacter un administrateur';
        if (!duplicateHandler) return notificationService.error(message);
        CRUD.post<any>(route + '/duplicates', processedData).then(setDuplicateList);
      })
      .finally(() => setIsSending(false));
  };

  const onDefaultDelete = () => {
    if (!id || !deletable) return;
    if (onDelete) return onDelete(id);
    CRUD.deleteById(route, id).then(() => {
      notificationService.warning('Élément supprimé avec succès');
      const goTo = `${currentUrl}list`;
      goTo.startsWith('http') ? (window.location.href = goTo) : navigate(goTo);
    });
  };

  if (!noReturn)
    actions = [
      ...(actions ?? []),
      goBackUseHref ? (
        <ArrowBackNavigationButton title="Retourner à la liste" href={returnToList} />
      ) : (
        <ArrowBackNavigationButton title="Retourner à la liste" to={returnToList} />
      ),
    ];

  let paperProps: any = { paperStyle, title: (id ? 'Éditer ' : 'Créer ') + title, actions, contentType: type, thin };
  paperProps = { ...paperProps, isLoading, ...formSize, onCancel, noReturn };
  let child = !withPaper ? children : <CmsFormPaper {...paperProps}>{children}</CmsFormPaper>;
  if (renderForm) {
    if (withPaper) child = <CmsFormPaper {...paperProps}>{renderForm(form, onDefaultSubmit)}</CmsFormPaper>;
    else child = isLoading ? <LoadingScreen /> : renderForm(form, onDefaultSubmit);
  }

  const provide = { onSubmit: onDefaultSubmit, returnToList, onDelete: onDefaultDelete, deletable };
  return (
    <FormProvider {...form}>
      <CmsFormContext.Provider value={provide}>
        {isSending ? <LoadingScreen over fullScreen /> : <FormPrompt hasUnsavedChanges={form.formState.isDirty} />}
        <form onSubmit={form.handleSubmit((x) => onDefaultSubmit(x))}>{child}</form>
        <CmsFormDuplicateModal
          {...{ duplicateList, setDuplicateList, duplicateHandler }}
          onValidate={() => onDefaultSubmit({ ...form.getValues(), duplicateHaveBeenChecked: true }, false)}
        />
      </CmsFormContext.Provider>
    </FormProvider>
  );
};

interface CmsFormPaperProps {
  title?: string | ReactNode;
  actions?: any[];
  children: any;
  paperStyle?: CSSProperties;
  contentType?: 'createForm' | 'editForm';
  isLoading?: boolean;
  row?: number;
  col?: number;
  thin?: boolean;
  onCancel?: () => void;
  noReturn?: boolean;
}

/**
 * Paper contenant un formulaire
 * @param title Titre du formulaire
 * @param paperStyle Style du formulaire
 * @param actions Actions à afficher en haut à droite du formulaire
 * @param children Contenu du formulaire
 * @param contentType Type de formulaire (createForm, editForm)
 * @param isLoading Défini si le formulaire est en chargement
 * @param row Nombre de lignes du formulaire
 * @param col Nombre de colonnes du formulaire
 * @param thin Défini si le formulaire doit être affiché en version "thin" (maxwidth :600px)
 * @param onCancel Callback à appeler à l'annulation du formulaire
 * @param noReturn Défini si le bouton "Créer et partir" est affiché ou non
 */
export const CmsFormPaper: FC<CmsFormPaperProps> = ({
  title,
  paperStyle,
  actions,
  children,
  contentType,
  isLoading = false,
  row = 1,
  col = 1,
  thin = false,
  onCancel,
  noReturn,
}) => {
  if (thin) paperStyle = { ...paperStyle, maxWidth: '600px' };
  if (row === 1 && children.length > 1) row = children.length;
  if (row === 1 && (children.props.children?.length ?? 0) > 1) row = children.props.children.length;
  if (col === 1 && children.props?.style?.gridTemplateColumns)
    col = children.props.style.gridTemplateColumns.split(' ').length;
  if (col === 1 && children?.props?.container && children.props.children?.length > 1) {
    col = children.props.children.length;
    row = children.props.children.reduce((x: any, y: any) => {
      const perCol = y.props.children?.length ?? 0;
      return x > perCol ? x : perCol;
    }, 0);
  }
  return (
    <CmsPaper contentType={contentType} title={title} actions={actions} style={paperStyle}>
      <FormSkeleton isLoading={isLoading} fieldNumber={row} columnNumber={col}>
        {children}
      </FormSkeleton>
      <CmsFormSubmit type={contentType} onCancel={onCancel} noReturn={noReturn} />
    </CmsPaper>
  );
};

interface FormProps {
  // Contenu du formulaire, enfant à afficher
  children: any;
  // Si le formulaire est en chargement, affiche un skeleton
  isLoading: boolean;
  // Nombre de champs à afficher (utilisé pour le skeleton)
  fieldNumber: number;
  // Nombre de colonnes à afficher (utilisé pour le skeleton)
  columnNumber?: number;
  className?: string;
}

/**
 * Affiche un skeleton si le formulaire est en chargement
 * @param children Contenu du formulaire
 * @param isLoading Défini si le formulaire est en chargement
 * @param fieldNumber Nombre de champs à afficher (utilisé pour le skeleton)
 * @param columnNumber Nombre de colonnes à afficher (utilisé pour le skeleton)
 * @param className Classe à ajouter au formulaire
 */
export const FormSkeleton: FC<FormProps> = ({ children, isLoading, fieldNumber, columnNumber = 1, className }) => {
  if (!isLoading) return <div className={className}>{children}</div>;
  return (
    <div className={'skeleton-container ' + className}>
      {[...Array(columnNumber)].map((_, i) => (
        <span key={i} style={{ width: Math.floor(100 / columnNumber) + '%' }}>
          {[...Array(fieldNumber)].map((_, idx) => (
            <UI.FormField key={idx}>
              <Skeleton style={{ width: '15%', height: '20px' }} />
              <Skeleton style={{ width: '100%', height: '40px' }} />
            </UI.FormField>
          ))}
        </span>
      ))}
    </div>
  );
};

interface CmsFormValidationProps {
  type?: 'createForm' | 'editForm';
  withoutDivider?: boolean;
  children?: any;
  onCancel?: () => void;
  noReturn?: boolean;
}

/**
 * Boutons de validation d'un formulaire
 * @param type Type de formulaire (createForm, editForm)
 * @param withoutDivider Défini si le séparateur doit être affiché ou non
 * @param children Contenu du formulaire
 * @param onCancel Callback à appeler à l'annulation du formulaire
 * @param noReturn Défini si le bouton "Créer et partir" est affiché ou non
 */
export const CmsFormSubmit: FC<CmsFormValidationProps> = ({ type, withoutDivider, children, onCancel, noReturn }) => {
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = React.useState(false);
  const { formState, handleSubmit } = useFormContext();
  const { onSubmit, returnToList, onDelete, deletable } = React.useContext(CmsFormContext);
  const disableSubmit = formState.isSubmitting || !formState.isValid;
  const handleReturn = () => {
    if (onCancel) return onCancel();
    if (!returnToList?.startsWith('http')) navigate(returnToList ?? '');
    else window.location.href = returnToList;
  };
  return (
    <div>
      {!withoutDivider && <CmsDivider />}
      <div className="cms-form-submit">
        {children}
        {type === 'editForm' && deletable && (
          <CmsButton color="error" onClick={() => setOpenDialog(true)}>
            Supprimer
          </CmsButton>
        )}
        {returnToList && (
          <CmsButton color="inherit" onClick={handleReturn}>
            Abandonner
          </CmsButton>
        )}
        {!noReturn && (
          <CmsButton disabled={disableSubmit} onClick={handleSubmit((x) => onSubmit(x, true))}>
            {(type === 'editForm' ? 'Valider' : 'Créer') + ' et partir'}
          </CmsButton>
        )}
        <CmsButton disabled={disableSubmit} onClick={handleSubmit((x) => onSubmit(x))}>
          {type === 'editForm' ? 'Valider' : 'Créer'}
        </CmsButton>
      </div>
      <DialogUI open={openDialog} onClose={setOpenDialog} onValidation={() => onDelete && onDelete()} title="Supprimer">
        Êtes-vous sûr de vouloir supprimer cet élément ? cette action est irréversible.
      </DialogUI>
    </div>
  );
};

export const FormPrompt: FC<{ hasUnsavedChanges: boolean }> = ({ hasUnsavedChanges }) => {
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) => hasUnsavedChanges && currentLocation.pathname !== nextLocation.pathname,
  );
  if (blocker.state !== 'blocked') return <></>;
  return (
    <UI.Dialog open={true} title="Attention" onClose={() => {}}>
      <CmsPaper title="Modification en cours" style={{ marginBottom: 0 }}>
        <p>Souhaitez-vous tout de même quitter cette page</p>
        <p>et perdre les modifications effectuées ?</p>
        <UI.Divider />
        <UI.ButtonHolder>
          <UI.Button color="error" onClick={() => !!blocker.reset && blocker.reset()}>
            Non
          </UI.Button>
          <UI.Button color="primary" onClick={() => !!blocker.proceed && blocker.proceed()}>
            Oui
          </UI.Button>
        </UI.ButtonHolder>
      </CmsPaper>
    </UI.Dialog>
  );
};

interface CmsFormDuplicateModalProps {
  onValidate: (data: any) => void;
  duplicateList?: any[];
  setDuplicateList: (data: any) => void;
  duplicateHandler?: DuplicateHandler;
}

const CmsFormDuplicateModal: FC<CmsFormDuplicateModalProps> = ({
  duplicateList,
  duplicateHandler,
  onValidate,
  setDuplicateList,
}) => {
  const colWithoutFilter = duplicateHandler?.columns.map((x) => ({ ...x, defaultFilterValue: undefined }));
  return (
    <CmsDialog maxWidth="xl" open={!!duplicateList}>
      <CmsFrontendTable
        title="Liste des doublons potentiel"
        paperStyle={{ marginBottom: 0 }}
        columns={colWithoutFilter ?? []}
        invertClick
        navigateTo={duplicateHandler?.navigateTo}
        headerComponent={
          <>
            Attention, il existe déjà un ou plusieurs éléments similaires dans la base de données. Vérifiez les
            informations ci-dessous, si vous êtes certain que cela n'est pas un doublon, vous pouvez valider sinon,
            veuillez bien vouloir annuler.
          </>
        }
        route="none"
        controlledState={{ state: duplicateList ?? [], setState: () => {} }}
        actions={[
          <Buttons.Cancel onClick={() => setDuplicateList(undefined)}>Annuler</Buttons.Cancel>,
          <Buttons.Valid onClick={onValidate}>Valider quand même</Buttons.Valid>,
        ]}
      />
    </CmsDialog>
  );
};
