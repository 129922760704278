import React, { FC, useEffect, useState } from 'react';
import { CmsForm } from '../../component/form/CmsForm';
import { CmsFormInput } from 'component/form/CmsFormInput';
import APIRoute from '../../constant/API.constant';
import './user.scss';
import { Buttons, FormUI, InputUI } from '../../component/shared';
import { API_URL, GOOGLE_API_KEY } from '../../constant/API_URL';
import { uploadFile } from '../../helper/handle-request';
import NotificationService from '../../service/NotificationService';
import CRUD from '../../service/CRUD.service';
import homme from '../../style/assets/avatar-homme.svg';
import femme from '../../style/assets/avatar-femme.svg';
import { CmsButton, CmsPaper } from '../../component/shared/Ui';
import { useFormContext } from 'react-hook-form';
import { IdLabel } from '../../interface/CommonType';
import { EmployeeStatusList, OccupationalMedicineList, UserPackageStatus } from './User.pack';
import { SfRisk, SfRiskUser } from '../../interface/SfType';
import { UsUsers } from '../../interface/UsType';
import { DialogVanillaUI } from '../../component/shared/DialogUI';
import CmsIcon from '../../component/shared/CmsIcon';
import { ViewNavigationButton } from '../../component/shared/Buttons';
import LoadingScreen from '../../component/LoadingScreen';
import { useNavigate } from 'react-router-dom';
import { checkStatus, handleCrudErrorNotif, handleJsonResponse } from '../../helper/handle-response';

export const UsUsersCreateEdit: FC = ({ id }: any) => {
  const [sfRiskList, setSfRiskList] = useState<SfRisk[]>();
  const [openSubordinatesModal, setOpenSubordinatesModal] = useState<boolean>(false);
  const [subordinates, setSubordinates] = useState<UsUsers[]>([]);
  const navigate = useNavigate();
  const title = (!id ? 'Ajouter' : 'Éditer') + ' un utilisateur';
  const baseUrl = '/sonata/user/user/';

  useEffect(() => {
    CRUD.getList<SfRisk>('SfRisk').then(setSfRiskList);
  }, []);

  if (!sfRiskList) return <LoadingScreen />;
  return (
    <CmsForm
      id={id}
      route={APIRoute.UsUsers}
      currentUrl={baseUrl}
      onGetEditData={(user) => mapRiskListOnUser(user, sfRiskList)}
      onSubmit={(user) => {
        user.sfRiskUser = reverseMapRiskListOnUser(user, sfRiskList);
        return CRUD.post<UsUsers>(APIRoute.UsUsers, user, !!id)
          .then((result) => {
            NotificationService.success("L'utilisateur a bien été " + (id ? 'modifié' : 'créé'));
            navigate(`${baseUrl}${result.id}/show`);
          })
          .catch((e: Error) => {
            if (e.message !== '400 : Cet utilisateur a des subordonnés, vous ne pouvez donc pas le désactiver.') return;
            CRUD.getList<UsUsers>(APIRoute.UsUsers + '/Subordinates/' + user.id).then((list) => {
              setSubordinates(list);
              setOpenSubordinatesModal(true);
            });
          });
      }}
      renderForm={(form, submit) => (
        <div className="user-form">
          <FormUI.HeaderFormPanel pageId={id} title={title} form={form} baseUrl={baseUrl} onSubmit={submit} />
          <div className="forms">
            <UserPic />
            <UserAuthForm />
            <UserAdminInfoForm />
            <UserContractForm />
            <Sir riskList={sfRiskList} />
            <SiaOrSig riskList={sfRiskList} type="SIA" />
            <SiaOrSig riskList={sfRiskList} type="SIG" />
          </div>
          <FormUI.HeaderFormPanel pageId={id} title={title} form={form} baseUrl={baseUrl} onSubmit={submit} />
          <DialogAlertSubordinates
            open={openSubordinatesModal}
            subordinates={subordinates}
            handleClose={() => setOpenSubordinatesModal(false)}
            baseUrl={baseUrl}
          />
        </div>
      )}
    ></CmsForm>
  );
};

function mapRiskListOnUser(user: UsUsers, sfRiskList: SfRisk[]) {
  for (const risk of sfRiskList) {
    user = { ...user, [risk.code]: !!user.sfRiskUser?.find((r) => r.code === risk.code) };
    if (!risk.needComment) continue;
    user = { ...user, [risk.code + 'Comment']: user.sfRiskUser?.find((r) => r.code === risk.code)?.comment };
  }
  return user;
}

function reverseMapRiskListOnUser(user: UsUsers, sfRiskList: SfRisk[]): SfRiskUser[] {
  if (!user.id) return [];
  const riskList: SfRiskUser[] = [];
  for (const risk of sfRiskList) if (user[risk.code]) riskList.push({ userId: +user.id, riskId: +risk.id });
  for (const risk of sfRiskList)
    if (user[risk.code + 'Comment'] && riskList.find((x) => x.riskId === risk.id))
      riskList.find((x) => x.riskId === risk.id)!.comment = user[risk.code + 'Comment'];
  return riskList;
}

interface DialogAlertSubordinatesProps {
  open: boolean;
  subordinates: UsUsers[];
  handleClose: any;
  baseUrl: string;
}

const DialogAlertSubordinates: FC<DialogAlertSubordinatesProps> = ({ open, subordinates, handleClose, baseUrl }) => {
  return (
    <DialogVanillaUI open={open} onClose={handleClose} maxWidth={'sm'}>
      <CmsPaper
        title="Impossible de désactiver l'utilisateur"
        style={{ marginBottom: '0' }}
        actions={[<CmsIcon icon={'close'} onClick={handleClose} />]}
      >
        <h3>Impossible de désactiver l'utilisateur car il est manager des subalternes suivant :</h3>
        <ul style={{ listStyleType: 'none', padding: '20px 10px' }}>
          {subordinates.map((sub: UsUsers) => (
            <li style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0.2rem' }}>
              <span>{sub.getFormattedName}</span>
              <ViewNavigationButton title="Voir" to={`${baseUrl}${sub.id}/show`} forceOpenNewTab={true} />
            </li>
          ))}
        </ul>
        <CmsButton onClick={handleClose} size={'medium'}>
          Confirmer
        </CmsButton>
      </CmsPaper>
    </DialogVanillaUI>
  );
};

/**
 * Photo de l'utilisateur
 */
const UserPic: FC = () => {
  const form = useFormContext();
  const { id, gender } = form.watch();
  const [file, setFile] = useState<File | null>(null);
  const isCreate = !id;
  const handleUploadClick = () => {
    if (!file || !id) return;
    uploadFile({ url: `${API_URL}UsUsers/${id}/Pic`, file })
      .then(() => {
        NotificationService.success('Photo mis à jour');
        setFile(null);
        CRUD.getBlob(`${APIRoute.UsUsers}/${id}/Pic`).then(setUserPic);
      })
      .catch(() => {
        NotificationService.error("le fichier n'est pas un jpg ou un png");
      });
  };

  const [userPic, setUserPic] = useState<Blob | null>();

  useEffect(() => {
    if (!id) return;
    CRUD.getBlob(`${APIRoute.UsUsers}/${id}/Pic`, null, null, true).then(setUserPic);
  }, [id]);

  let imageUrl: string;
  const havePic = !!userPic && userPic.size > 0;
  if (havePic) {
    const urlCreator = window.URL || window.webkitURL;
    imageUrl = urlCreator.createObjectURL(userPic);
  } else imageUrl = gender === 'm' ? homme : femme;
  const className = havePic ? '' : ' holder';
  const title = isCreate ? "Photo (vous devez créer l'utilisateur d'abord)" : 'Photo';

  return (
    <CmsPaper className="pic-form" title={title}>
      <div className={'user-icon' + className} style={{ backgroundImage: `url(${imageUrl})` }} />
      <div className="pic-buttons">
        <InputUI.InputFile disabled={isCreate} name="user-pic" id="input-file" onFileSelected={setFile} image />
        <Buttons.Valid disabled={!file || isCreate} onClick={handleUploadClick}>
          Modifier la photo
        </Buttons.Valid>
      </div>
    </CmsPaper>
  );
};

/**
 * Formulaire regroupant les informations d'authentification
 */
const UserAuthForm: FC = () => {
  const user = useFormContext().watch();
  const handleUnlock = () => {
    CRUD.put<any>(APIRoute.UsUsers + '/UnlockUser', { id: user?.id }).then(() => {
      window.location.reload();
    });
  };

  let action = [<Buttons.Valid onClick={handleUnlock}>Débloquer l'utilisateur</Buttons.Valid>];
  if (!user?.locked && (user?.errorLogin ?? 0) <= 5) action = [];
  return (
    <CmsPaper className="account-form" title="Information de compte" actions={action}>
      <div className="double-form-container">
        <div className="form-col1">
          <CmsFormInput.Text id="username" label="Identifiant" required />
          <CmsFormInput.Text id="email" label="Email professionnel" required max={100} />
          <CmsFormInput.Switch id="enabled" label="Actif:" />
        </div>
        <div className="form-col2">
          <CmsFormInput.Select id="groupList" label="Groupes" options={APIRoute.UsGroups + '/Simplified'} multiple />
        </div>
      </div>
    </CmsPaper>
  );
};

const genderOptions = [
  { id: 'm', label: 'Homme' },
  { id: 'f', label: 'Femme' },
];

const UserAdminInfoForm: FC = () => {
  const formContext = useFormContext();
  const data = formContext.watch();
  const handleCheckAddress = () => {
    const url = 'https://addressvalidation.googleapis.com/v1:validateAddress?key=' + GOOGLE_API_KEY;
    const address = { regionCode: 'FR', addressLines: [data.address, data.postcode, data.city] };
    fetch(url, { method: 'POST', body: JSON.stringify({ address }) })
      .then(checkStatus)
      .then(handleJsonResponse)
      .then((data: any) => {
        if (!data?.result?.verdict) return NotificationService.error("Erreur lors de la vérification de l'adresse");
        const message = 'Adresse non valide, veuillez vérifier les informations: adresse, code postal et ville';
        if (data.result.verdict.hasUnconfirmedComponents) return NotificationService.error(message);
        NotificationService.success('Adresse valide');
      })
      .catch(handleCrudErrorNotif);
  };
  return (
    <CmsPaper
      className="admin-form"
      title="Information administrative"
      actions={[
        <CmsButton disabled={!data.address || !data.postcode || !data.city} onClick={handleCheckAddress}>
          Vérifier la validité de l'adresse
        </CmsButton>,
      ]}
    >
      <div className="double-form-container">
        <div className="form-col1">
          <CmsFormInput.Text id="firstname" label="Prénom" required max={100} />
          <CmsFormInput.Text id="lastname" label="Nom" required max={100} />
          <CmsFormInput.Text id="emailPerso" label="Email personnel" max={100} />
          <CmsFormInput.Text id="oisoId" label="Code oiso" />
          <CmsFormInput.Select id="managerId" label="Manager" options={APIRoute.UsUsers + '/Simplified'} />
          <CmsFormInput.Radio label="Sexe *" id="gender" options={genderOptions} />
          <CmsFormInput.Switch id="detached" label="Détaché" />
          <CmsFormInput.Switch id="usePersonalEmail" label="Utiliser l'email personnel pour les absences" />
          <CmsFormInput.Switch id="sendApkLog" label="Récupérer le fichier de logs du technicien" />
        </div>
        <div className="form-col2">
          <CmsFormInput.Text id="address" label="Adresse" required />
          <CmsFormInput.Text id="postcode" label="Code postal" required />
          <CmsFormInput.Text id="city" label="Ville" required />
          <CmsFormInput.Text id="phone" label="Téléphone personnel" />
          <CmsFormInput.Text id="workphone" label="Téléphone professionnel" />
          <CmsFormInput.Text id="mobile" label="Téléphone Mobile" />
          <CmsFormInput.Select
            id="licenseTypeList"
            label="Type de permis"
            options={APIRoute.SfLicenseType + '/Simplified'}
            multiple
          />
          <CmsFormInput.Select
            id="occupationalMedicine"
            label="Risque(s) au poste"
            options={OccupationalMedicineList}
          />
          <CmsFormInput.Switch id="fullTravelingPaid" label="Voyage complet payé" />
          <CmsFormInput.Switch id="haveArea" label="Zone" />
          <CmsFormInput.Switch id="halfDayPaidLeave" label="Demi-journée de congé(s) payé(s)" />
        </div>
      </div>
    </CmsPaper>
  );
};

const ContractList: IdLabel[] = [
  { id: 'CDI', label: 'CDI' },
  { id: 'CDD', label: 'CDD' },
  { id: 'Interim', label: 'Interim' },
];

const UserContractForm: FC = () => {
  return (
    <CmsPaper className="contract-form" title="Information de contrat">
      <CmsFormInput.Select id="serviceId" label="Service" options={APIRoute.SfService + '/Simplified'} required />
      <CmsFormInput.Select
        id="legalEntityId"
        label="Entité du contrat"
        options={APIRoute.ClLegalEntity + '/Simplified?addExternalRH=true'}
        required
      />
      <CmsFormInput.Select id="jobId" label="Corps d'état" options={APIRoute.WkJob + '/Simplified'} required />
      <CmsFormInput.Text id="jobTitle" label="Emploi occupé" max={255} />
      <CmsFormInput.Select id="contract" label="Type de contrat" options={ContractList} required />
      <CmsFormInput.Select id="employeeStatus" label="Statut" options={EmployeeStatusList} required />
      <CmsFormInput.Date id="startDate" label="Date d'embauche" required />
      <CmsFormInput.Date id="endDate" label="Date de fin de contrat" />
      <CmsFormInput.Select
        id="worktimeId"
        label="Temps de travail"
        options={`${APIRoute.SfWorktime}/Simplified`}
        required
      />
      <CmsFormInput.Text id="sageNumber" label="Matricule (N° Sage)" required max={100} />
      <CmsFormInput.SimpleSelect
        id="packageStatus"
        label="Forfait"
        options={UserPackageStatus}
        nullable
        optionValue="intId"
      />
      <CmsFormInput.Number id="contractualHour" label="Heures de travail hebdomadaire" required max={100} />
      <CmsFormInput.Number id="paidLeaveTotal" label="Nombre total annuel de congé payé" required />
      <CmsFormInput.Number id="rttPerYear" label="Nombre total annuel de Rtt" required />
    </CmsPaper>
  );
};

const Sir: FC<{ riskList: any[] }> = ({ riskList }) => {
  const form = useFormContext();
  if (form.watch().occupationalMedicine !== 1) return <></>;
  return (
    <CmsPaper className="sir-form" title="Suivi individuel renforcé">
      <div className="medicine-form-container">
        {[1, 2, 3].map((i) => (
          <div className={'medicine-col-' + i}>
            {(riskList?.filter((x) => x.type === 'SIR') ?? [])
              .filter((x) => x.col === i)
              .map((x) => {
                if (x.chapter) return <h3>{x.label}</h3>;
                if (!x.needComment) return <CmsFormInput.Switch inlineReverse key={x.id} label={x.label} id={x.code} />;
                return <CmsFormInput.Text id={x.code} label={x.label} multiline max={255} />;
              })}
          </div>
        ))}
      </div>
    </CmsPaper>
  );
};

const SiaOrSig: FC<{ riskList: any[]; type: 'SIA' | 'SIG' }> = ({ riskList, type }) => {
  const form = useFormContext();
  if (type === 'SIA' && form.watch().occupationalMedicine !== 2) return <></>;
  if (type === 'SIG' && form.watch().occupationalMedicine !== 3) return <></>;
  return (
    <CmsPaper className="sir-form" title={'Suivi individuel ' + (type === 'SIA' ? 'adapté' : 'général')}>
      <div className="medicine-form-container">
        {(riskList?.filter((x) => x.type === type) ?? []).map((x) => (
          <>
            <CmsFormInput.Switch inlineReverse key={x.code} label={x.label} id={x.code} />
            {x.needComment && (
              <CmsFormInput.Text id={x.code + 'Comment'} label={'Commentaire lié aux ' + x.label} multiline max={255} />
            )}
          </>
        ))}
      </div>
    </CmsPaper>
  );
};
