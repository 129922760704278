import React, { FC, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { IdLabel, LabelValue } from '../../interface/CommonType';
import CRUD from '../../service/CRUD.service';
import APIRoute from '../../constant/API.constant';
import { Dialog, Divider, IconButton, Menu, MenuItem } from '@mui/material';
import Utils, {
  dayOfTheWeek,
  displayDate,
  displayTime,
  getDayOfTheWeek,
  getKebabDateToDate,
  isDate,
  isSameDay,
  minuteToStringHour,
  orderListByAttr,
  setToKebabDate,
} from '../../helper/Utils';
import { PlanningContext, PlanningProvider, WkTimeReportingWeek } from '../../context/TimeReport.context';
import AccessFilter from '../../helper/AccessFilter';
import { WkIntervention } from '../../interface/WkType';
import NotificationService from '../../service/NotificationService';
import { TextEllipse, WarningBubble } from '../../component/shared/Ui';
import { TabStyleDataView } from '../../component/shared/TabStyleDataView';
import { authenticationService } from '../../service/Authentication.service';
import { ViewNavigationButton } from '../../component/shared/Buttons';
import './timeReporting.scss';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import StoreIcon from '@mui/icons-material/Store';
import PauseIcon from '@mui/icons-material/Pause';
import HomeIcon from '@mui/icons-material/Home';
import { Build } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingScreen from '../../component/LoadingScreen';
import { InputUI, UI } from '../../component/shared';
import CloseIcon from '@mui/icons-material/Close';
import CmsIcon from '../../component/shared/CmsIcon';
import { GlobalContext } from '../../context/Global.context';
import ROLE from '../../constant/role.constant';

// Local Interfaces and Constants --------------------------------

export enum TimeReportingFormStep {
  // État initial, à compléter
  TO_COMPLETE,
  // En attente de validation par le manager
  TO_SUPERVISOR_VALIDATE,
  // En attente de validation par le RH
  TO_RH_VALIDATE,
  // Validé
  VALIDATED,
}

interface TRPlanningDay {
  // Date sélectionnée
  day: Date;
  // Liste des utilisateurs présents sur le planning dans la semaine sélectionnée
  userList: Array<IdLabel>;
}

export class WkTimeReportingDay {
  // Id de la journée
  id?: number;
  // Est en cours de rechargement (Front uniquement)
  isReloading: boolean;
  // Date de la journée
  dayDate: Date;
  // Statut de la journée
  status: number;
  originalStatus?: number;
  // Id de l'utilisateur
  userId?: number;
  // Liste des actions de la journée
  actionList: Array<WkTimeReportingAction>;
  // Type de traject du technicien (Grand déplacement, panier, etc...)
  travelingTypeLabel?: string;
  // L'id du type de trajet
  travelingType?: number;
  // Total de la distance parcourue par le technicien
  allTravelDistance?: number;
  // Total du temps de travail
  totalWorkedTime?: number;
  // Définie si le jour à été validé (change l'affichage de la journée pour faire le compte rendu)
  isCompleted?: boolean;
  // Total du temps de trajet sans pause ni déplacement privé
  totalRouteTime?: number;
  // Temps total d'absence (7 ou 8 heures si 35 ou 40 heures hebdomadaires)
  totalAbsenceTime?: number;
  // Temps total d'absence payé (rtt ou congés payés)
  totalPaidAbsenceTime?: number;
  // Zone final calculé pour remboursement trajet du technicien
  finalArea?: number;
  // Commentaire du technicien
  comment?: string;
  // Commentaire du manager ou du RH si la journée est refusée
  errorComment?: string;
  // Validé par : (validation technicien, à ne pas confondre avec la validation supreviseur ou Rh)
  validatedBy?: number;
  // Nom de la personne qui a validé la journée
  validatedByName?: string;
  // Commentaire du manager
  supervisorComment?: string;
  // Commentaire du RH
  rhComment?: string;
  // Définie si la journée doit être validé
  isValidation?: boolean;
  // Nom
  rhCommentName?: string;
  // Nom du manager qui a fait le commentaire
  supervisorCommentName?: string;
  // Liste des problèmes potentiels dans la journée (ex: pas de pause de X minutes, vitesse trop rapide/lente, etc...)
  warningList?: string[];
  validatedDayTeamUserList?: IdLabel[];
  ticketRestaurant?: number;

  constructor() {
    this.dayDate = new Date();
    this.actionList = new Array<WkTimeReportingAction>();
    this.isReloading = false;
    this.status = 0;
  }
}

export class WkTimeReportingAction {
  // Id de l'action
  id?: number;
  // Id d'affichage (pour réact)
  keyId?: string;
  // Type d'action (Pause, Déplacement, Intervention, etc...)
  actionType: number;
  // Date de début de l'action
  start: Date;
  // Date de fin de l'action
  end: Date;
  // Est-ce que la journée commence bien par un départ.
  startError?: boolean;
  // Est-ce que la journée fini bien par un retour.
  endError?: boolean;
  // Description de l'action (ref intervention, station, etc...)
  description?: string;
  // Id de la tache de l'intervention
  taskId?: number;
  // Référence de la tache de l'intervention
  taskRef?: string;
  // Id de l'intervention
  interventionId?: number;
  // Référence de l'intervention
  interventionRef?: string;
  // Libellé de l'intervention (avec nom, ref, etc...)
  interventionLabel?: string;
  // Id de la station
  stationId?: number;
  // Nom de la station
  stationLabel?: string;
  // Distance parcourue
  travelDistance?: number;
  // Entreprise détenant la station
  retailer?: string;
  // Liste des problèmes potentiels dans l'action (ex: pas de pause de X minutes, vitesse trop rapide/lente, etc...)
  warningList?: string[];
  // Temps de trajet sans pause ni déplacement privé (si l'utilisateur n'as pas le remboursement par zone)
  roadLabor?: number;
  // Temps de trajet sans pause ni déplacement privé (si l'utilisateur as le remboursement par zone)
  routeIt?: number;
  // Temps de travail
  labor?: number;
  absenceTypeLabel?: string;
  viewOpen?: boolean;

  constructor(actionType = 0, start = new Date(), end = new Date()) {
    this.actionType = actionType;
    this.start = start;
    this.end = end;
  }
}

// Type d'action possible
export enum TRActionType {
  // Pause
  PAUSE,
  // Départ domicile
  HOME_DEPARTURE,
  // Retour domicile
  HOME_COME,
  // Départ hôtel
  HOSTEL_DEPARTURE,
  // Retour hôtel
  HOSTEL_COME,
  // Intervention
  INTERVENTION,
  // Trajet
  TRAVELING,
  // Passage agence
  AGENCY_PASSAGE,
  // Passage fournisseur
  WORK_PROVIDER_PASSAGE,
  // Départ maison (calcule depuis l'agence)
  AGENCY_DEPARTURE,
  // Retour agence
  AGENCY_COME,
  // Absence (non selectionnable (read only) depuis le backend, calculé avec le module absence)
  PAID_ABSENCE,
  // Absence (non selectionnable (read only) depuis le backend, calculé avec le module absence)
  ABSENCE,
  // Spécial, uniquement pour les techniciens en atelier (pas de trajet etc...)
  WORKSHOP,
  // En Formation, (read only) depuis le backend, calculé avec le module habilitation)
  FORMATION,
  HOLYDAY,
  CAR_WASH,
}

// Tout les type d'action de déplacement
const movingOption = [
  TRActionType.HOME_COME,
  TRActionType.HOME_DEPARTURE,
  TRActionType.HOSTEL_COME,
  TRActionType.HOSTEL_DEPARTURE,
  TRActionType.TRAVELING,
  TRActionType.AGENCY_DEPARTURE,
  TRActionType.AGENCY_COME,
];

// Type d'action de départ
const DepartOptions = [TRActionType.HOME_DEPARTURE, TRActionType.HOSTEL_DEPARTURE, TRActionType.AGENCY_DEPARTURE];
// Type d'action de retour
const ComeOptions = [TRActionType.HOME_COME, TRActionType.HOSTEL_COME, TRActionType.AGENCY_COME];

// Icone pour chaque type d'action
const TRActionIcon = {
  pause: <PauseIcon className="tr-action-icon" />,
  travel: <DriveEtaIcon className="tr-action-icon" />,
  store: <StoreIcon className="tr-action-icon" />,
  build: <Build className="tr-action-icon" />,
  home: <HomeIcon className="tr-action-icon" />,
};

// Liste des options pour chaque type d'action
export const TRActionOption: Array<{ id: number; label: string; icon: React.ReactNode; show: boolean }> = [
  { id: TRActionType.PAUSE, label: 'Pause', icon: TRActionIcon.pause, show: true },
  { id: TRActionType.HOME_DEPARTURE, label: 'Départ domicile', icon: TRActionIcon.home, show: true },
  { id: TRActionType.HOME_COME, label: 'Retour domicile', icon: TRActionIcon.home, show: true },
  { id: TRActionType.HOSTEL_DEPARTURE, label: 'Départ hôtel', icon: TRActionIcon.home, show: true },
  { id: TRActionType.HOSTEL_COME, label: 'Retour hôtel', icon: TRActionIcon.home, show: true },
  { id: TRActionType.INTERVENTION, label: 'Intervention', icon: TRActionIcon.build, show: true },
  { id: TRActionType.TRAVELING, label: 'Trajet', icon: TRActionIcon.travel, show: true },
  { id: TRActionType.AGENCY_PASSAGE, label: "Passage à l'agence", icon: TRActionIcon.store, show: true },
  { id: TRActionType.WORK_PROVIDER_PASSAGE, label: 'Passage par le fournisseur', icon: TRActionIcon.store, show: true },
  { id: TRActionType.AGENCY_DEPARTURE, label: 'Départ maison', icon: TRActionIcon.home, show: true },
  { id: TRActionType.AGENCY_COME, label: 'Retour maison', icon: TRActionIcon.home, show: true },
  { id: TRActionType.PAID_ABSENCE, label: 'Absence (indemnisée)', icon: TRActionIcon.home, show: false },
  { id: TRActionType.ABSENCE, label: 'Absence', icon: TRActionIcon.home, show: false },
  { id: TRActionType.WORKSHOP, label: 'Atelier', icon: TRActionIcon.build, show: true },
  { id: TRActionType.FORMATION, label: 'Formation', icon: TRActionIcon.home, show: true },
  { id: TRActionType.HOLYDAY, label: 'Jour férié', icon: TRActionIcon.home, show: true },
  { id: TRActionType.CAR_WASH, label: 'Entretien véhicule', icon: TRActionIcon.store, show: true },
];

interface UserHandler {
  // Utilisateur courant (actuellement connecté)
  currentUser?: IdLabel;
  // Utilisateur émulé (si l'utilisateur courant est un admin)
  emulatedUser?: IdLabel;
  // Utilisateur sélectionné, celui dont ont va remplir la feuille de temps
  selectedUser?: IdLabel;
  // Est-ce que l'utilisateur est un admin/Master (RH, responsable, etc...)
  isUserAdmin?: boolean;
}

interface TimeReportingStateProps {
  // Date courante
  date?: Date;
  // Mis à jour de la date courante
  setDate: React.Dispatch<React.SetStateAction<Date | undefined>>;
  // Utilisateur courant (actuellement connecté) avec les informations de l'utilisateur émulé/selectionné
  userHandler: UserHandler;
  // Mis à jour de l'utilisateur courant (actuellement connecté) avec les informations de l'utilisateur émulé/selectionné
  setUserHandler: React.Dispatch<React.SetStateAction<UserHandler>>;
  // @deprecated ne plus utiliser
  isBypass: boolean;
  // @deprecated ne plus utiliser
  setBypass: React.Dispatch<React.SetStateAction<boolean>>;
}

// Components ----------------------------------------------

/**
 * Page de gestion des feuilles de temps, elle permet aux techniciens de remplir leur feuille de temps afin
 * d'informer le système de leur présence et de leur activité (anciennement gérer par feuille de papier).
 */
const TimeReportingPage: FC = () => {
  const [date, setDate] = useState<any>(new Date());
  const [userHandler, setUserHandler] = useState<UserHandler>({});
  const [isBypass, setBypass] = useState<boolean>(false);
  const isUserAdmin = useRef(AccessFilter([ROLE.ADMIN_TIMEREPORTINGWEEK_LIST, ROLE.USER_IS_MANAGER]));
  const timeReportState = {
    date,
    setDate,
    userHandler,
    setUserHandler,
    isBypass,
    setBypass,
    isUserAdmin: isUserAdmin?.current,
  };
  const navigate = useNavigate();
  const { paramId, paramDate, emulatedId } = useParams() as any;

  // On va chercher les technicien présent à la date indiqué, filtré par droits d'accès
  useEffect(() => {
    const user = authenticationService.getCurrentUser();
    const idLabel = { id: user.userId, label: user.userLabel };
    const tempUserHandler: UserHandler = { currentUser: idLabel, emulatedUser: idLabel };
    if (isUserAdmin?.current) tempUserHandler.isUserAdmin = true;
    if (paramId && paramDate) {
      const calculatedDate = getKebabDateToDate(paramDate);
      if (!isDate(calculatedDate)) navigate('/castres/timereporting/');
      setDate(calculatedDate);
      CRUD.getCustomObject<IdLabel>(APIRoute.UsUsers + '/Simplified?id=' + paramId).then((result) => {
        tempUserHandler.selectedUser = { id: paramId, label: result.label };
        tempUserHandler.emulatedUser = { id: emulatedId ?? paramId, label: '' };
        setUserHandler(tempUserHandler);
      });
    } else {
      setUserHandler(tempUserHandler);
    }
  }, [paramDate, paramId, navigate, emulatedId]);

  if (!userHandler?.currentUser?.id) return <LoadingScreen />;
  if (!date || !userHandler?.selectedUser) return <CalendarSelection props={timeReportState} />;
  // Un fois un utilisateur et une date sélection dans le calendrier, on affiche le formulaire de saisie
  return (
    <div className="time-reporting-form">
      <PlanningProvider>
        <WeekReportingForm props={timeReportState} />
      </PlanningProvider>
    </div>
  );
};

/**
 *  Affiche le calendrier de sélection de la date et de l'utilisateur, dès qu'un utilisateur et une date sont sélectionné
 *  le composant disparait pour afficher le formulaire de saisie
 * @param props Contient les informations de l'utilisateur selectionné et de la date
 */
const CalendarSelection: FC<{ props: TimeReportingStateProps }> = ({ props }) => {
  const { date, setDate, userHandler, setUserHandler } = props;
  const [adminUserList, setAdminUserList] = useState<Array<IdLabel>>([]);
  const [planningDayList, setPlanningDayList] = useState<Array<TRPlanningDay>>();
  const userCanViewList = useRef(AccessFilter([ROLE.ADMIN_TIMEREPORTINGWEEK_LIST, ROLE.USER_IS_MANAGER]));
  const { selectedUser, emulatedUser, currentUser } = userHandler;
  const navigate = useNavigate();

  useEffect(() => {
    if (date) return;
    const tempUserHandler = Object.assign({}, userHandler);
    tempUserHandler.emulatedUser = currentUser;
    tempUserHandler.selectedUser = undefined;
    setUserHandler(tempUserHandler);
    setDate(new Date());
  }, [setUserHandler, userHandler, setDate, date, currentUser]);

  useEffect(() => {
    CRUD.getCustomObject<Array<TRPlanningDay>>(APIRoute.WkTimeReportingWeek + '/GetPlanning/' + currentUser?.id).then(
      setPlanningDayList,
    );
    if (userCanViewList?.current)
      CRUD.getSimpleList(APIRoute.WkTimeReportingWeek + '/GetUserFiltered').then(setAdminUserList);
  }, [currentUser, userCanViewList]);
  if (!planningDayList) return <LoadingScreen />;

  const userList = planningDayList.find((p: TRPlanningDay) => isSameDay(p.day, date))?.userList;

  const findUserSelected = (id: number) => {
    const tempUserHandler = Object.assign({}, userHandler);
    tempUserHandler.selectedUser = userList?.find((x) => x.id === id);
    if (!date) return;
    let url = `/castres/timereporting/${id}/${setToKebabDate(date)}`;
    if (tempUserHandler.emulatedUser?.id && id !== tempUserHandler.emulatedUser?.id)
      url += '/' + tempUserHandler.emulatedUser?.id;
    navigate(url);
  };

  const findUserEmulated = (id: number | null) => {
    if (userHandler?.emulatedUser?.id === id) return;
    const tempUserHandler = Object.assign({}, userHandler);
    tempUserHandler.emulatedUser = adminUserList?.find((x) => x.id === id);
    tempUserHandler.selectedUser = undefined;
    setUserHandler(tempUserHandler);
    if (!id) return;
    CRUD.getCustomObject<Array<TRPlanningDay>>(
      APIRoute.WkTimeReportingWeek + '/GetPlanning/' + (id ?? currentUser?.id),
    ).then((result) => setPlanningDayList(result));
  };

  return (
    <>
      {userCanViewList?.current && (
        <UI.Paper
          title={'Administration'}
          style={{ maxWidth: '600px' }}
          actions={[<ViewNavigationButton title="Administration" to={`/castres/timereporting/administration`} />]}
        >
          <p>En tant qu'administrateur, vous pouvez remplir la feuille de vos techniciens</p>
          <p>Veuillez sélectionner ci-dessous en tant que quel technicien vous souhaiter remplir la feuille d'heure</p>
          <InputUI.AutoCompletor options={adminUserList} value={emulatedUser?.id ?? null} onChange={findUserEmulated} />
        </UI.Paper>
      )}
      <UI.Paper
        title="Enregistrer une feuille de temps"
        style={{ maxWidth: '600px' }}
        actions={[
          <CmsIcon
            href="/help/Feuille_heures/saisie"
            style={{ marginLeft: '12px' }}
            tooltip="Aide Saisie des feuilles d'heures"
            icon="help"
            target="_blank"
          />,
        ]}
      >
        <div className="time-reporting-calendar">
          <InputUI.CMSCalendar date={date} onChange={setDate} disableFuture />
        </div>
        <InputUI.SimpleSelect
          label="Remplir la feuille de :"
          value={selectedUser ?? ''}
          disabled={!userList}
          options={userList}
          onChange={(e: any) => findUserSelected(e as number)}
        />
      </UI.Paper>
    </>
  );
};

/**
 * Affiche le formulaire de saisie de la feuille de temps, elle représente une semaine divisé en jours puis actions
 * @param props Contient les informations de l'utilisateur selectionné et de la date
 */
const WeekReportingForm: FC<{ props: TimeReportingStateProps }> = ({ props }) => {
  const [firstCall, setFirstCall] = useState(true);
  const { planning, setPlanning, setInterventionOfThisWeek } = useContext(PlanningContext);
  const { date, setDate, userHandler, isBypass } = props;
  const canEdit = useMemo(() => {
    if (planning.status === 0) return true;
    if (planning.status === 1 && AccessFilter([ROLE.USER_IS_MANAGER, ROLE.ADMIN_TIMEREPORTINGWEEK_VALIDATION]))
      return true;
    return planning.status === 2 && AccessFilter([ROLE.ADMIN_TIMEREPORTINGWEEK_VALIDATION]);
  }, [planning.status]);

  useEffect(() => {
    if (!userHandler?.selectedUser?.id || !date) return;
    const query = `/TimeReporting/${userHandler.selectedUser.id}/${Utils.toGmtIsoString(date)}`;
    CRUD.getList<WkIntervention>(APIRoute.WkIntervention + query).then(setInterventionOfThisWeek);
  }, [date, setInterventionOfThisWeek, userHandler?.selectedUser?.id]);

  useEffect(() => {
    const id = userHandler?.selectedUser?.id ?? 0;
    const emuId = userHandler?.emulatedUser?.id ?? 0;
    const request = `/${id}?originalUserId=${emuId}&date=${Utils.toGmtIsoString(date)}&bypassClosedTask=${isBypass}`;
    setFirstCall(false);
    if (!firstCall) return;
    CRUD.getCustomObject<WkTimeReportingWeek>(APIRoute.WkTimeReportingWeek + request).then((result) => {
      result.mondayDate = new Date(result.mondayDate);
      result.dayList = parseDateOfDayList(result.dayList);
      result.dayListUnModified = JSON.parse(JSON.stringify(result.dayList));
      result.originalUserDayList = result.originalUserDayList ?? new Array<WkTimeReportingDay>();
      setKeyIds(result);
      setPlanning(result);
    });
  }, [date, firstCall, isBypass, setDate, setPlanning, userHandler]);

  if (!planning || planning.id === undefined || planning?.isReloading) return <LoadingScreen />;
  console.log('planning', planning);
  return (
    <>
      <WeekIntroduction userHandler={userHandler} status={planning.status} />
      {planning &&
        planning.dayList.map((day, j) => {
          if (day.status === 0) return <DayForm key={j} day={day} dayId={j} />;
          return <DayValidation key={j} day={day} dayId={j} canEdit={canEdit} />;
        })}
      <WeekFooter userHandler={userHandler} />
    </>
  );
};

/**
 * Affiche le formulaire de saisie d'un jour, divisé en actions
 * @param day L'objet wkTimeReportingDay qui contient toutes les données de la journée
 * @param dayId L'index de la journée dans le tableau de jours (pour react)
 */
export const DayForm: FC<{ day: WkTimeReportingDay; dayId: number; isAdministration?: boolean }> = ({ day, dayId }) => {
  const { planning, setPlanning } = useContext(PlanningContext);
  const [isDeployed, setDeploy] = useState<boolean>(false);
  const [isLoading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [warningList, setWarningList] = useState<any[]>([]);
  const [isValidation, setValidation] = useState(false);

  useEffect(() => {
    if (!day.isReloading) return;
    planning.dayList[dayId].isReloading = false;
    setPlanning(Object.assign({}, planning));
  }, [day.isReloading, planning, setPlanning, dayId]);

  if (day.isReloading) return <LoadingScreen />;
  const isDayError = day.status === 0 && planning.status > 0;

  const checkIfDayValid = (isValidate = false) => {
    setValidation(isValidate);
    const warnings = getDayWarnings(planning, day);
    if (warnings.length === 0) return validDay(isValidate);
    setWarningList(warnings);
    setOpenModal(true);
  };

  const validDay = (isValidate = false) => {
    const request = isDayError || day.id ? CRUD.put : CRUD.post;
    setLoading(true);
    setOpenModal(false);
    day.isValidation = isValidate;
    request<WkTimeReportingDay>(APIRoute.WkTimeReportingDay, day)
      .then((result) => {
        if (!result) return;
        result.dayDate = new Date(result.dayDate);
        result = parseDateOfDayList([result])[0];
        planning.dayList[dayId] = result;
        setPlanning(Object.assign({}, planning));
        NotificationService.success('Journée enregistrée');
      })
      .finally(() => setLoading(false));
  };

  // Ajoute une action à la journée
  const addAction = () => {
    const newAction = new WkTimeReportingAction();
    newAction.keyId = Utils.generateRandomString();
    newAction.viewOpen = true;
    const actionList = planning.dayList[dayId].actionList;
    if (actionList && actionList.length > 0) newAction.start = newAction.end = actionList[actionList.length - 1].end;
    else newAction.start = newAction.end = planning.dayList[dayId].dayDate;
    planning.dayList[dayId].actionList.push(newAction);
    setPlanning(Object.assign({}, planning));
  };

  let isAbsenceDay = false;
  for (const action of day.actionList) if (isAbsence(action.actionType)) isAbsenceDay = true;
  const Menu: FC = () => (isAbsenceDay ? <></> : <DayMenuValider day={day} dayId={dayId} setLoading={setLoading} />);
  const title = (
    <span onClick={() => setDeploy(!isDeployed)}>{getDayOfTheWeek(day.dayDate) + ' ' + displayDate(day.dayDate)}</span>
  );
  return (
    <UI.Paper
      style={{ marginBottom: '2rem' }}
      isLoading={isLoading}
      headerStyle={isDayError ? { backgroundColor: '#9e2626' } : undefined}
      title={[title, <UI.DeployIcon value={isDeployed} onchange={setDeploy} />, <Menu />]}
    >
      {isDeployed && (
        <>
          {day.errorComment && (
            <UI.WarningBubble type="error">
              <h3>Motif de refus :</h3>
              {day.errorComment}
            </UI.WarningBubble>
          )}
          {day?.actionList &&
            day.actionList.map(
              (step, i) =>
                (isAbsence(step.actionType) && <AbsenceStep key={i} step={step} dayId={dayId} stepId={i} />) || (
                  <StepForm key={step.keyId} step={step} dayId={dayId} stepId={i} />
                ),
            )}
          <div className={'day-buttons ' + (Utils.isMobileBrowser() ? 'flex-v' : 'flex-col-center')}>
            <UI.Button onClick={addAction}>Ajouter une action</UI.Button>
            {planning.status < 1 && (day.originalStatus ?? day.status) < 1 && (
              <UI.Button onClick={() => checkIfDayValid(false)}>Enregistrer la journée</UI.Button>
            )}
            <UI.Button onClick={() => checkIfDayValid(true)}>Valider la journée</UI.Button>
          </div>
        </>
      )}
      <WarningModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        warningList={warningList}
        sendReporting={() => validDay(isValidation)}
      />
    </UI.Paper>
  );
};

// Défini si l'action est une absence
function isAbsence(actionType: TRActionType): boolean {
  return actionType === TRActionType.ABSENCE || actionType === TRActionType.PAID_ABSENCE;
}

// Génère un id unique pour chaque action pour l'affichage front (react)
function setKeyIds(planning: WkTimeReportingWeek): void {
  for (const day of planning.dayList)
    for (const step of day.actionList) if (!step.keyId) step.keyId = Utils.getRandomUniqId(day.actionList, 'keyId');
}

// Remplace une action de formulaire par une action absence (readOnly)
const AbsenceStep: FC<{ step: WkTimeReportingAction; dayId: number; stepId: number }> = ({ step, stepId }) => (
  <div key={stepId} className="step-form tr-absence">
    <h2>{step.absenceTypeLabel}</h2>
    <p>
      <span>Début:{displayTime(step.start)}</span>
      <span>Fin:{displayTime(step.end)}</span>
    </p>
  </div>
);

/**
 * Menu d'une journée du formulaire des feuilles d'heures, il permet de réinitialiser la jourée ou de la supprimer
 * @param day la journée
 * @param setLoading fonction pour mettre le formulaire en chargement (loader)
 */
const DayMenuValider: FC<{ day: WkTimeReportingDay; dayId: number; setLoading: any }> = ({
  day,
  dayId,
  setLoading,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { planning, setPlanning } = useContext(PlanningContext);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const originalDay = planning.originalUserDayList.find((x) => x.dayDate === day.dayDate);

  const deleteDay = () => {
    if (!day.id) {
      removeDayFromFrontList();
      return;
    }
    setLoading(true);
    CRUD.deleteById(APIRoute.WkTimeReportingDay, day.id)
      .then(() => {
        NotificationService.info('journée supprimée');
        removeDayFromFrontList();
      })
      .finally(() => setLoading(false));
  };

  // Supprime la journée de la liste
  const removeDayFromFrontList = () => {
    planning.dayList = planning.dayList.filter((x) => x.dayDate !== planning.dayList[dayId].dayDate);
    setPlanning(Object.assign({}, planning));
  };

  // Réinitialise la journée
  const undoChange = () => {
    const unmodifiedDay =
      planning.dayListUnModified.find((x) => new Date(x.dayDate).getDay() === day.dayDate.getDay()) ?? null;
    if (!unmodifiedDay) {
      removeDayFromFrontList();
      return;
    }
    planning.dayList[dayId] = JSON.parse(JSON.stringify(unmodifiedDay));
    planning.dayList = parseDateOfDayList(planning.dayList);
    setPlanning(Object.assign({}, planning));
  };

  // Remplace la journée par la version de l'utilisateur
  const replaceDayByOriginalUserDay = () => {
    planning.dayList[dayId] = JSON.parse(JSON.stringify(originalDay));
    planning.dayList = parseDateOfDayList(planning.dayList);
    planning.dayList[dayId].isCompleted = false;
    setPlanning(Object.assign({}, planning));
  };

  const getDay = (userId?: number) => {
    setLoading(true);
    CRUD.post<WkTimeReportingDay>(APIRoute.WkTimeReportingDay + '/Copy', { id: userId, value: day.dayDate })
      .then((dayFromTeam) => {
        dayFromTeam.id = planning.dayList[dayId]?.id;
        dayFromTeam.validatedDayTeamUserList = planning.dayList[dayId]?.validatedDayTeamUserList ?? [];
        dayFromTeam.userId = planning.dayList[dayId]?.userId;
        dayFromTeam.status = 0;
        dayFromTeam.actionList.map((x) => (x.id = undefined));
        planning.dayList[dayId] = dayFromTeam;
        planning.dayList = parseDateOfDayList(planning.dayList);
        setPlanning(Object.assign({}, planning));
      })
      .finally(() => setLoading(false));
    handleClose();
  };

  return (
    <>
      <IconButton aria-label="settings" onClick={handleClick} size="large">
        <CmsIcon icon="moreVertical" />
      </IconButton>
      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={undoChange}>Réinitialiser la journée</MenuItem>
        {originalDay && <MenuItem onClick={replaceDayByOriginalUserDay}>Copier à partir de votre feuille</MenuItem>}
        <MenuItem onClick={deleteDay}>Supprimer la journée</MenuItem>
        {day.validatedDayTeamUserList?.map((user) => (
          <MenuItem onClick={() => getDay(user.id)}>Copier de {user.label}</MenuItem>
        ))}
      </Menu>
    </>
  );
};

/**
 * Affichage d'une journée validé avec résultat des calculs backend pour effectuer les totaux et calcule de zone, etc.
 * @param day la journée
 * @param dayId l'id de la journée dans le planning
 */
const DayValidation: FC<{ day: WkTimeReportingDay; dayId: number; canEdit: boolean }> = ({ day, dayId, canEdit }) => {
  const { planning, setPlanning } = useContext(PlanningContext);
  const [comment, setComment] = useState<string>(day.comment ?? '');
  const { theming } = useContext(GlobalContext);
  const backgroundColor =
    planning.status > 0
      ? planning.status === 3
        ? theming.get().cms.color.green
        : theming.get().cms.color.purple
      : theming.get().cms.color.blue;

  // Envoie un commentaire, uniquement possible après la validation de la journée
  const validComment = () => {
    if (day.comment === comment) return;
    day.comment = comment;
    CRUD.put<WkTimeReportingDay>(APIRoute.WkTimeReportingDay, day).then(() => {
      NotificationService.success('Commentaire enregistré');
      day.dayDate = new Date(day.dayDate);
      day = parseDateOfDayList([day])[0];
      planning.dayList[dayId] = day;
      setPlanning(Object.assign({}, planning));
    });
  };

  const enableModif = () => {
    planning.dayList[dayId].originalStatus = planning.dayList[dayId].status;
    planning.dayList[dayId].status = 0;
    setPlanning(Object.assign({}, planning));
  };

  return (
    <UI.Paper
      title={getDayOfTheWeek(day.dayDate) + ' ' + displayDate(day.dayDate)}
      headerStyle={{ backgroundColor }}
      actions={canEdit ? [<UI.Button onClick={enableModif}>Modifier ma journée</UI.Button>] : []}
    >
      <TabStyleDataView conf={mapLabelValueStep(day.actionList)} />
      <Divider />
      <h4 style={{ marginTop: '20px' }}>Info de la journée:</h4>
      <div>indemnité : {day.travelingTypeLabel}</div>
      {!!day.finalArea && <div>Zone Payée : {day.finalArea ?? 0}</div>}
      <div>Distance parcourue : {day.allTravelDistance ?? 0} km</div>
      <div>Temps de route payé : {minuteToStringHour(day.totalRouteTime ?? 0)}</div>
      <div>Temps Travaillé : {minuteToStringHour(day.totalWorkedTime ?? 0)}</div>
      <InputUI.DebouncedInput
        disabled={planning.status === TimeReportingFormStep.VALIDATED}
        delayTime={2000}
        label="Commentaire"
        multiline
        value={comment}
        onChange={setComment}
      />
      {comment && comment !== '' && day.comment !== comment && planning.status !== TimeReportingFormStep.VALIDATED && (
        <UI.Button onClick={validComment}>Enregistrer le commentaire</UI.Button>
      )}
    </UI.Paper>
  );
};

/**
 * Transforme les string JSON en date de l'objet WkTimeReportingDay
 * @param daylist
 */
export function parseDateOfDayList(daylist: Array<WkTimeReportingDay>): Array<WkTimeReportingDay> {
  for (const day of daylist) {
    if (!isDate(day.dayDate)) day.dayDate = new Date(day.dayDate);
    for (const action of day.actionList) {
      action.start = !isDate(action.start) ? new Date(action.start) : action.start;
      action.end = !isDate(action.end) ? new Date(action.end) : action.end;
    }
  }
  return daylist;
}

// Affichage des étapes (nom de l'intervention, nom de la tâche, etc.) pour le dropdown
function mapLabelValueStep(data: Array<WkTimeReportingAction>): Array<LabelValue> {
  const result = new Array<LabelValue>();
  for (const step of data) {
    let label = TRActionOption[step.actionType]?.label;
    if ([11, 12].includes(step.actionType)) label = step.absenceTypeLabel ?? 'Absence';
    if (step.actionType === 5) label = step.interventionRef ?? '';
    const value = displayTime(step.start) + ' - ' + displayTime(step.end);
    result.push({ label, value });
  }
  return result;
}

const specialInterventionType = [
  TRActionType.WORK_PROVIDER_PASSAGE,
  TRActionType.AGENCY_PASSAGE,
  TRActionType.CAR_WASH,
];

/**
 * Formulaire d'une action, elle permet de modifier les informations d'une action, (type/heure de début/heure de fin)
 * @param step l'action
 * @param dayId l'id de la journée dans le planning
 * @param stepId l'id de l'action dans la journée
 */
const StepForm: FC<{ step: WkTimeReportingAction; dayId: number; stepId: number }> = ({ step, dayId, stepId }) => {
  const {
    start,
    end,
    actionType,
    travelDistance,
    taskRef,
    stationLabel,
    interventionRef,
    interventionLabel,
    taskId,
    startError,
    endError,
  } = step;
  const { planning, setPlanning, interventionOfThisWeek } = useContext(PlanningContext);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const { theming } = useContext(GlobalContext);
  const [taskList, setTaskList] = useState<any[]>([{ id: taskId, label: taskRef }]);
  const uid = dayId + '0' + stepId;
  const isReadOnly = step.actionType === TRActionType.ABSENCE || step.actionType === TRActionType.PAID_ABSENCE;
  const stepHtmlId = 'step-' + start.getTime();
  const dayDate = planning.dayList[dayId]?.dayDate;

  // Gère la mise à jour des données de l'action dans l'objet planning
  // Attention, il faut faire un Object.assign pour que le composant se mette à jour
  const handleUpdate = (target: string, value: any) => {
    if (!planning.dayList?.[dayId]?.actionList?.[stepId]) return;
    // @ts-ignore
    if (planning.dayList[dayId].actionList[stepId][target] === value) return;
    // @ts-ignore
    planning.dayList[dayId].actionList[stepId][target] = value;
    planning.dayList[dayId] = checkDayErrors(planning.dayList[dayId]);
    setKeyIds(planning);
    setPlanning(Object.assign({}, UpdatePlanningTravelType(planning)));
    if (target !== 'start' && target !== 'end') return;
    setTimeout(() => {
      const updatedStep = document.getElementById('step-' + value.getTime());
      updatedStep?.scrollIntoView({ block: 'center', behavior: 'smooth' });
      updatedStep?.classList?.add('tm-highlight');
    }, 200);
  };

  const handleView = () => {
    if (!planning.dayList[dayId]?.actionList[stepId]) return;
    planning.dayList[dayId].actionList[stepId].viewOpen = !planning.dayList[dayId].actionList[stepId].viewOpen;
    setPlanning(Object.assign({}, planning));
  };

  // Gère la mise à jour du type d'action
  const handleActionTypeUpdate = (actionType: number) => {
    if (planning.dayList[dayId].actionList[stepId].actionType === actionType) return;
    const oldAction = planning.dayList[dayId].actionList[stepId];
    planning.dayList[dayId].actionList[stepId] = new WkTimeReportingAction(actionType, oldAction.start, oldAction.end);
    planning.dayList[dayId].actionList[stepId].viewOpen = true;
    planning.dayList[dayId] = Object.assign({}, planning.dayList[dayId]);
    setPlanning(Object.assign({}, UpdatePlanningTravelType(planning)));
    setRefresh(true);
  };

  // Gère la mise à jour de l'intervention d'une action
  const handleInterventionUpdate = (intervention: WkIntervention) => {
    const label = (intervention?.name ?? '') + (intervention?.name ? ' - ' : '') + (intervention?.description ?? '');
    handleUpdate('interventionId', intervention?.id ?? null);
    handleUpdate('interventionRef', intervention?.ref ?? null);
    handleUpdate('interventionLabel', label);
    handleUpdate('stationLabel', intervention?.station?.label ?? null);
    if (intervention?.wkTask) {
      if (intervention?.wkTask?.length === 1) handleUpdate('taskId', intervention.wkTask[0]?.id ?? null);
      setTaskList(intervention?.wkTask ?? null);
    }
  };

  // Supprime l'action
  const DeleteActualStep = () => {
    planning.dayList[dayId].actionList = planning.dayList[dayId].actionList.filter(
      (x: WkTimeReportingAction, i: number) => i !== stepId,
    );
    planning.dayList[dayId] = checkDayErrors(planning.dayList[dayId]);
    setPlanning(Object.assign({}, planning));
  };

  useEffect(() => {
    if (refresh) setRefresh(false);
  }, [refresh, setRefresh]);
  if (refresh) return <></>;
  const weirdSpeed = isSpeedWeird(start, end, travelDistance ?? 0);

  let lunchTimeTooShort = null;
  const lunchTime = (planning?.lunchTime ?? 60) * 60000;
  if (step.actionType === TRActionType.PAUSE) lunchTimeTooShort = step.end.getTime() - step.start.getTime() < lunchTime;

  if (!planning.dayList[dayId]?.actionList[stepId]?.viewOpen) {
    return (
      <div
        className="short-view-div clickable"
        style={{ borderColor: theming.get().palette.text.primary }}
        onClick={handleView}
      >
        <CmsIcon icon="edit" className="short-view-close-icon" tooltip="editer" onClick={() => {}} />
        <TabStyleDataView
          conf={[
            { label: 'Action', value: TRActionOption[actionType]?.label },
            { label: 'Début', value: displayTime(start) },
            { label: 'Fin', value: displayTime(end) },
          ]}
        />
      </div>
    );
  }
  const icon = <CmsIcon className="short-view-icon" icon="arrowUp" onClick={handleView} />;
  let actionOption = TRActionOption.filter((x) => x.show);
  if (!planning.detached)
    actionOption = actionOption.filter((x) => x.id !== TRActionType.HOME_DEPARTURE && x.id !== TRActionType.HOME_COME);
  return (
    <div id={stepHtmlId} className="step-form">
      <CmsIcon icon="close" className="delete-button" onClick={() => setOpenDeleteModal(true)} />
      <InputUI.SimpleSelect
        label={['Action', icon] as any}
        value={actionType}
        options={actionOption}
        onChange={(e: any) => handleActionTypeUpdate(e as number)}
      />

      {movingOption.includes(actionType) && (
        <InputUI.DebouncedInput
          label="Distance (km)"
          name={'distance' + uid}
          value={travelDistance ?? 0}
          onChange={(e: any) => handleUpdate('travelDistance', e)}
        />
      )}

      {[...specialInterventionType, TRActionType.INTERVENTION].includes(actionType) && (
        <>
          <InputUI.CustomSuggest
            label="Ref Intervention"
            name={'interventionId' + uid}
            initialValue={interventionRef}
            onValueSelected={handleInterventionUpdate}
            getSuggestionService={(search: string) =>
              CRUD.getSuggestedList(APIRoute.WkIntervention, { search, date: dayDate }).then((x) => [
                ...x,
                ...interventionOfThisWeek,
              ])
            }
            showAttribute="ref"
          />
          {interventionLabel && (
            <div style={{ margin: '.5em 0 0 0' }}>
              <h3 style={{ textAlign: 'center' }}>Description intervention:</h3>
              <p>{interventionLabel}</p>
            </div>
          )}
          {stationLabel && (
            <div style={{ margin: '.5em 0' }}>
              <h3 style={{ textAlign: 'center' }}>Station:</h3>
              <TextEllipse style={{ textAlign: 'center', width: '100%' }} text={stationLabel} />
            </div>
          )}
          {[TRActionType.INTERVENTION].includes(actionType) && (
            <InputUI.SimpleSelect
              nullable
              label="Tâches"
              value={taskId ?? ''}
              disabled={!taskList?.length}
              options={taskList}
              itemStyle={{ whiteSpace: 'normal' }}
              onChange={(e: any) => handleUpdate('taskId', e ?? null)}
            />
          )}
        </>
      )}
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
        <InputUI.CMSTimePicker
          disabled={isReadOnly}
          label="de"
          error={startError}
          value={start}
          onAccept={(e: any) => handleUpdate('start', e)}
        />
        <InputUI.CMSTimePicker
          disabled={isReadOnly}
          label="à"
          error={endError}
          value={end}
          onAccept={(e: any) => handleUpdate('end', e)}
        />
      </div>
      {movingOption.includes(actionType) && !!weirdSpeed && (
        <WarningBubble>Votre vitesse de déplacement est de {weirdSpeed}</WarningBubble>
      )}
      {lunchTimeTooShort && <WarningBubble>La pause doit faire au moins {lunchTime / 60000} minutes</WarningBubble>}
      <WarningDeleteStep {...{ openDeleteModal, setOpenDeleteModal }} onAccept={DeleteActualStep} />
    </div>
  );
};

interface WarningDeleteProps {
  openDeleteModal: boolean;
  setOpenDeleteModal: (value: boolean) => void;
  onAccept: () => void;
}

const WarningDeleteStep: FC<WarningDeleteProps> = ({ openDeleteModal, setOpenDeleteModal, onAccept }) => {
  return (
    <UI.Dialog open={openDeleteModal} onClose={() => setOpenDeleteModal(false)}>
      <div style={{ margin: '1rem' }}>
        <span>êtes vous sûr de vouloir supprimer cette action ?</span>
        <UI.Divider />
        <UI.ButtonHolder>
          <UI.Button color="inherit" onClick={() => setOpenDeleteModal(false)}>
            Annuler
          </UI.Button>
          <UI.Button color="warning" onClick={onAccept}>
            Supprimer
          </UI.Button>
        </UI.ButtonHolder>
      </div>
    </UI.Dialog>
  );
};

/**
 * Fonction de vérification superficielle du formulaire, vérifier que les heures sont cohérentes,
 * @param form formulaire de la journée à vérifier
 */
function checkDayErrors(form: WkTimeReportingDay): WkTimeReportingDay {
  if (!form?.actionList) return form;
  const tempForm: WkTimeReportingDay = Object.assign({}, form);
  tempForm.actionList = orderListByAttr(tempForm.actionList, 'start');
  for (let i = 0; i < tempForm.actionList.length; i++) {
    const isTimeNegative = tempForm.actionList[i].start > tempForm.actionList[i].end;
    tempForm.actionList[i].startError = tempForm.actionList[i].endError = isTimeNegative;
    if (i === 0) continue;
    if (tempForm.actionList[i].start < tempForm.actionList[i - 1].end)
      tempForm.actionList[i].startError = tempForm.actionList[i - 1].endError = true;
  }
  return tempForm;
}

/**
 * Fonction de mise à jour automatique des actions de déplacement d'une jounée à l'autre
 * Si on mets un retour hotel, mets automatiquement un départ hotel le lendemain
 * @param planning planning à mettre à jour
 */
function UpdatePlanningTravelType(planning: WkTimeReportingWeek): WkTimeReportingWeek {
  for (let i = 1; i < planning.dayList.length; i++) {
    if (planning.dayList[i].status > 0 || planning.dayList[i].actionList.length < 1) continue;
    if (planning.dayList[i - 1].dayDate.getDay() !== planning.dayList[i].dayDate.getDay() - 1) continue;
    const departure = planning.dayList[i].actionList[0];
    if (!DepartOptions.includes(departure.actionType)) continue;
    const actionList = planning.dayList[i - 1].actionList;
    const previousActionType = actionList[actionList.length - 1]?.actionType ?? 0;
    if (ComeOptions.includes(previousActionType)) departure.actionType = previousActionType - 1;
  }
  return planning;
}

/**
 * Entête de la page de saisie des temps
 * @param userHandler gestionnaire d'utilisateur
 * @param status état de la semaine
 */
const WeekIntroduction: FC<{ userHandler: UserHandler; status: TimeReportingFormStep }> = ({ userHandler, status }) => {
  const [helpIsOpen, setHelpIsOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { planning, setPlanning } = useContext(PlanningContext);
  const { emulatedUser, selectedUser } = userHandler;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const title = status > 0 ? (status === 3 ? 'Semaine validée' : 'Semaine en cours de traitement') : 'Saisie';
  const navigate = useNavigate();
  const { theming } = useContext(GlobalContext);
  const backgroundColor = status === 3 ? theming.get().cms.color.green : theming.get().cms.color.purple;
  const handleNavigation = () => navigate('/castres/timereporting');

  const handleCopy = (userId: number) => {
    setIsLoading(true);
    const payload = `/Copy/${planning.id}?copyUserId=${userId}&date=${Utils.toGmtIsoString(planning.mondayDate)}`;
    CRUD.getCustomObject<WkTimeReportingWeek>(APIRoute.WkTimeReportingWeek + payload)
      .then((result) => {
        if (!result) return;
        planning.dayList = parseDateOfDayList(result.dayList);
        setKeyIds(planning);
        setPlanning(Object.assign({}, planning));
      })
      .finally(() => setIsLoading(false));
  };

  const copyList = () => {
    if (planning.status > 0 || planning.dayList.some((day) => day.status > 0)) return <></>;
    if (!planning.validatedWeekTeamUserList || planning.validatedWeekTeamUserList.length === 0) return <></>;

    return (
      <>
        <IconButton aria-label="settings" onClick={(x) => setAnchorEl(x.currentTarget)} size="small">
          <CmsIcon icon="moreVertical" />
        </IconButton>
        <Menu id="copy-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
          {planning.validatedWeekTeamUserList.map((user) => (
            <MenuItem onClick={() => handleCopy(user.id)}>Copier la semaine de: {user.label}</MenuItem>
          ))}
        </Menu>
      </>
    );
  };

  const action = [
    title,
    <CmsIcon
      href="/help/Feuille_heures/saisie"
      style={{ marginLeft: '12px' }}
      tooltip="Aide Saisie des feuilles d'heures"
      icon="help"
      target="_blank"
    />,
    <IconButton onClick={handleNavigation} size="small" style={{ marginLeft: '12px' }}>
      <CmsIcon icon="arrowBack" />
    </IconButton>,
    copyList(),
  ];

  return (
    <UI.Paper isLoading={isLoading} title={action} headerStyle={status > 0 ? { backgroundColor } : {}}>
      <h3 className="user-title">Bonjour {selectedUser?.label}</h3>
      {emulatedUser?.id !== selectedUser?.id && (
        <UI.WarningBubble>
          Attention, vous remplissez la feuille d'heure d'un collègue: {selectedUser?.label}
        </UI.WarningBubble>
      )}
      <HelpModal open={helpIsOpen} onClose={() => setHelpIsOpen(false)} />
    </UI.Paper>
  );
};

/**
 * Pied de page de la page de saisie des temps, permets d'ajouter une journée, de valider la semaine
 * @param userHandler gestionnaire d'utilisateur
 */
const WeekFooter: FC<{ userHandler: UserHandler }> = ({ userHandler }) => {
  const { planning, setPlanning } = useContext(PlanningContext);
  const [isLoading, setLoading] = useState(false);
  const [comment, setComment] = useState<string>(planning.comment ?? '');
  const [isOpenDaySelect, setOpenDaySelect] = useState<boolean>(false);
  const dayAlreadySet = planning.dayList.map((x) => new Date(x?.dayDate).getDay());
  const dayAvailable = dayOfTheWeek.filter((x) => dayAlreadySet.indexOf(x.id) === -1);
  const userCanEdit = useRef(AccessFilter([ROLE.ADMIN_TIMEREPORTINGWEEK_EDIT, ROLE.USER_IS_MANAGER]));
  const navigate = useNavigate();
  if (planning.status === TimeReportingFormStep.VALIDATED) return <></>;

  const validWeek = () => {
    planning.comment = comment;
    if (planning.dayList.find((x) => x.status === 0) !== undefined) {
      NotificationService.error(
        'Vous avez des jours non validés, supprimez les ou validez les avant de valider la semaine',
      );
      return;
    }
    const request = planning.status === TimeReportingFormStep.TO_COMPLETE ? CRUD.post : CRUD.put;
    setLoading(true);
    request<WkTimeReportingWeek>(APIRoute.WkTimeReportingWeek, planning)
      .then((result) => {
        NotificationService.success('Semaine validée');
        result.mondayDate = new Date(result.mondayDate);
        result.dayList = parseDateOfDayList(result.dayList);
        setPlanning(result);
      })
      .finally(() => setLoading(false));
  };

  // Ajoute une journée au planning
  const addDay = (dayOfTheWeek: number) => {
    const newDay = new WkTimeReportingDay();
    const date = new Date(planning.mondayDate);
    date.setDate(date.getDate() + dayOfTheWeek - 1);
    newDay.dayDate = date;
    newDay.userId = userHandler.selectedUser?.id;
    planning.dayList.push(newDay);
    planning.dayList.sort((a, b) => (a.dayDate < b.dayDate ? -1 : 1));
    setPlanning(Object.assign({}, planning));
    setOpenDaySelect(false);
  };

  const handleGoToAdmin = () =>
    navigate(`/castres/timereporting/administration/${planning.userId}/${setToKebabDate(planning.mondayDate)}`);

  return (
    <UI.Paper title="Validation de la semaine" isLoading={isLoading}>
      <div className="week-validation">
        {planning.status === TimeReportingFormStep.TO_SUPERVISOR_VALIDATE && (
          <>
            <InputUI.DebouncedInput
              style={{ width: '100%' }}
              label="Commentaire"
              multiline
              value={comment}
              onChange={setComment}
            />
            <UI.Button size="large" title="SaveWeekComment" onClick={validWeek}>
              Enregistrer un commentaire pour la semaine
            </UI.Button>
          </>
        )}
        {planning.status === TimeReportingFormStep.TO_COMPLETE && (
          <>
            {isOpenDaySelect &&
              dayAvailable.map((day, i) => (
                <UI.Button size="large" key={i} title={day.label + day.id} onClick={() => addDay(day.id)}>
                  {day.label}
                </UI.Button>
              ))}
            <UI.Button size="large" title="AddDay" onClick={() => setOpenDaySelect(true)}>
              Ajouter une journée
            </UI.Button>
            <UI.Button size="large" title="ValidWeek" onClick={validWeek}>
              Valider la feuille de temps
            </UI.Button>
          </>
        )}
        {userCanEdit && (
          <UI.Button size="large" title="go-to-admin" onClick={handleGoToAdmin}>
            Administrer cette semaine
          </UI.Button>
        )}
      </div>
    </UI.Paper>
  );
};

/**
 * Modal d'aide
 * @param open ouverture de la modal
 * @param onClose fermeture de la modal
 */
const HelpModal: FC<{ open: any; onClose: () => any }> = ({ open, onClose }) => {
  return (
    <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
      <UI.Paper title="Feuille d'heure">
        <h2>Comment remplir la feuille d'heure</h2>
      </UI.Paper>
    </Dialog>
  );
};

/**
 * Calcul la distance entre deux points, détection de la vitesse pour affichr si un trajet est suspect
 * @param start Date de départ
 * @param end Date d'arrivée
 * @param distance Distance entre les deux points
 */
function isSpeedWeird(start: Date, end: Date, distance: number): string | null {
  const speed = distance / ((end.getTime() - start.getTime()) / 3600000);
  if (speed === 0) return null;
  return ((speed < 30 || speed > 130) && Math.round(speed) + 'km/h') || null;
}

interface WarningModalProps {
  // État de la modal
  openModal: boolean;
  // Fermeture de la modal
  setOpenModal: any;
  // Liste des avertissements
  warningList: any[];
  // Valide la journée
  sendReporting: any;
}

/**
 * Fonction de détection des erreurs éventuelles
 * @param planning Planning de la semaine
 * @param day Journée à valider
 */
function getDayWarnings(planning: WkTimeReportingWeek, day: WkTimeReportingDay): any[] {
  const result: any[] = [];
  if (
    day.actionList.filter((x) => x.actionType === TRActionType.ABSENCE || x.actionType === TRActionType.PAID_ABSENCE)
      .length === day.actionList.length &&
    day.actionList.length !== 0
  )
    return [];
  const displacements: WkTimeReportingAction[] =
    day.actionList?.filter((x: any) => movingOption.includes(x.actionType)) ?? [];
  if (displacements?.length > 0) {
    for (const travel of displacements) {
      const speedWarning = isSpeedWeird(travel.start, travel.end, travel?.travelDistance ?? 0);
      const travelLabel = TRActionOption.find((x) => x.id === travel.actionType)?.label ?? 'non défini';
      if (speedWarning) result.push(`${travelLabel} à la vitesse de ${speedWarning}`);
    }
  }
  const pauseList = day.actionList.filter((x) => x.actionType === TRActionType.PAUSE);
  if (pauseList.length > 0) {
    let totalTime = 0;
    for (const pause of pauseList) totalTime += pause.end.getTime() - pause.start.getTime();
    if (totalTime < (planning.lunchTime ?? 60) * 60000)
      result.push(`Le temps de pause doit être d'au moins ${planning.lunchTime} minutes`);
  }
  if (
    day.actionList?.length > 0 &&
    day.actionList[0].start.getHours() < 12 &&
    day.actionList[day.actionList.length - 1].end.getHours() > 13 &&
    pauseList.length === 0
  )
    result.push("Il semble que votre journée ne contienne pas de temps de pause alors qu'elle le devrait");
  day.actionList.forEach((action, i) => {
    if (i === 0) return;
    const timeSpan = action.start.getTime() - day.actionList[i - 1].end.getTime();
    if (timeSpan > 300000)
      result.push(`Vous avez ${timeSpan / 60000} minutes de temps non renseigné entre l'action ${i} et ${i + 1}`);
  });
  day.actionList.forEach((action, i) => {
    if (action.actionType === TRActionType.INTERVENTION && !action.taskId)
      result.push(
        <span style={{ color: '#F33' }}>Une de vos actions "Intervention" n'est rattachée à aucune tâche</span>,
      );
  });
  day.actionList.forEach((action, i) => {
    if (specialInterventionType.includes(action.actionType) && !action.interventionId)
      result.push(
        <span style={{ color: '#F33' }}>
          Une de vos actions "Passage agence / Passage fournisseur / Entretien véhicule" n'est rattachée à aucune
          intervention
        </span>,
      );
  });
  return result;
}

/**
 * Modal d'avertissement
 * @param openModal ouverture de la modale
 * @param setOpenModal Fermeture de la modale
 * @param warningList Liste des avertissements
 * @param sendReporting Valide la journée
 * @constructor
 */
const WarningModal: FC<WarningModalProps> = ({ openModal, setOpenModal, warningList, sendReporting }) => {
  return (
    <UI.Dialog onClose={() => setOpenModal(false)} open={openModal}>
      <UI.Paper
        scrollable
        style={{ maxWidth: '400px', marginBottom: 0 }}
        title="Liste des avertissements"
        actions={[
          <IconButton title="Fermer" onClick={() => setOpenModal(false)} size="large">
            <CloseIcon fontSize="small" />
          </IconButton>,
        ]}
      >
        <h4>Les informations suivantes semblent étranges:</h4>
        {warningList &&
          warningList.map((item, i) => (
            <p key={i}>
              {i + 1}) {item}
            </p>
          ))}
        <UI.Divider />
        <UI.Button style={{ marginBottom: '0.4rem' }} onClick={() => setOpenModal(false)}>
          Abandonner
        </UI.Button>
        <UI.Button color="primary" onClick={sendReporting}>
          Confirmer la saisie
        </UI.Button>
      </UI.Paper>
    </UI.Dialog>
  );
};

export default TimeReportingPage;
