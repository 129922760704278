import React, { FC, ReactNode, useContext } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { CmsButton, CmsButtonProps } from './Ui';
import AccessFilter from '../../helper/AccessFilter';
import ViewListIcon from '@mui/icons-material/ViewList';
import DoneIcon from '@mui/icons-material/Done';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Tooltip } from '@mui/material';
import { CloudDownload, CloudUpload, RotateLeft, RotateRight, Save, VisibilityOff } from '@mui/icons-material';
import { ENV_ISDEV } from '../../constant/API_URL';
import RefreshIcon from '@mui/icons-material/Refresh';
import MenuIcon from '@mui/icons-material/Menu';
import { GlobalContext } from '../../context/Global.context';
import { useNavigate, Link } from 'react-router-dom';

export interface NavigationButtonProps extends CmsButtonProps {
  // Oblige l'ouverte du lien dans un nouvel onglet
  forceOpenNewTab?: boolean;
  // Liste des features (aussi appelé role) pour afficher le menu
  access?: Array<string>;
}

/**
 * Bouton générique pour la navigation dans l'application,
 * Si href et to sont valorisés, l'environnement de deployment définira lequel il doit utiliser
 * @param href lien classique : principalement utilisé pour rediriger vers le V1
 * @param to lien réact : uniquement pour la navigation V2
 * @param forceOpenNewTab Oblige l'ouverte du lien dans un nouvel onglet
 * @param title Titre du bouton
 * @param access Liste des features (aussi appelé role) pour afficher le menu
 * @param hidden Cache le bouton
 * @param props Autres props
 */
export const NavigationButton: FC<NavigationButtonProps> = ({
  href,
  to = '',
  forceOpenNewTab,
  title,
  access,
  hidden,
  ...props
}) => {
  if (hidden || (access && !AccessFilter(access))) return <></>;
  const toV2 = (!!to && ENV_ISDEV) || (!ENV_ISDEV && !href);
  return (
    <CmsButton
      component={toV2 ? Link : 'a'}
      href={!toV2 ? href : undefined}
      to={toV2 ? to : undefined}
      target={forceOpenNewTab ? '_blank' : '_self'}
      {...props}
    >
      {title}
    </CmsButton>
  );
};

// Surcharge du bouton de navigation pour ajouter un icone
export const ArrowBackNavigationButton: FC<NavigationButtonProps> = (props) => (
  <NavigationButton {...props} startIcon={<ArrowBackIcon />} />
);

// Surcharge du bouton de navigation pour ajouter un icone
export const OpenInNewNavigationButton: FC<NavigationButtonProps> = (props) => (
  <NavigationButton {...props} startIcon={<OpenInNewIcon />} />
);

// Surcharge du bouton de navigation pour ajouter un icone
export const EditNavigationButton: FC<NavigationButtonProps> = ({ color = 'primary', ...props }) => (
  <NavigationButton {...props} color={color} startIcon={<EditIcon />} />
);

// Surcharge du bouton de navigation pour ajouter un icone
export const ViewNavigationButton: FC<NavigationButtonProps> = (props) => (
  <NavigationButton {...props} startIcon={<VisibilityIcon />} />
);

// Surcharge du bouton de navigation pour ajouter un icone
export const AddNavigationButton: FC<NavigationButtonProps> = ({ color = 'primary', ...props }) => (
  <NavigationButton {...props} color={color} startIcon={<AddIcon />} />
);

// Surcharge du bouton de navigation pour ajouter un icone
export const ListNavigationButton: FC<NavigationButtonProps> = (props) => (
  <NavigationButton {...props} startIcon={<ViewListIcon />} />
);

// Tout les boutons avec cette interface pour être caché en fonction des droits
export interface ButtonWithAccess extends CmsButtonProps {
  access?: string[];
}

// Bouton formatter pour la validation
export const ValidButton: FC<ButtonWithAccess> = (props) => {
  if (!!props.access && !AccessFilter(props.access)) return <></>;
  return <CmsButton color="primary" size="small" startIcon={<DoneIcon />} {...props} />;
};

// Bouton formatter pour la suppression
export const DeleteButton: FC<ButtonWithAccess> = (props) => {
  if (!!props.access && !AccessFilter(props.access)) return <></>;
  return <CmsButton {...props} color="error" startIcon={<DeleteIcon />} size="small" />;
};

// Bouton formatter pour l'annulation
export const CancelButton: FC<ButtonWithAccess> = (props) => {
  if (!!props.access && !AccessFilter(props.access)) return <></>;
  return <CmsButton color="error" startIcon={<CloseIcon />} size="small" {...props} />;
};

// Bouton par défaut
export const DefaultButton: FC<ButtonWithAccess> = (props) => {
  if (!!props.access && !AccessFilter(props.access)) return <></>;
  return <CmsButton color="inherit" size="small" {...props} />;
};

interface LinkWithAccessProps {
  // Liste des features (aussi appelé role) pour afficher le lien
  access?: string[];
  // Titre du lien
  title?: string;
  // Lien à rediriger
  to: string;
  // Oblige l'ouverte du lien dans un nouvel onglet
  forceOpenNewTab?: boolean;
}

export const LinkWithAccess: FC<LinkWithAccessProps> = ({ access, title, to, forceOpenNewTab }) => {
  if (!!access && !AccessFilter(access)) return <>{title ?? ''}</>;
  return <Link {...{ to, target: forceOpenNewTab ? '_blank' : '_self' }}>{title ?? ''}</Link>;
};

export interface IconButtonWithAccess extends ButtonWithAccess {
  // Icone possible à afficher, ajouter le ici pour l'utiliser et dans le switch de getIcon
  icon?:
    | 'close'
    | 'download'
    | 'upload'
    | 'view'
    | 'delete'
    | 'add'
    | 'edit'
    | 'valid'
    | 'rotateLeft'
    | 'rotateRight'
    | 'save'
    | 'menu'
    | 'refresh'
    | 'arrowBack';
  // Taille de l'icone
  size?: 'small' | 'medium';
  // Tooltip à afficher (message d'explication)
  toolTip?: string;
  // Appliquer une animation de rotation à l'icône
  rotate?: boolean;
}

/**
 * Icône avec possibilité de le rendre cliquable et avec un tooltip pour l'explication
 * @deprecated Utiliser le composant CmsIcon, la migration devras être faite pour ne plus utiliser ce composant
 * @param access Liste des features (aussi appelé role) pour afficher l'icone
 * @param size Taille de l'icone
 * @param children Icône personnalisée pour surcharger l'icone par défaut
 * @param toolTip Tooltip à afficher (message d'explication)
 * @param icon Nom de l'icone à afficher (peut être surchargé par children
 * @param onClick Fonction à appeler lors du clic
 * @param style Style à appliquer à l'icone
 * @param rotate Appliquer une animation de rotation à l'icône
 */
export const Icon: FC<IconButtonWithAccess> = ({
  access,
  size,
  children,
  toolTip,
  icon,
  onClick,
  style,
  rotate = false,
}) => {
  if (!!access && !AccessFilter(access)) return <></>;
  const finalElem: any = children ?? getIcon(icon);
  const className = rotate ? 'icon-rotate' : '';
  return (
    <IconButton size={size ?? 'small'} onClick={onClick} style={style} className={className}>
      {(!!toolTip && <Tooltip title={toolTip}>{finalElem}</Tooltip>) || finalElem}
    </IconButton>
  );
};

/**
 * Récupère l'icône en fonction du nom
 * @param name Nom de l'icone (voir la liste dans l'interface IconButtonWithAccess)
 */
function getIcon(name?: string): ReactNode {
  switch (name) {
    case 'download':
      return <CloudDownload />;
    case 'delete':
      return <DeleteIcon />;
    case 'view':
      return <VisibilityIcon />;
    case 'add':
      return <AddIcon />;
    case 'upload':
      return <CloudUpload />;
    case 'close':
      return <CloseIcon />;
    case 'rotateLeft':
      return <RotateLeft />;
    case 'rotateRight':
      return <RotateRight />;
    case 'save':
      return <Save />;
    case 'refresh':
      return <RefreshIcon />;
    case 'arrowBack':
      return <ArrowBackIcon />;
    case 'menu':
      return <MenuIcon />;
    case 'edit':
      return <EditIcon />;
    case 'valid':
      return <DoneIcon />;
  }
  return <VisibilityIcon />;
}

export interface ShowHideIconProps {
  // Liste des features (aussi appelé role) pour afficher l'icone
  access?: string[];
  // Tooltip à afficher (message d'explication)
  toolTip: string;
  // Fonction à appeler lors du clic
  onClick: any;
  // Etat actuel de l'élément (visible ou non)
  active: boolean;
}

/**
 * Icône pour afficher ou cacher un élément
 * @param access Liste des features (aussi appelé role) pour afficher l'icone
 * @param active Etat actuel de l'élément (visible ou non)
 * @param toolTip Tooltip à afficher (message d'explication)
 * @param onClick Fonction à appeler lors du clic
 */
export const ShowHideIcon: FC<ShowHideIconProps> = ({ access, active, toolTip, onClick }) => {
  if (!!access && !AccessFilter(access)) return <></>;
  const finalElem = active ? <VisibilityIcon /> : <VisibilityOff />;
  return (
    <IconButton size="small" onClick={() => onClick(!active)}>
      {(!!toolTip && <Tooltip title={toolTip}>{finalElem}</Tooltip>) || finalElem}
    </IconButton>
  );
};

interface PayLoadButtonProps extends ButtonWithAccess {
  payload: any;
}

export const PayLoadButton: FC<PayLoadButtonProps> = (props) => {
  const { setPayload } = useContext(GlobalContext);
  const navigate = useNavigate();
  if (!!props.access && !AccessFilter(props.access)) return <></>;
  const handleClick = () => {
    setPayload({ to: props.to, data: props.payload });
    navigate(props.to ?? '');
  };
  return (
    <CmsButton color="primary" size="small" {...props} onClick={handleClick}>
      {props.title}
    </CmsButton>
  );
};
