import React from 'react';
import { authenticationService } from '../service/Authentication.service';
import { BASE_URL } from 'constant/API_URL';

/**
 * Composant de déconnexion
 */
export const Logout: React.FC = () => {
  const splittedUrl = window.location.href.split(BASE_URL);
  console.log(splittedUrl);
  const forceV2Only = splittedUrl.length > 1 && splittedUrl[1] === 'logout';
  setTimeout(() => {
    authenticationService.logout(forceV2Only);
  }, 250);
  return <></>;
};
