import { BehaviorSubject } from 'rxjs';
import { checkStatus, handleCrudErrorNotif, handleJsonResponse } from '../helper/handle-response';
import { API_URL, BASE_URL, REFRESH_TOKEN_TIMER } from '../constant/API_URL';
import { jwtDecode } from 'jwt-decode';
import { User } from '../interface/User';
import { fetchWithAuth } from '../helper/handle-request';
import APIRoute, { WhiteListLogoutToLoginV2 } from '../constant/API.constant';
import { setUserPreferences } from './CacheService';

export interface LoginProps {
  // Itendifiant de l'utilisateur
  login: string;
  // Mot de passe de l'utilisateur
  password: string;
}

// Récupère l'utilisateur présent sur le localStorage
const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser') as string));
const loggedInSubject = new BehaviorSubject(!!localStorage.getItem('currentUser'));

export const authenticationService = {
  login,
  logout,
  loginByToken,
  getCurrentUser,
  checkTokenExpiration,
  ThrowUserBackToV1,
  loggedInSubject,
  currentUser: currentUserSubject.asObservable(),
  get isLoggedIn() {
    return loggedInSubject ? loggedInSubject.value : null;
  },
  get currentUserValue() {
    return currentUserSubject ? currentUserSubject.value : null;
  },
};

/**
 * Méthode du login V2
 * @param loginProps login = identifiant, password = mot de passe, se référer à l'interface LoginProps
 */
function login(loginProps: LoginProps) {
  const requestOptions: any = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(loginProps),
  };
  const request = new Request(API_URL + `Login`, requestOptions);
  return fetch(request)
    .then(checkStatus)
    .then(handleJsonResponse)
    .catch(handleCrudErrorNotif)
    .then((user) => setCurrentUser(user));
}

/**
 * Renvoie l'utilisateur vers la page de login V1
 */
function ThrowUserBackToV1() {
  window.location.href = BASE_URL;
}

/**
 * Méthode pour déconnecter un utilisateur. On supprime le localStorage,
 * on supprime le token et on redirige vers la page de login
 * @param forceV2Only Permet de forcer le CMS en V2 uniquement (si /logout est géré par le V2 et non le V1)
 */
async function logout(forceV2Only: boolean = false) {
  const user = getCurrentUser();
  await revokePhpSessionId();
  await revokeToken(user?.refreshToken);
  clearCurrentUser();
  handleRedirect(forceV2Only || !!user?.isV2Only);
}

/**
 * Redirige vers la page de login V2 si l'utilisateur s'est directement connecté depuis le V2 (sans passer par le V1)
 * @param isV2Only Définit si l'utilisateur est connecté depuis le V2
 */
function handleRedirect(isV2Only: boolean) {
  const toLoginV2 = shouldRedirectToLoginV2();
  if (toLoginV2) {
    const splitedUrl = window.location.href.split(BASE_URL);
    if (splitedUrl.length > 1) localStorage.setItem('memo-url', splitedUrl[1]);
  }
  //soit uniquement v2 et la déconnection est finie, soit v1 et on poursuit vers la déconnection v1
  window.location.href =
    BASE_URL + (isV2Only || toLoginV2 || BASE_URL === 'http://localhost:3000/' ? 'loginV2' : 'logout');
}

/**
 * Si l'url actuel fait partie de la liste blanche WhiteListLogoutToLoginV2, on redirige vers la page de login V2
 */
function shouldRedirectToLoginV2(): boolean {
  const currentUrl = window.location.href;
  for (const func of WhiteListLogoutToLoginV2) if (func(currentUrl)) return true;
  return false;
}

/**
 * Supprime l'utilisateur du localStorage et de l'observable
 */
function clearCurrentUser(): void {
  localStorage.removeItem('currentUser');
  currentUserSubject.next('notLogged');
  loggedInSubject.next(false);
  // Suppression des cookies d'authentification
  deleteCookie('PHPSESSID');
  deleteCookie('AccessToken');
  deleteCookie('UserCode');
}

/**
 * Fonction utilitaire pour supprimer un cookie en le réinitialisant avec une date d'expiration passée
 * @param name Nom du cookie à supprimer
 */
function deleteCookie(name: string): void {
  document.cookie = name + '=; Max-Age=0; path=/; domain=' + window.location.hostname;
}

/**
 * Appel API pour déconnecter l'utilisateur du backend en révoquant le token PHP du V1
 */
async function revokePhpSessionId(): Promise<Response> {
  const requestOptions: any = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchWithAuth({
    url: API_URL + APIRoute.Login + '/token/RevokePhpSessionId',
    method: 'POST',
    headers: requestOptions,
  });
}

/**
 * Méthode pour déconnecter l'utilisateur en révoquant le token V2
 * @param token
 */
async function revokeToken(token: string): Promise<Response> {
  const requestOptions: any = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ token }),
  };
  return fetch(API_URL + APIRoute.Login + '/token/revoke', requestOptions);
}

/**
 * Insère l'utilisateur dans le localStorage et dans l'observable
 * @param user l'utilisateur à insérer
 */
export function setCurrentUser(user: any | null): User | null {
  if (!user || user?.accessToken == null) {
    logout();
    return null;
  }
  localStorage.clear();
  setUserPreferences(user);
  localStorage.setItem('currentUser', JSON.stringify(user));
  currentUserSubject.next(user);
  loggedInSubject.next(true);
  return user;
}

/**
 * Récupère l'utilisateur présent sur le localStorage (et mets à jour l'observable)
 */
function getCurrentUser(): any {
  const currentUserString = localStorage.getItem('currentUser');
  if (!currentUserString) return null;
  const user = JSON.parse(currentUserString);
  currentUserSubject.next(user);
  return user;
}

/**
 * Vérification de la date de péremption de l'access token.
 * Si l'access token est périmé, on supprime l'utilisateur du localStorage et de l'observable
 * @param currentUser l'utilisateur à vérifier
 */
function checkTokenExpiration(currentUser?: any): boolean {
  let decodedToken: any;
  const user = currentUser ?? getCurrentUser();
  const accessToken = user?.accessToken;
  if (!accessToken) {
    clearCurrentUser();
    return false;
  }
  if (accessToken) decodedToken = jwtDecode(accessToken);
  const expirationDate: number = decodedToken?.exp * 1000;
  if (!expirationDate) {
    clearCurrentUser();
    return false;
  }
  if (
    user?.refreshToken &&
    expirationDate >= new Date().getTime() &&
    expirationDate <= new Date().getTime() + 60000 * REFRESH_TOKEN_TIMER
  ) {
    refreshAccessToken(getCurrentUser());
  }
  if (expirationDate >= new Date().getTime()) {
    return true;
  } else {
    clearCurrentUser();
  }
  return false;
}

/**
 * Méthode pour rafraichir l'access token de l'utilisateur
 * @param currentUser l'utilisateur à rafraichir
 */
function refreshAccessToken(currentUser: User) {
  const requestOptions: RequestInit = {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ token: currentUser.refreshToken, userId: 10000000 }),
  };
  fetchWithAuth({ url: API_URL + APIRoute.Login + '/token/refresh', ...requestOptions })
    .then((response) => response.json())
    .then((tokenRessource) => updateAccessToken(tokenRessource));
}

/**
 * Met à jour l'access token de l'utilisateur dans le localStorage et dans l'observable
 * @param tokenRessource
 */
function updateAccessToken(tokenRessource: any) {
  if (!getCurrentUser() || !tokenRessource?.refreshToken) authenticationService.logout();
  const currentUser = Object.assign({}, getCurrentUser());
  currentUser.accessToken = tokenRessource.accessToken;
  currentUser.refreshToken = tokenRessource.refreshToken;
  currentUser.expiration = tokenRessource.expiration;
  setCurrentUser(currentUser);
}

/**
 * Méthode pour récupérer l'utilisateur connecté via le token PHP V1
 * On n'envoie aucune donnée car le token est déjà présent dans le cookie
 */
function loginByToken() {
  const requestOptions: RequestInit = { credentials: 'include', method: 'POST' };
  fetch(API_URL + APIRoute.Login + '/token/getUserByToken', requestOptions)
    .then((response) => response.json())
    .then((user) => setCurrentUser(user));
}
