import React, { FC, useEffect, useState } from 'react';
import APIRoute from '../../constant/API.constant';
import CRUD from '../../service/CRUD.service';
import '../user/user.scss';
import { CmsForm } from '../../component/form/CmsForm';
import { CmsFormInput } from 'component/form/CmsFormInput';
import notificationService from '../../service/NotificationService';
import { useNavigate } from 'react-router-dom';

export const WkGreenfluxStationEdit: FC<any> = ({ id }) => {
  const [clientOptionList, setClientOptionList] = useState<any[]>();
  const [stationId, setStationCId] = useState<number>();
  const [resetClient, setResetClient] = useState<boolean>(false);

  useEffect(() => {
    if (stationId) CRUD.getList(APIRoute.ClClient + '/ByStation/' + stationId).then(setClientOptionList);
    setResetClient(true);
  }, [stationId]);
  const navigate = useNavigate();

  const submit = (data: WkGfStationEdit) => {
    const postData: WkGfStationEdit = {
      id: id,
      stationCmsId: data.stationCmsId,
      clientId: data.clientId,
    };
    return CRUD.put<WkGfStationEdit>(`${APIRoute.WkGreenfluxStations}`, postData).then((result) => {
      notificationService.success('La station a bien été modifiée');
      navigate(`${baseUrl}${id}/show`);
    });
  };

  const baseUrl = '/castres/greenflux/stations/';

  return (
    <CmsForm
      id={id}
      currentUrl={baseUrl}
      route={APIRoute.WkGreenfluxStations}
      onSubmit={submit}
      goToOnValidForm={(id) => `${baseUrl}${id}/show`}
      title="Édition du lien entre la station Greenflux et la station CMS"
      renderForm={(form) => {
        const { stationCmsId } = form.watch();
        setStationCId(stationCmsId);
        return (
          <>
            <CmsFormInput.Select
              id="stationCmsId"
              label="Référence à une station du CMS"
              options={APIRoute.ClStation + '/Simplified'}
              required
            />
            <CmsFormInput.Select
              id="clientId"
              label="Client lié"
              options={clientOptionList ?? []}
              hideIf={(x) => !x.stationCmsId}
              updateFieldIf={() => {
                if (resetClient) {
                  setResetClient(false);
                  return { update: true, value: undefined };
                }
                return { update: false, value: null };
              }}
            />
          </>
        );
      }}
    />
  );
};

export interface WkGfStationEdit {
  id: number;
  stationCmsId: number;
  clientId: number;
}
