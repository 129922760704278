import React, { FC } from 'react';
import { toast, ToastOptions } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FormNotifMessage } from 'constant/Form.constant';
import FormMode from 'interface/FormMode';

const customStyle = {
  display: 'flex',
  alignItems: 'center',
  svg: {
    marginLeft: '0.5em',
    marginRight: '0.5em',
  },
};

/**
 * Wrapper pour les notifications (customisation du style)
 * @param children contenu de la notification
 */
const CustomToast: FC<{ children: any }> = ({ children }) => <span css={customStyle}>{children}</span>;

/**
 * Notification d'erreur
 * @param message message de la notification
 */
export const error = (message = "Une erreur s'est produite") => {
  toast.error(<CustomToast>{message}</CustomToast>);
};

/**
 * Notification d'avertissement
 * @param message message de la notification
 */
export const warning = (message = 'Attention') => {
  toast.warning(<CustomToast>{message}</CustomToast>);
};

/**
 * Notification d'information
 * @param message message de la notification
 */
export const info = (message = 'Information importante') => {
  toast.info(<CustomToast>{message}</CustomToast>);
};

/**
 * Notification de succès
 * @param message message de la notification
 * @param options Surcharge des options de la notification
 */
export const success = (message = 'Tout est bon', options?: ToastOptions<any>) => {
  toast.success(<CustomToast>{message}</CustomToast>, options);
};

/**
 * Notification de succès de formulaire (avec message préformaté)
 * @param formMode mode du formulaire (pour déterminer si c'est une création ou une modification)
 */
export const successForm = (formMode: FormMode) => {
  success(formMode.isUpdate() ? FormNotifMessage.updated : FormNotifMessage.created);
};

const NotificationService = {
  error,
  warning,
  info,
  success,
  successForm,
};
export default NotificationService;
