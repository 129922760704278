import React, { FC } from 'react';
import AccessFilter from 'helper/AccessFilter';
import { AddNavigationButton } from 'component/shared/Buttons';
import APIRoute from '../../constant/API.constant';
import { LabelCalc } from '../../interface/CommonType';
import { CmsColumnDef, CmsFrontendTable } from '../../component/table/CmsTable';
import { SkTool } from '../../interface/SkType';
import CmsTableFilter from '../../component/table/helper/CmsTableFilter';
import CmsTableCell from '../../component/table/helper/CmsTableCell';
import ROLE from '../../constant/role.constant';

const ToolListConfig: CmsColumnDef<SkTool>[] = [
  {
    header: 'Ref',
    id: 'ref',
    noClick: true,
    Filter: CmsTableFilter.Search,
    cell: (x) => <CmsTableCell.Link label={x.getValue()} url={`/castres/stock/tool/${x.row.original.id}/show`} />,
  },
  { header: 'Type', id: 'type', Filter: CmsTableFilter.Select, filterOptions: { multiple: true } },
  { header: 'Statut', id: 'status' },
  {
    header: 'Statut',
    id: 'statusId',
    hide: true,
    Filter: CmsTableFilter.Select,
    defaultFilterValue: [1, 2, 3, 4],
    filterOptions: {
      multiple: true,
      optionList: [
        { id: 1, label: 'DISPO' },
        { id: 2, label: 'ATTRIBUE' },
        { id: 3, label: 'EN CONTROLE REPARATION' },
        { id: 4, label: 'EN PANNE' },
        { id: 5, label: 'AU REBUT' },
      ],
    },
  },
  {
    header: 'Service',
    id: 'service',
    Filter: CmsTableFilter.Select,
    filterOptions: { multiple: true, optionList: APIRoute.SfService, optionId: 'label' },
  },
  { header: 'Technicien', id: 'user', Filter: CmsTableFilter.Select, filterOptions: { multiple: true } },
  { header: 'Marque', id: 'mark', Filter: CmsTableFilter.Select, filterOptions: { multiple: true } },
  { header: 'Modèle', id: 'model', Filter: CmsTableFilter.Select, filterOptions: { multiple: true } },
  { header: 'Volume nominal', id: 'nominalVolume' },
  { header: 'Début de validité', id: 'validateStartDate', Filter: CmsTableFilter.Date },
  { header: 'Service du technicien', id: 'serviceUser', hide: true, Filter: CmsTableFilter.Select },
  { header: 'Fin de validité', id: 'validateEndDate', Filter: CmsTableFilter.Date, cell: CmsTableCell.DateWithLimit },
  { header: 'N° Serie', id: 'serial', Filter: CmsTableFilter.Search, hide: 'hiddenByDefault' },
];

export const ToolListIndicatorList: Array<LabelCalc> = [
  { label: 'Outils', calc: (rows: Array<any>) => rows.length },
  {
    label: 'Disponible',
    calc: (rows) => {
      const total = rows.length;
      const selected: number = rows.filter((item) => item.original.status === 'DISPO').length;
      return { count: selected, ratio: selected / total };
    },
  },
  {
    label: 'Attribué',
    calc: (rows) => {
      const total = rows.length;
      const selected: number = rows.filter((item) => item.original.status === 'ATTRIBUE').length;
      return { count: selected, ratio: selected / total };
    },
  },
  {
    label: 'Contrôle',
    revertColor: true,
    calc: (rows) => {
      const total = rows.length;
      const selected: number = rows.filter((item) => item.original.status === 'EN CONTROLE REPARATION').length;
      return { count: selected, ratio: selected / total };
    },
  },
  {
    label: 'En panne',
    revertColor: true,
    calc: (rows) => {
      const total = rows.length;
      const selected: number = rows.filter((item) => item.original.status === 'EN PANNE').length;
      return { count: selected, ratio: selected / total };
    },
  },
  {
    label: 'Au rebut',
    revertColor: true,
    calc: (rows) => {
      const total = rows.length;
      const selected: number = rows.filter((item) => item.original.status === 'AU REBUT').length;
      return { count: selected, ratio: selected / total };
    },
  },
  {
    label: 'Périmé',
    revertColor: true,
    calc: (rows) => {
      const total = rows.length;
      const selected: number = rows.filter((item) => item.original.validateEndDate < Date.now()).length;
      return { count: selected, ratio: selected / total };
    },
  },
  {
    label: 'Périmé et attribué',
    revertColor: true,
    calc: (rows) => {
      const total = rows.length;
      const selected: number = rows.filter(
        (item) => item.original.status === 'ATTRIBUE' && item.original.validateEndDate < Date.now(),
      ).length;
      return { count: selected, ratio: selected / total };
    },
  },
  {
    label: 'Périmé dans les 3 mois',
    revertColor: true,
    calc: (rows) => {
      const total = rows.length;
      const dateToCheck = new Date().setDate(90);
      const selected: number = rows.filter(
        (item) => item.original.validateEndDate > Date.now() && item.original.validateEndDate < dateToCheck,
      ).length;
      return { count: selected, ratio: selected / total };
    },
  },
];
/**
 * Liste des outils
 */
const ToolList: FC = () => (
  <CmsFrontendTable
    title="Liste d'outils"
    route={APIRoute.SkTool}
    initialSorting={{ id: 'ref', desc: false }}
    indicatorList={ToolListIndicatorList}
    columns={ToolListConfig}
    navigateTo={(id) => `/castres/stock/tool/${id}/show`}
    downloadable={AccessFilter([ROLE.ADMIN_STOCK_TOOL_EXPORT, ROLE.ADMIN_STOCK_TOOL_EXPORT]) && 'csv&excel'}
    actions={[
      <span>
        {AccessFilter([ROLE.ADMIN_STOCK_TOOL_CREATE]) && (
          <AddNavigationButton title="Ajouter un outil" to="/castres/stock/tool/create" />
        )}
      </span>,
    ]}
    setFiltersInUrl
  />
);

export default ToolList;
